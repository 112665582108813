import { apiClient } from "lib/apiClient";

type Response = {
  id: string;
  filename: string;
  creationDate: string;
  lastModifiedDate: string;
};
export const addPropertyFiles = async (
  id: string,
  files: FormData
): Promise<Response> => {
  const response = await apiClient.post(`/property/${id}/attachments`, files);

  return response.data;
};
