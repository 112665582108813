import React, { useEffect } from "react";
import { useTypedDispatch } from "store";
import { getGroupedMemberTemplates } from "features/Templates/controllers";
import { useAbortPreviousRequest } from "utils/hooks/useAbortPreviousRequest";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { templatesTranslations } from "../../translations";
import styles from "./MyTemplates.module.scss";
import { LOADING_STATE } from "types/general";
import { CustomLoader } from "components/CustomLoader";
import { useSelector } from "react-redux";
import {
  getGroupedTemplates,
  getGroupedTemplatesIsLoading,
} from "features/Templates/selectors";
import { GroupedTableRow } from "./GroupedTableRow";
import { LOADER_BOX_STYLE } from "../constants";

export const GroupedTemplates = () => {
  const dispatch = useTypedDispatch();
  const { abortPreviousRequest } = useAbortPreviousRequest();
  const data = useSelector(getGroupedTemplates);
  const loadingState = useSelector(getGroupedTemplatesIsLoading);

  useEffect(() => {
    const { signal } = abortPreviousRequest();
    dispatch(getGroupedMemberTemplates({ abortSignal: signal }));
  }, []);

  return (
    <Table className={styles.tableWrapper}>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>
            {templatesTranslations.companyGroupName}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={{ position: "relative" }}>
        {loadingState === LOADING_STATE.pending ? (
          <CustomLoader
            styles={{
              color: "#E3E3E3",
              opacity: 0.6,
            }}
            boxStyles={LOADER_BOX_STYLE}
          />
        ) : (
          data &&
          Object.keys(data)?.map((companyName) => {
            return (
              <GroupedTableRow
                key={companyName}
                data={data[companyName]}
                companyName={companyName}
              />
            );
          })
        )}
      </TableBody>
    </Table>
  );
};
