import { CancelTokenSource } from "axios";
import { apiClient } from "lib/apiClient";

export interface IPropertyAttachments {
  id: string;
  notes: string;
  filename: string;
  creationDate: string;
  lastModifiedDate: string;
  fileSize: number;
  order: number;
  size: number;
}

export const getPropertyAttachments = async (
  id: string,
  cancelTokenSource: CancelTokenSource
): Promise<IPropertyAttachments[]> => {
  const response = await apiClient.get(`/property/${id}/attachments`, {
    cancelToken: cancelTokenSource.token,
  });

  return response.data;
};
