import { IDeal } from "features/Deals/interfaces";
import { apiClient } from "lib/apiClient";

export interface IEditDeal {
  name: string;
  emails: string[];
}

export const editDealById = async (
  id: string,
  request: IEditDeal
): Promise<IDeal> => {
  const response = await apiClient.put(`/deals/${id}`, request);

  return response.data;
};
