import { IPropertyResponse } from "./addProperty";
import { IPropertyEditRequestData } from "pages/Properties/interfaces";
import { apiClient } from "lib/apiClient";

export const editProperty = async (
  request: IPropertyEditRequestData
): Promise<IPropertyResponse> => {
  const response = await apiClient.put(`/properties`, request);

  return response.data;
};
