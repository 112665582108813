import React, { useEffect, useState } from "react";
import { ModalComponent } from "components/ModalComponent";
import { Close } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { templatesTranslations } from "../../translations";
import { UploadMultipleFiles } from "components/UploadMultipleFiles/UploadMultipleFiles";
import { IMultipleFileData } from "../../../Comps/components/CreateSaleComp/interfaces";
import { VALIDATION_ERRORS } from "components/UploadCompsModal/constants";
import {
  AdditionalInfoNode,
  CloseButtonStyled,
  List,
  ModalButtonStyled,
} from "./AddTemplateModal.styled";
import {
  SUPPORTED_TEMPLATE_FILES,
  TEMPLATE_FILE_EXTENSIONS,
} from "../../constants";
import { CustomLoader } from "../../../../components/CustomLoader";
import { Action } from "redux";

interface IAddTemplateModalProps {
  handleClose: () => void;
  handleAddFiles: (files: IMultipleFileData[]) => Promise<Action>;
  uploadError: string;
}

export const AddTemplateModal = ({
  handleClose,
  handleAddFiles,
  uploadError,
}: IAddTemplateModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<IMultipleFileData[]>([]);
  const [isValidFile, setIsValidFile] = useState(true);
  const [errorText, setErrorText] = useState(uploadError);

  useEffect(() => {
    setErrorText(uploadError);
  }, [uploadError]);

  const handleFilesValue = (value: IMultipleFileData[]) => {
    if (files.length) {
      if (value.length) {
        setErrorText(VALIDATION_ERRORS.filesLimit);
      } else {
        setErrorText("");
        setFiles(() => value);
      }
    } else {
      setFiles(value);
      setErrorText("");
    }
  };

  const handleFilesError = (value: boolean) => {
    setIsValidFile(value);
  };

  const handleSubmitModal = () => {
    if (isValidFile && files.length) {
      setIsLoading(true);
      handleAddFiles(files).finally(() => setIsLoading(false));
    }
  };

  return (
    <ModalComponent open>
      <Box
        padding="30px 45px"
        display="flex"
        flexDirection="column"
        width="580px"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={1}
          borderColor="secondary.main"
          pl="10px"
          mb="20px"
        >
          <Typography variant="h3">
            {templatesTranslations.addTemplateLowerCase}
          </Typography>
          <CloseButtonStyled
            onClick={handleClose}
            disableRipple
            disabled={isLoading}
          >
            <Close />
          </CloseButtonStyled>
        </Box>
        <UploadMultipleFiles
          files={files}
          inputStyles={{ minWidth: "121px" }}
          handleFilesError={handleFilesError}
          handleFilesValue={handleFilesValue}
          attachments={[]}
          attachmentsToDelete={[]}
          supportedFiles={SUPPORTED_TEMPLATE_FILES}
          hasLimitText={false}
          supportedExtensions={TEMPLATE_FILE_EXTENSIONS}
          additionalText={`.\n\n ${templatesTranslations.maxFileSize}\n\n`}
          additionalNodeInfo={
            <Box display="flex" flexDirection="column">
              <AdditionalInfoNode>
                Filename must include the desired SeniorComps export sheet(s)
                named:
                <List>
                  {/* eslint-disable react/no-unescaped-entities */}
                  <li>"SeniorComps Export - Rent"</li>
                  <li>"SeniorComps Export - Sale"</li>
                  <li>"SeniorComps Export - Expense"</li>
                </List>
              </AdditionalInfoNode>
            </Box>
          }
          supportedFileNames={[]}
          validationErrorsBox={
            <Typography variant="h5" color="error" whiteSpace="pre-wrap">
              {errorText}
            </Typography>
          }
          hasMultipleUpload={false}
          filesLimit={1}
          fileItemStyles={{ width: "100%" }}
        />
        <Box display="flex" gap={2} justifyContent="flex-end" mt={5}>
          <ModalButtonStyled
            classes="light"
            onClick={handleClose}
            disabled={isLoading}
          >
            {templatesTranslations.cancelButtonLabel}
          </ModalButtonStyled>
          <ModalButtonStyled
            onClick={handleSubmitModal}
            variant="contained"
            color="secondary"
            disabled={!files.length || isLoading}
            disableElevation
          >
            {isLoading ? (
              <CustomLoader
                size={20}
                styles={{ color: "common.white" }}
                boxStyles={{ display: "flex", alignItems: "center" }}
              />
            ) : (
              templatesTranslations.uploadButtonLabel
            )}
          </ModalButtonStyled>
        </Box>
      </Box>
    </ModalComponent>
  );
};
