import React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { ModalComponent } from "components/ModalComponent";
import { CustomLoader } from "components/CustomLoader";

export type Props = {
  open: boolean;
  icon: string;
  title: string;
  subtitle: string;
  cancelButtonTitle?: string;
  confirmButtonTitle?: string;
  handleClose: () => void;
  handleSubmit?: () => void;
  minHeight?: string;
  type?: "info" | "submit";
  isLoading?: boolean;
  additionalNote?: React.ReactNode | null;
};

export const ConfirmPopup = ({
  open,
  icon,
  title,
  subtitle,
  cancelButtonTitle,
  confirmButtonTitle,
  handleClose,
  handleSubmit,
  minHeight = "330px",
  type = "submit",
  isLoading,
  additionalNote,
}: Props) => {
  const theme = useTheme();
  const isSubmit = type === "submit";
  return (
    <ModalComponent
      open={open}
      closable
      handleClose={handleClose}
      boxStyles={{
        minWidth: open ? "465px" : 0,
        maxWidth: open ? "520px" : 0,
        minHeight: open ? minHeight : 0,
      }}
    >
      <Box
        sx={{
          p: "0 40px 40px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            m: "15px 0 30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={icon} alt="icon" />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              marginBottom: "5px",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "300",
            }}
          >
            {subtitle}
          </Typography>
        </Box>
        {isSubmit ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              classes="light"
              onClick={handleClose}
              sx={{
                width: "180px",
                height: "44px",
                mr: "24px",
                borderRadius: "6px",
                fontSize: "14px",
              }}
            >
              {cancelButtonTitle}
            </Button>
            <Button
              onClick={handleSubmit}
              sx={{
                width: "180px",
                height: "44px",
                borderRadius: "6px",
                backgroundColor: theme.palette.secondary.main,
                color: "white",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              {isLoading ? (
                <CustomLoader
                  size={20}
                  styles={{ color: "common.white" }}
                  boxStyles={{ display: "flex", alignItems: "center" }}
                />
              ) : (
                confirmButtonTitle
              )}
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              position: "absolute",
              left: 0,
              bottom: 0,
              width: "100%",
              height: "8px",
              backgroundColor: theme.palette.secondary.main,
            }}
          />
        )}
      </Box>
      {additionalNote}
    </ModalComponent>
  );
};
