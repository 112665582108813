import { toastMessage } from "components/ToastMessage";
import { exportExcel } from "./exportExcel";
import { resolveDate } from "./resolveDate";
import {
  IExportMyDealsCompsRequestParams,
  exportMyDealsComps,
} from "api/exportMyDealsComps";

export const handleExportMyDealsComps = async (
  compType: string,
  requestParams: IExportMyDealsCompsRequestParams
) => {
  try {
    const date = resolveDate(new Date(), "MM-dd-yyyy-HH-mm-ss");
    const fileName = `SeniorComps-${compType} ${date}`;
    const { blob, fileName: serverFileName } = await exportMyDealsComps(
      compType,
      requestParams
    );
    exportExcel(serverFileName ?? fileName, blob);
  } catch {
    toastMessage({
      message: "Failed to export comp.",
      position: "top-right",
      type: "error",
    });
  }
};
