import { RootState } from "../rootReducer";

export const isSuccessOpen = (state: RootState) =>
  state.CompanyGroupsReducer.isSuccessOpen;
export const companyGroups = (state: RootState) =>
  state.CompanyGroupsReducer.companyGroups;
export const companyGroupInfo = (state: RootState) =>
  state.CompanyGroupsReducer.companyGroupInfo;
export const companyGroupUsers = (state: RootState) =>
  state.CompanyGroupsReducer.companyGroupUsers;
export const userDetails = (state: RootState) =>
  state.CompanyGroupsReducer.userDetails;
export const isAdminPageType = (state: RootState) =>
  state.CompanyGroupsReducer.isAdminPageType;
export const transactionHistory = (state: RootState) =>
  state.CompanyGroupsReducer.transactionHistory;
export const isLoading = (state: RootState) =>
  state.CompanyGroupsReducer.isLoading;
export const uploadedComps = (state: RootState) =>
  state.CompanyGroupsReducer.uploadedComps;
export const county = (state: RootState) => state.CompanyGroupsReducer.county;
