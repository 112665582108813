import { apiClient } from "lib/apiClient";

export type IUserDetails = {
  agreedToLegalDocs: boolean;
  id: string;
  companyId: string;
  authServiceId: string;
  firstName: string;
  lastName: string;
  email: string;
  phonePrefix: string;
  phone: string;
  creationDate: string;
  lastModifiedDate: string;
  roles: string[];
};

export const getUserById = async (id: string): Promise<IUserDetails> => {
  const response = await apiClient.get(`/users/${id}`);

  return response.data;
};
