import React, { FC, Suspense, lazy } from "react";
import { routes } from "config/routes";
import {
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { VisitorsLayout } from "components/VisitorsLayout";
import { PrivateRoute } from "./components/PrivateRoute";
import { Layout } from "components/Layout";
import { PageWrapper } from "components/PageWrapper";
import Templates from "../Templates";

const LoginForm = lazy(() => import("../Login/components/LoginForm"));
const ResetForm = lazy(() => import("../Login/components/ResetForm"));
const Login = lazy(() => import("../Login"));
const ViewCompanyGroup = lazy(() => import("../ViewCompanyGroup"));
const ViewUser = lazy(() => import("../ViewUser"));
const ViewProperty = lazy(() => import("../ViewProperty"));
const CompanyGroups = lazy(() => import("../CompanyGroups"));
const Properties = lazy(() => import("../Properties"));
const UserProfile = lazy(() => import("../UserProfile"));
const PropertiesDetails = lazy(
  () => import("../Properties/components/PropertiesDetails")
);
const CompsDetails = lazy(() => import("../Comps/components/CompsDetails"));
const MergeSaleComp = lazy(() => import("../MergeSaleComp"));
const MergeRentComp = lazy(() => import("../MergeRentComp"));
const MergeExpenseComp = lazy(() => import("../MergeExpenseComp"));
const MyDeals = lazy(() => import("../MyDeals"));
const AddProperty = lazy(() => import("../AddProperty"));

export const PageRoot: FC = () => {
  const routesComponent = (
    <Route path="/">
      <Route index element={<Navigate to={routes.public.login} />} />
      <Route path={routes.public.login}>
        <Route
          index={true}
          path={routes.public.login}
          element={
            <VisitorsLayout>
              <Login>
                <LoginForm />
              </Login>
            </VisitorsLayout>
          }
        />
        <Route
          path={routes.public.signIn}
          element={
            <VisitorsLayout>
              <Login>
                <LoginForm />
              </Login>
            </VisitorsLayout>
          }
        />
        <Route
          path={routes.public.reset}
          element={
            <VisitorsLayout>
              <Login>
                <ResetForm />
              </Login>
            </VisitorsLayout>
          }
        />
      </Route>
      <Route
        path={routes.private.companyGroups}
        element={
          <PrivateRoute>
            <PageWrapper>
              <Layout>
                <CompanyGroups />
              </Layout>
            </PageWrapper>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.private.viewCompanyGroup}
        element={
          <PrivateRoute>
            <PageWrapper>
              <Layout>
                <ViewCompanyGroup />
              </Layout>
            </PageWrapper>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.private.viewUser}
        element={
          <PrivateRoute>
            <PageWrapper>
              <Layout>
                <ViewUser />
              </Layout>
            </PageWrapper>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.private.properties}
        element={
          <PrivateRoute>
            <PageWrapper>
              <Layout>
                <Properties />
              </Layout>
              <PropertiesDetails />
              <CompsDetails />
            </PageWrapper>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.private.viewProperties}
        element={
          <PrivateRoute>
            <PageWrapper>
              <Layout>
                <ViewProperty />
              </Layout>
            </PageWrapper>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.private.mergeSaleComp}
        element={
          <PrivateRoute>
            <PageWrapper>
              <Layout>
                <MergeSaleComp />
              </Layout>
            </PageWrapper>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.private.mergeRentComp}
        element={
          <PrivateRoute>
            <PageWrapper>
              <Layout>
                <MergeRentComp />
              </Layout>
            </PageWrapper>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.private.mergeExpenseComp}
        element={
          <PrivateRoute>
            <PageWrapper>
              <Layout>
                <MergeExpenseComp />
              </Layout>
            </PageWrapper>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.private.settings}
        element={
          <PrivateRoute>
            <PageWrapper>
              <Layout>
                <UserProfile />
              </Layout>
            </PageWrapper>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.private.myDeals}
        element={
          <PrivateRoute>
            <PageWrapper>
              <Layout>
                <MyDeals />
              </Layout>
            </PageWrapper>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.private.emailActivation}
        element={
          <Navigate
            to={routes.private.myDeals}
            state={{ search: location.search }}
          />
        }
      />
      <Route
        path={routes.private.newProperty}
        element={
          <PrivateRoute>
            <PageWrapper>
              <Layout>
                <AddProperty />
              </Layout>
            </PageWrapper>
          </PrivateRoute>
        }
      />
      <Route
        path={routes.private.templates}
        element={
          <PrivateRoute>
            <PageWrapper>
              <Layout>
                <Templates />
              </Layout>
            </PageWrapper>
          </PrivateRoute>
        }
      />
    </Route>
  );

  const router = createBrowserRouter(createRoutesFromElements(routesComponent));

  return (
    <Suspense fallback={null}>
      <RouterProvider router={router} />
    </Suspense>
  );
};
