import { apiClient } from "lib/apiClient";
import { ICreateCompanyGroup } from "features/CompanyGroups/interfaces";

type Response = {
  id: string;
  companyId: string;
  authServiceId: string;
  firstName: string;
  lastName: string;
  email: string;
  phonePrefix: string;
  phone: string;
  creationDate: string;
  lastModifiedDate: string;
};
export const addCompanyGroup = async (
  request: ICreateCompanyGroup
): Promise<Response> => {
  const response = await apiClient.post(`/companies`, request);

  return response.data;
};
