import {
  xls,
  xlsb,
  xlsm,
  xlsx,
} from "../../components/UploadMultipleFiles/constants";

export const SUPPORTED_TEMPLATE_FILES = "XLS, XLSX, XLSM, XLSB";

export const TEMPLATE_FILE_EXTENSIONS = [xlsx, xls, xlsm, xlsb];
