import { TSetReviewedCompData } from "features/Comp/interfaces";
import { apiClient } from "lib/apiClient";

export const getReviewedComps = async (
  compType: string,
  compId: string,
  propertyId: string
): Promise<TSetReviewedCompData> => {
  const response = await apiClient.get(
    `/reviews/comps/${compType}/${compId}/${propertyId}`
  );

  return response.data;
};
