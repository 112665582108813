import { CancelTokenSource } from "axios";
import {
  FeePlansType,
  IExpenseData,
  IMixData,
  IRevenuesData,
  IReviewedProperties,
  ParticipantDataType,
  RoomRatesType,
  RoomSizesType,
  ServiceAndOccupancyType,
  ServiceDataType,
  TransactionDataType,
} from "features/Comp/interfaces";
import { apiClient } from "lib/apiClient";

export const getCompInfo = async (
  id: string,
  compType: string,
  propertyId: string,
  cancelTokenSource: CancelTokenSource
): Promise<
  IDetailedSaleCompInfo | IDetailedRentCompInfo | IDetailedExpenseCompInfo
> => {
  const response = await apiClient.get(
    `/comps/${compType}/${id}?currentPropertyId=${propertyId}`,
    {
      cancelToken: cancelTokenSource.token,
    }
  );

  return response.data;
};

export type IDetailedSaleCompInfo = {
  createdByAdmin: boolean;
  firstName: string;
  lastName: string;
  companyName: string;
  permissions: string[];
  saleType: string;
  id: string;
  name: string;
  ownerRelatedStatus: string;
  closingDate: string;
  price: number;
  properties: IReviewedProperties[];
  defaultProperty: string;
  participantData: ParticipantDataType;
  transactionData: TransactionDataType;
  serviceData: ServiceDataType;
  activeAdultOffered: boolean;
  assistedLiving: boolean;
  independentLivingOffered: boolean;
  memoryCareOffered: boolean;
  skilledNursing: boolean;
  internalNotes: string | null;
};

export type IDetailedRentCompInfo = {
  comment: string;
  internalNotes: string | null;
  companyName: string;
  contactCompanyName: string | null;
  contactPersonName: string | null;
  createdByAdmin: boolean;
  email: string | null;
  firstName: string;
  id: string;
  lastName: string;
  name: string;
  ownerRelatedStatus: string;
  permissions: string[];
  phone: string | null;
  propertyId: string;
  roomRates: RoomRatesType;
  roomSizes: RoomSizesType;
  feePlans: FeePlansType;
  servicesAndOccupancy: ServiceAndOccupancyType;
  verificationDate: string;
  verificationSource: string;
  rentStatus: string | null;
};

export type IDetailedExpenseCompInfo = {
  companyName: string | null;
  firstName: string | null;
  lastName: string | null;
  id: string;
  name: string;
  financialType: number;
  financialYear: number;
  performance: string;
  oer: number;
  mixData: IMixData;
  revenuesData: IRevenuesData;
  expensesData: IExpenseData;
  comment: string | null;
  internalNotes: string | null;
  ownerRelatedStatus: string;
  propertyId: string;
  permissions: string[];
  createdByAdmin: boolean;
};
