import { ISortProperty } from "features/Property/interfaces";
import { IRequestParams } from "./../pages/Properties/components/PropertiesFilter/PropertiesFilter";
import { apiClient } from "lib/apiClient";
import {
  IExpenseCompTableInfo,
  IRentCompTableInfo,
  ISaleCompTableInfo,
} from "pages/Comps/components/CompsDetails/constants";
import { CancelTokenSource } from "axios";
import { configureCompTableRequestParams } from "./utils/configureCompTableRequestParams";

export interface ISaleCompTableData {
  info: {
    objects: ISaleCompTableInfo[];
    active: number;
    disabled: number;
    totalForPages: number;
  };
  count: {
    total: number;
    myComps: number;
    seniorComps: number;
  };
}

export interface IRentCompTableData {
  info: {
    objects: IRentCompTableInfo[];
    active: number;
    disabled: number;
    totalForPages: number;
  };
  count: {
    total: number;
    myComps: number;
    seniorComps: number;
  };
}

export interface IExpenseCompTableData {
  info: {
    objects: IExpenseCompTableInfo[];
    active: number;
    disabled: number;
    totalForPages: number;
  };
  count: {
    total: number;
    myComps: number;
    seniorComps: number;
  };
}

// According to the BE requirements we have to set undefined if field are empty.

export const getCompsTableData = async (
  page: number,
  pageSize: number,
  compType: string,
  requestParams: IRequestParams | null,
  createdByAdmin: boolean,
  cancelTokenSource: CancelTokenSource,
  sortCompParam?: ISortProperty
): Promise<
  | ISaleCompTableData["info"]
  | IRentCompTableData["info"]
  | IExpenseCompTableData["info"]
> => {
  const response = await apiClient.post(
    `/comps/${compType}/export/preview?page=${page}&pageSize=${pageSize}`,
    {
      sortBy: sortCompParam?.value || undefined,
      createdByAdmin,
      ...configureCompTableRequestParams(requestParams),
    },
    { cancelToken: cancelTokenSource.token }
  );

  return response.data;
};
