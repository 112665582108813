import {
  SET_ALL_PROPERTY,
  SET_PROPERTY_INFO,
  SET_PROPERTY_PHOTO,
  SET_PROPERTY_DETAILS,
  SET_SEARCHED_PROPERTY,
  SET_STATE_COUNTY,
  SET_FILTERED_PROPERTY,
  SET_FILTER_STATES,
  SET_SELECTED_FILTERS,
  SET_SEARCHED_PROPERTY_NAME,
  SET_PROPERTY_TABLE_INFO,
  SET_OPEN_FILTER,
  SET_SUCCESS_PROPERTY_OPEN,
  SET_PROPERTIES_SALE_COMPS,
  SET_PROPERTY_LOADING,
  SET_PROPERTIES_RENT_COMPS,
  SET_PROPERTIES_EXPENSE_COMPS,
  SET_PROPERTY_CANCEL_TOKEN,
  SET_SORT_PROPERTY,
  SET_RESET_FILTERS,
  SET_MAP_BOUNDS,
  SET_BOUNDED_MAP_MODE,
  SET_DEAL_VIEW,
  SET_MAP_ZOOM,
  SET_MAP_CENTER,
  SET_SUCCESS_MODAL_TYPE,
  SET_PROPERTY_ATTACHMENTS,
} from "./PropertyActions";
import { IPropertyState, Actions, ISelectedFilters } from "./interfaces";
import { defineDefaultCounty } from "utils/defineDefaultCounty";
import { resolveDefaultStates } from "pages/Properties/components/PropertiesFilter/constants";
import { defineDefaultSelectedServices } from "pages/AddProperty/components/PropertyForm/constants";

export const defaultSelectedFilters: ISelectedFilters = {
  country: [],
  services: defineDefaultSelectedServices(),
  availableComps: {
    hasSeniorComp: false,
    hasMemberComp: false,
    hasCompUnderReview: false,
  },
  selectedCompTypes: {
    isSaleCompsSelected: false,
    closingDateFrom: null,
    closingDateTo: null,
    pricePerUnitFrom: null,
    pricePerUnitTo: null,
    isRentCompsSelected: false,
    verificationDateFrom: null,
    verificationDateTo: null,
    isExpenseCompsSelected: false,
    plYearFrom: null,
    plYearTo: null,
    revenueFrom: null,
    revenueTo: null,
    expenseFrom: null,
    expenseTo: null,
    saleType: [],
    assets: [],
    propertyRights: [],
    financing: [],
    saleConditions: [],
    propertyCondition: [],
  },
  selectedServicesParams: {
    licensedBedsAlFrom: null,
    licensedBedsAlTo: null,
    licensedBedsMcFrom: null,
    licensedBedsMcTo: null,
    licensedBedsSnFrom: null,
    licensedBedsSnTo: null,
  },
  propertyPhoto: null,
  advancedSearch: {
    region: [],
    marketType: [],
    operatorName: "",
    operatorCheckbox: false,
    profitType: [],
    propertyStatus: [],
    yearOpenedFrom: "",
    yearOpenedTo: "",
    yearOpenedCheckbox: false,
    paymentType: [],
    federalProviderNumber: "",
    federalProviderNumberCheckbox: false,
  },
};

export const initialState: IPropertyState = {
  allProperties: [],
  selectedFilters: defaultSelectedFilters,
  isResetFilters: false,
  propertyTableData: {
    active: 0,
    disabled: 0,
    objects: [],
    totalForPages: 0,
  },
  sortPropertyBy: {
    value: "",
    direction: "",
  },
  mapBounds: null,
  boundedMapMode: true,
  dealView: false,
  filteredProperties: [],
  searchedPropertyName: "",
  filterStates: resolveDefaultStates(),
  searchedProperty: null,
  propertyInfo: {
    propertyId: "",
    name: "",
    country: "",
    state: "",
    region: "",
    city: "",
    street: "",
    zipCode: "",
    activeAdultOffered: false,
    assistedLiving: false,
    independentLivingOffered: false,
    memoryCareOffered: false,
    skilledNursing: false,
    compsCount: {
      EXPENSE: 0,
      SALE: 0,
      RENT: 0,
    },
  },
  county: defineDefaultCounty(),
  propertyPhoto: [],
  cancelToken: {
    propertyInfo: null,
    propertyPhoto: null,
    propertyComps: null,
    propertyTableInfo: null,
    propertyMarkers: null,
    filteredProperty: null,
    exportProperties: null,
    propertyAttachments: null,
  },
  propertyDetails: {
    id: "",
    name: "",
    phonePrefix: "",
    phone: "",
    website: "",
    progressStatus: "",
    operator: "",
    profitType: "",
    latitude: 0,
    longitude: 0,
    country: "",
    state: "",
    county: "",
    region: "",
    city: "",
    street: "",
    zipCode: "",
    marketType: "",
    storeys: 0,
    buildingSizeSfTotal: 0,
    yearOpened: 0,
    expansionOrRenovationHistory: "",
    primaryPaymentType: "",
    activeAdultOffered: false,
    assistedLiving: false,
    independentLivingOffered: false,
    memoryCareOffered: false,
    skilledNursing: false,
    licensedBedsAl: 0,
    licensedBedsMc: 0,
    licensedBedsSn: 0,
    federalProviderNumber: "",
    notes: "",
    sightMapLink: "",
  },
  isOpenFilter: true,
  isSuccessOpen: false,
  successModalType: "property",
  propertiesSaleComps: [],
  propertiesRentComps: [],
  propertiesExpenseComps: [],
  isLoading: {
    propertyInfo: false,
    propertyPhoto: false,
    propertyComps: false,
    propertyTableInfo: false,
    propertyMarkers: false,
    filteredProperty: false,
    exportProperties: false,
    propertyAttachments: false,
  },
  mapZoom: 5,
  mapCenter: {
    lat: 39.5,
    lng: -98.35,
  },
  propertyAttachments: [],
};

const PropertyReducer = (
  state: IPropertyState = initialState,
  action: Actions
) => {
  switch (action.type) {
    case SET_ALL_PROPERTY:
      return {
        ...state,
        allProperties: action.payload,
      };
    case SET_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: action.payload,
      };
    case SET_RESET_FILTERS:
      return {
        ...state,
        isResetFilters: action.payload,
      };
    case SET_FILTERED_PROPERTY:
      return {
        ...state,
        filteredProperties: action.payload,
      };
    case SET_FILTER_STATES:
      return {
        ...state,
        filterStates: action.payload,
      };
    case SET_PROPERTY_INFO:
      return {
        ...state,
        propertyInfo: action.payload,
      };
    case SET_PROPERTY_PHOTO:
      return {
        ...state,
        propertyPhoto: action.payload,
      };
    case SET_PROPERTY_DETAILS:
      return {
        ...state,
        propertyDetails: action.payload,
      };
    case SET_SEARCHED_PROPERTY:
      return {
        ...state,
        searchedProperty: action.payload,
      };
    case SET_STATE_COUNTY:
      return {
        ...state,
        county: action.payload,
      };
    case SET_SEARCHED_PROPERTY_NAME:
      return {
        ...state,
        searchedPropertyName: action.payload,
      };
    case SET_OPEN_FILTER:
      return {
        ...state,
        isOpenFilter: !state.isOpenFilter,
      };
    case SET_PROPERTY_TABLE_INFO:
      return {
        ...state,
        propertyTableData: action.payload,
      };
    case SET_SORT_PROPERTY:
      return {
        ...state,
        sortPropertyBy: action.payload,
      };
    case SET_MAP_BOUNDS:
      return {
        ...state,
        mapBounds: action.payload,
      };
    case SET_BOUNDED_MAP_MODE:
      return {
        ...state,
        boundedMapMode: action.payload,
      };
    case SET_DEAL_VIEW:
      return {
        ...state,
        dealView: action.payload,
      };
    case SET_SUCCESS_PROPERTY_OPEN:
      return {
        ...state,
        isSuccessOpen: action.payload,
      };
    case SET_PROPERTIES_SALE_COMPS:
      return {
        ...state,
        propertiesSaleComps: action.payload,
      };
    case SET_PROPERTIES_RENT_COMPS:
      return {
        ...state,
        propertiesRentComps: action.payload,
      };
    case SET_PROPERTIES_EXPENSE_COMPS:
      return {
        ...state,
        propertiesExpenseComps: action.payload,
      };
    case SET_PROPERTY_LOADING:
      return {
        ...state,
        isLoading: { ...state.isLoading, ...action.payload },
      };
    case SET_PROPERTY_CANCEL_TOKEN:
      return {
        ...state,
        cancelToken: { ...state.cancelToken, ...action.payload },
      };
    case SET_MAP_ZOOM:
      return {
        ...state,
        mapZoom: action.payload,
      };
    case SET_MAP_CENTER:
      return {
        ...state,
        mapCenter: action.payload,
      };
    case SET_SUCCESS_MODAL_TYPE:
      return {
        ...state,
        successModalType: action.payload,
      };
    case SET_PROPERTY_ATTACHMENTS:
      return {
        ...state,
        propertyAttachments: action.payload,
      };
    default:
      return state;
  }
};

export default PropertyReducer;
