import { RootState } from "store/rootReducer";
import { TypedDispatch } from "store/index";
import { getPropertyPhoto, IPhotoDetails } from "api/getPropertyPhoto";
import { addPropertyPhoto } from "api/addPropertyPhoto";
import { getAllMapProperties, IProperty } from "api/getAllMapProperties";
import { getCounties } from "api/getCounties";
import { getPropertyById, IPropertyDetails } from "api/getPropertyById";
import { addProperty } from "api/addProperty";
import {
  IFilterStates,
  IMapBounds,
  IMapCenter,
  ISearchedProperty,
  ISelectedFilters,
  ISortProperty,
  PropertyCancelToken,
  PropertyLoadingPayload,
  SuccessModalConfigKeys,
} from "./interfaces";
import {
  IPropertyInfo,
  IPropertyRequestData,
  IPropertyEditRequestData,
} from "pages/Properties/interfaces";
import { getPropertyDetails } from "api/getPropertyDetails";
import { editProperty } from "api/editProperty";
import {
  getFilteredMapProperties,
  IPropertyResults,
} from "api/getFilteredMapProperties";
import { IRequestParams } from "pages/Properties/components/PropertiesFilter/PropertiesFilter";
import { deletePhoto } from "api/deletePhoto";
import { IMultipleFileData } from "pages/Comps/components/CreateSaleComp/interfaces";
import axios from "axios";
import {
  IPropertyExpenseComps,
  IPropertyRentComps,
  IPropertySaleComps,
} from "api/getAllCompsByPropertyIds";
import { initialState } from "./PropertyReducer";
import { toastMessage } from "components/ToastMessage";
import {
  getGroupedCompsByPropertyIds,
  GroupedCompType,
} from "api/getGroupedCompsByPropertyId";
import { exportProperties } from "api/exportProperties";
import {
  getPropertyAttachments,
  IPropertyAttachments,
} from "api/getPropertyAttachments";
import { deletePropertyAttachments } from "api/deletePropertyAttachments";
import { addPropertyFiles } from "api/addPropertyFiles";

export const SET_ALL_PROPERTY = "SET_ALL_PROPERTY";
export const SET_SELECTED_FILTERS = "SET_SELECTED_FILTERS";
export const SET_RESET_FILTERS = "SET_RESET_FILTERS";
export const SET_FILTERED_PROPERTY = "SET_FILTERED_PROPERTY";
export const SET_FILTER_STATES = "SET_FILTER_STATES";
export const SET_PROPERTY_INFO = "SET_PROPERTY_INFO";
export const SET_PROPERTY_DETAILS = "SET_PROPERTY_DETAILS";
export const SET_PROPERTY_PHOTO = "SET_PROPERTY_PHOTO";
export const SET_SEARCHED_PROPERTY = "SET_SEARCHED_PROPERTY";
export const SET_STATE_COUNTY = "SET_STATE_COUNTY";
export const SET_SEARCHED_PROPERTY_NAME = "SET_SEARCHED_PROPERTY_NAME";
export const SET_PROPERTY_TABLE_INFO = "SET_PROPERTY_TABLE_INFO";
export const SET_SORT_PROPERTY = "SET_SORT_PROPERTY";
export const SET_MAP_BOUNDS = "SET_MAP_BOUNDS";
export const SET_BOUNDED_MAP_MODE = "SET_BOUNDED_MAP_MODE";
export const SET_DEAL_VIEW = "SET_DEAL_VIEW";
export const SET_OPEN_FILTER = "SET_OPEN_FILTER";
export const SET_SUCCESS_PROPERTY_OPEN = "SET_SUCCESS_PROPERTY_OPEN";
export const SET_PROPERTIES_SALE_COMPS = "SET_PROPERTIES_SALE_COMPS";
export const SET_PROPERTY_LOADING = "SET_PROPERTY_LOADING";
export const SET_PROPERTIES_RENT_COMPS = "SET_PROPERTIES_RENT_COMPS";
export const SET_PROPERTIES_EXPENSE_COMPS = "SET_PROPERTIES_EXPENSE_COMPS";
export const SET_PROPERTY_CANCEL_TOKEN = "SET_PROPERTY_CANCEL_TOKEN";
export const SET_MAP_ZOOM = "SET_MAP_ZOOM";
export const SET_MAP_CENTER = "SET_MAP_CENTER";
export const SET_SUCCESS_MODAL_TYPE = "SET_SUCCESS_MODAL_TYPE";
export const SET_PROPERTY_ATTACHMENTS = "SET_PROPERTY_ATTACHMENTS";

export const setAllProperties = (value: IProperty[]) => ({
  type: SET_ALL_PROPERTY,
  payload: value,
});

export const setSearchedPropertyName = (value: string) => ({
  type: SET_SEARCHED_PROPERTY_NAME,
  payload: value,
});

export const setSelectedFilters = (value: ISelectedFilters) => ({
  type: SET_SELECTED_FILTERS,
  payload: value,
});

export const setResetFilters = (value: boolean) => ({
  type: SET_RESET_FILTERS,
  payload: value,
});

export const setFilteredProperties = (value: IProperty[] | []) => ({
  type: SET_FILTERED_PROPERTY,
  payload: value,
});

export const setFilterStates = (value: IFilterStates) => ({
  type: SET_FILTER_STATES,
  payload: value,
});

export const setPropertyInfo = (value: IPropertyDetails) => ({
  type: SET_PROPERTY_INFO,
  payload: value,
});

export const setPropertyPhoto = (value: IPhotoDetails[]) => ({
  type: SET_PROPERTY_PHOTO,
  payload: value,
});

export const setPropertyDetails = (value: IPropertyInfo) => ({
  type: SET_PROPERTY_DETAILS,
  payload: value,
});

export const setSearchedProperty = (value: ISearchedProperty | null) => ({
  type: SET_SEARCHED_PROPERTY,
  payload: value,
});

export const setStateCounty = (value: { [key: string]: string[] }) => ({
  type: SET_STATE_COUNTY,
  payload: value,
});

export const setOpenFilter = () => ({
  type: SET_OPEN_FILTER,
});

export const setPropertyTableInfo = (value: IPropertyResults) => ({
  type: SET_PROPERTY_TABLE_INFO,
  payload: value,
});

export const setSortProperty = (value: ISortProperty) => ({
  type: SET_SORT_PROPERTY,
  payload: value,
});

export const setMapBounds = (value: IMapBounds | null) => ({
  type: SET_MAP_BOUNDS,
  payload: value,
});

export const setBoundedMapMode = (value: boolean) => ({
  type: SET_BOUNDED_MAP_MODE,
  payload: value,
});

export const setDealView = (value: boolean) => ({
  type: SET_DEAL_VIEW,
  payload: value,
});

export const setSuccessModalOpen = (value: boolean) => ({
  type: SET_SUCCESS_PROPERTY_OPEN,
  payload: value,
});

export const setPropertiesSaleComps = (
  value: GroupedCompType<IPropertySaleComps>[]
) => ({
  type: SET_PROPERTIES_SALE_COMPS,
  payload: value,
});

export const setPropertiesRentComps = (
  value: GroupedCompType<IPropertyRentComps>[]
) => ({
  type: SET_PROPERTIES_RENT_COMPS,
  payload: value,
});

export const setPropertiesExpenseComps = (
  value: GroupedCompType<IPropertyExpenseComps>[]
) => ({
  type: SET_PROPERTIES_EXPENSE_COMPS,
  payload: value,
});

export const setPropertyLoading = (value: PropertyLoadingPayload) => ({
  type: SET_PROPERTY_LOADING,
  payload: value,
});

export const setPropertyCancelToken = (value: PropertyCancelToken) => ({
  type: SET_PROPERTY_CANCEL_TOKEN,
  payload: value,
});

export const setMapZoom = (value: number) => ({
  type: SET_MAP_ZOOM,
  payload: value,
});

export const setMapCenter = (value: IMapCenter) => ({
  type: SET_MAP_CENTER,
  payload: value,
});

export const setSuccessModalType = (value: SuccessModalConfigKeys) => ({
  type: SET_SUCCESS_MODAL_TYPE,
  payload: value,
});

export const setPropertyAttachments = (value: IPropertyAttachments[]) => ({
  type: SET_PROPERTY_ATTACHMENTS,
  payload: value,
});

export const fetchAllProperty = () => async (dispatch: TypedDispatch) => {
  dispatch(setPropertyLoading({ propertyMarkers: true }));
  try {
    const response = await getAllMapProperties();
    dispatch(setAllProperties(response));
    dispatch(setPropertyLoading({ propertyMarkers: false }));
  } catch (error) {
    dispatch(setPropertyLoading({ propertyMarkers: false }));
  }
};

export const fetchPropertyTableInfo =
  (
    requestParams: IRequestParams,
    page: number,
    sortPropertyParam?: ISortProperty
  ) =>
  async (dispatch: TypedDispatch, getState: () => RootState) => {
    dispatch(setPropertyLoading({ propertyTableInfo: true }));
    try {
      const { PropertyReducer } = getState();
      const { isLoading, cancelToken } = PropertyReducer;
      if (isLoading.propertyTableInfo && cancelToken.propertyTableInfo) {
        cancelToken.propertyTableInfo.cancel();
      }
      const cancelTokenSource = axios.CancelToken.source();
      dispatch(
        setPropertyCancelToken({ propertyTableInfo: cancelTokenSource })
      );
      const response = await getFilteredMapProperties(
        requestParams,
        false,
        cancelTokenSource,
        page,
        sortPropertyParam
      );
      dispatch(setPropertyLoading({ propertyTableInfo: false }));
      dispatch(setPropertyTableInfo(response));
    } catch (error) {
      !axios.isCancel(error) &&
        dispatch(setPropertyLoading({ propertyTableInfo: false }));
    }
  };

export const fetchFilteredProperty =
  (requestParams: IRequestParams, sortPropertyParam?: ISortProperty) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setPropertyLoading({ filteredProperty: true }));
    try {
      dispatch(fetchPropertyCoordinates(requestParams));
      dispatch(setPropertyLoading({ filteredProperty: false }));
      dispatch(fetchPropertyTableInfo(requestParams, 0, sortPropertyParam));
    } catch (error) {
      dispatch(setPropertyLoading({ filteredProperty: false }));
    }
  };

export const fetchPropertyCoordinates =
  (requestParams: IRequestParams) =>
  async (dispatch: TypedDispatch, getState: () => RootState) => {
    dispatch(setPropertyLoading({ propertyMarkers: true }));
    try {
      const { PropertyReducer } = getState();
      const { isLoading, cancelToken } = PropertyReducer;
      if (isLoading.propertyMarkers && cancelToken.propertyMarkers) {
        cancelToken.propertyMarkers.cancel();
      }
      const cancelTokenSource = axios.CancelToken.source();
      dispatch(setPropertyCancelToken({ propertyMarkers: cancelTokenSource }));
      const response = await getFilteredMapProperties(
        requestParams,
        true,
        cancelTokenSource
      );
      dispatch(setPropertyLoading({ propertyMarkers: false }));
      dispatch(setAllProperties(response.objects));
    } catch (error) {
      !axios.isCancel(error) &&
        dispatch(setPropertyLoading({ propertyMarkers: false }));
    }
  };

export const fetchPropertyPhotoById =
  (id: string) =>
  async (dispatch: TypedDispatch, getState: () => RootState) => {
    dispatch(setPropertyLoading({ propertyPhoto: true }));
    dispatch(setPropertyPhoto(initialState.propertyPhoto));
    try {
      const { PropertyReducer } = getState();
      const { isLoading, cancelToken } = PropertyReducer;
      if (isLoading.propertyPhoto && cancelToken.propertyPhoto) {
        cancelToken.propertyPhoto.cancel();
      }
      const cancelTokenSource = axios.CancelToken.source();
      dispatch(setPropertyCancelToken({ propertyPhoto: cancelTokenSource }));
      const response = await getPropertyPhoto(id, cancelTokenSource);
      dispatch(setPropertyPhoto(response));
      dispatch(setPropertyLoading({ propertyPhoto: false }));
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(setPropertyLoading({ propertyPhoto: false }));

        toastMessage({
          message:
            "The photos are not loaded, please refresh the page and try again.",
          position: "top-right",
          type: "error",
        });
      }
    }
  };

export const fetchPropertyInfoById =
  (id: string) => async (dispatch: TypedDispatch) => {
    dispatch(setPropertyInfo(initialState.propertyInfo));
    dispatch(setPropertyLoading({ propertyInfo: true }));
    try {
      const response = await getPropertyById(id);
      dispatch(setPropertyInfo(response));
      dispatch(setPropertyLoading({ propertyInfo: false }));
    } catch (error) {
      dispatch(setPropertyLoading({ propertyInfo: false }));
      toastMessage({
        message:
          "The main property information is not loaded, please refresh the page and try again.",
        position: "top-right",
        type: "error",
      });
    }
  };

export const createProperty =
  (
    propertyData: IPropertyRequestData,
    photos: IMultipleFileData[],
    files: FormData[],
    closeForm: (id: string) => void
  ) =>
  async (dispatch: TypedDispatch, getState: () => RootState) => {
    dispatch(setPropertyLoading({ propertyPhoto: true }));
    dispatch(setPropertyLoading({ propertyInfo: true }));
    try {
      const { PropertyReducer } = getState();
      const { allProperties } = PropertyReducer;

      const response = await addProperty(propertyData);
      if (photos.length) {
        const requests = photos.map(async (photo) => {
          await addPropertyPhoto(response.id, photo.data, photo.order);
        });
        await Promise.all(requests);
      }
      if (files.length) {
        const createAttachmentRequests = files.map((item) =>
          addPropertyFiles(response.id, item)
        );
        await Promise.all(createAttachmentRequests);
      }
      const location = {
        id: response.id,
        latitude: response.latitude,
        longitude: response.longitude,
      };
      const updatedProperties = [...allProperties, location];
      dispatch(setPropertyLoading({ propertyPhoto: false }));
      dispatch(setPropertyLoading({ propertyInfo: false }));
      dispatch(setAllProperties(updatedProperties));
      closeForm(response.id);
    } catch (error) {
      dispatch(setPropertyLoading({ propertyPhoto: false }));
      dispatch(setPropertyLoading({ propertyInfo: false }));
    }
  };

export const fetchPropertyDetails =
  (id: string) => async (dispatch: TypedDispatch) => {
    dispatch(setPropertyDetails(initialState.propertyDetails));
    dispatch(setPropertyLoading({ propertyInfo: true }));
    try {
      const response = await getPropertyDetails(id);
      dispatch(setPropertyLoading({ propertyInfo: false }));
      dispatch(setPropertyDetails(response));
    } catch (error) {
      dispatch(setPropertyLoading({ propertyInfo: false }));
      toastMessage({
        message:
          "The detailed property information is not loaded, please refresh the page and try again.",
        position: "top-right",
        type: "error",
      });
    }
  };

export const editSelectedProperty =
  (
    propertyData: IPropertyEditRequestData,
    closeForm: () => void,
    handleOpenSuccess: (successType: string) => void
  ) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setPropertyLoading({ propertyInfo: true }));
    try {
      await editProperty(propertyData);
      closeForm();
      dispatch(fetchPropertyInfoById(propertyData.id));
      dispatch(fetchPropertyDetails(propertyData.id));
      dispatch(fetchPropertiesComps(propertyData.id));
      handleOpenSuccess("property");
      dispatch(setPropertyLoading({ propertyInfo: false }));
    } catch (error) {
      dispatch(setPropertyLoading({ propertyInfo: false }));
    }
  };

export const editPropertyPhoto =
  (photos: IMultipleFileData[], photosToDelete: string[], id: string) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setPropertyLoading({ propertyPhoto: true }));
    try {
      if (photosToDelete.length) {
        const requests = photosToDelete.map(
          async (id) => await deletePhoto(id)
        );
        await Promise.all(requests);
      }

      if (photos.length) {
        const requests = photos.map(async (photo) => {
          await addPropertyPhoto(id, photo.data, photo.order);
        });
        await Promise.all(requests);
      }
      dispatch(fetchPropertyPhotoById(id));
    } catch (error) {
      dispatch(setPropertyLoading({ propertyPhoto: false }));
    }
  };

export const fetchStateCounty =
  (state: string) =>
  async (dispatch: TypedDispatch, getState: () => RootState) => {
    try {
      const { PropertyReducer } = getState();
      const { CompanyGroupsReducer } = getState();
      const { county } = PropertyReducer;
      const {
        companyGroupInfo: { statesCounties },
      } = CompanyGroupsReducer;
      const response = await getCounties(state, "");
      const data = statesCounties[state]?.length
        ? statesCounties[state]
        : response;
      const updatedCounty = {
        ...county,
        [state]: data,
      };
      dispatch(setStateCounty(updatedCounty));
    } catch (error) {
      console.log(error);
    }
  };

export const fetchPropertiesComps =
  (id: string) => async (dispatch: TypedDispatch) => {
    dispatch(setPropertyLoading({ propertyComps: true }));
    try {
      const comps = await getGroupedCompsByPropertyIds(id);
      dispatch(setPropertyLoading({ propertyComps: false }));
      dispatch(setPropertiesSaleComps(comps.sale));
      dispatch(setPropertiesRentComps(comps.rent));
      dispatch(setPropertiesExpenseComps(comps.expense));
    } catch (error) {
      dispatch(setPropertyLoading({ propertyComps: false }));
    }
  };

export const exportFiltredProperties =
  (requestParams: IRequestParams, sortPropertyParam?: ISortProperty) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setPropertyLoading({ exportProperties: true }));
    const cancelTokenSource = axios.CancelToken.source();
    dispatch(setPropertyCancelToken({ exportProperties: cancelTokenSource }));
    try {
      await exportProperties(
        requestParams,
        cancelTokenSource,
        sortPropertyParam
      );
      dispatch(setPropertyLoading({ exportProperties: false }));
      dispatch(setSuccessModalType("exportProperties"));
      dispatch(setSuccessModalOpen(true));
    } catch (error) {
      dispatch(setPropertyLoading({ exportProperties: false }));
      toastMessage({
        message: "Failed to export properties.",
        position: "top-right",
        type: "error",
      });
    }
  };

export const fetchPropertyAttachments =
  (id: string) => async (dispatch: TypedDispatch) => {
    dispatch(setPropertyLoading({ propertyAttachments: true }));
    try {
      const cancelTokenSource = axios.CancelToken.source();
      dispatch(
        setPropertyCancelToken({ propertyAttachments: cancelTokenSource })
      );
      const attachments = await getPropertyAttachments(id, cancelTokenSource);
      dispatch(setPropertyAttachments(attachments));
      dispatch(setPropertyLoading({ propertyAttachments: false }));
    } catch (error) {
      console.log(error);
      dispatch(setPropertyLoading({ propertyAttachments: false }));
    }
  };

export const managePropertyFiles =
  (id: string, files: FormData[], attachmentsToDelete: string[]) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setPropertyLoading({ propertyAttachments: true }));
    try {
      if (attachmentsToDelete.length) {
        const requests = attachmentsToDelete.map((id) =>
          deletePropertyAttachments(id)
        );
        await Promise.all(requests);
      }
      if (files.length) {
        const createAttachmentRequests = files.map((item) =>
          addPropertyFiles(id, item)
        );
        await Promise.all(createAttachmentRequests);
      }
      dispatch(fetchPropertyAttachments(id));
      dispatch(setPropertyLoading({ propertyAttachments: false }));
    } catch (error) {
      dispatch(setPropertyLoading({ propertyAttachments: false }));
    }
  };
