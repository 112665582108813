import { apiClient } from "lib/apiClient";

export interface IProperty {
  id: string;
  latitude: number;
  longitude: number;
}

export const getAllMapProperties = async (): Promise<IProperty[]> => {
  const response = await apiClient.get(`/properties/coordinates/all`);

  return response.data;
};
