import { IRequestParams } from "pages/Properties/components/PropertiesFilter/PropertiesFilter";

export const configureCompTableRequestParams = (
  requestParams: IRequestParams | null
) => {
  const {
    selectedServices,
    states,
    checkedCountry,
    searchedPropertyName,
    selectedCompTypes,
    selectedServicesParams,
    propertyPhoto,
    availableComps,
    latitudeMin,
    latitudeMax,
    longitudeMin,
    longitudeMax,
    dealView,
    advancedSearch,
  } = requestParams || {};

  return {
    ...selectedServices,
    statesCounties: states || undefined,
    country: checkedCountry?.join(",") || undefined,
    name: searchedPropertyName || undefined,
    hasSaleComp: selectedCompTypes?.isSaleCompsSelected || undefined,
    closingDateFrom: selectedCompTypes?.closingDateFrom || undefined,
    closingDateTo: selectedCompTypes?.closingDateTo || undefined,
    pricePerUnitFrom: selectedCompTypes?.pricePerUnitFrom || undefined,
    pricePerUnitTo: selectedCompTypes?.pricePerUnitTo || undefined,
    hasRentComp: selectedCompTypes?.isRentCompsSelected || undefined,
    verificationDateFrom: selectedCompTypes?.verificationDateFrom || undefined,
    verificationDateTo: selectedCompTypes?.verificationDateTo || undefined,
    hasExpenseComp: selectedCompTypes?.isExpenseCompsSelected || undefined,
    expenseYearFrom: selectedCompTypes?.plYearFrom || undefined,
    expenseYearTo: selectedCompTypes?.plYearTo || undefined,
    revenuePerResidentDaysFrom: selectedCompTypes?.revenueFrom || undefined,
    revenuePerResidentDaysTo: selectedCompTypes?.revenueTo || undefined,
    expenseRatioFrom: selectedCompTypes?.expenseFrom || undefined,
    expenseRatioTo: selectedCompTypes?.expenseTo || undefined,
    licensedBedsAlFrom: selectedServicesParams?.licensedBedsAlFrom || undefined,
    licensedBedsAlTo: selectedServicesParams?.licensedBedsAlTo || undefined,
    licensedBedsMcFrom: selectedServicesParams?.licensedBedsMcFrom || undefined,
    licensedBedsMcTo: selectedServicesParams?.licensedBedsMcTo || undefined,
    licensedBedsSnFrom: selectedServicesParams?.licensedBedsSnFrom || undefined,
    licensedBedsSnTo: selectedServicesParams?.licensedBedsSnTo || undefined,
    hasPhoto: propertyPhoto,
    hasSeniorComp: availableComps?.hasSeniorComp || undefined,
    hasMemberComp: availableComps?.hasMemberComp || undefined,
    hasCompUnderReview: availableComps?.hasCompUnderReview || undefined,
    longitudeMin: longitudeMin || undefined,
    longitudeMax: longitudeMax || undefined,
    latitudeMin: latitudeMin || undefined,
    latitudeMax: latitudeMax || undefined,
    dealView: dealView || undefined,
    marketTypes: advancedSearch?.marketType?.length
      ? advancedSearch?.marketType
      : undefined,
    operator: advancedSearch?.operatorName || undefined,
    isOperatorUnknown: advancedSearch?.operatorCheckbox || undefined,
    profitTypes: advancedSearch?.profitType?.length
      ? advancedSearch?.profitType
      : undefined,
    propertyStatuses: advancedSearch?.propertyStatus?.length
      ? advancedSearch?.propertyStatus
      : undefined,
    yearOpenedFrom: advancedSearch?.yearOpenedFrom || undefined,
    yearOpenedTo: advancedSearch?.yearOpenedTo || undefined,
    isYearOpenedUnknown: advancedSearch?.yearOpenedCheckbox || undefined,
    primaryPaymentTypes: advancedSearch?.paymentType?.length
      ? advancedSearch?.paymentType
      : undefined,
    federalProviderNumber: advancedSearch?.federalProviderNumber || undefined,
    isFederalProviderNumberUnknown:
      advancedSearch?.federalProviderNumberCheckbox || undefined,
    saleTypes: selectedCompTypes?.saleType?.length
      ? selectedCompTypes.saleType
      : undefined,
    assetsTypes: selectedCompTypes?.assets?.length
      ? selectedCompTypes.assets
      : undefined,
    propertyRights: selectedCompTypes?.propertyRights?.length
      ? selectedCompTypes.propertyRights
      : undefined,
    financing: selectedCompTypes?.financing?.length
      ? selectedCompTypes.financing
      : undefined,
    saleConditions: selectedCompTypes?.saleConditions?.length
      ? selectedCompTypes.saleConditions
      : undefined,
    propertyConditions: selectedCompTypes?.propertyCondition?.length
      ? selectedCompTypes.propertyCondition
      : undefined,
  };
};
