import { apiClient } from "lib/apiClient";
import { removeFileExtension } from "../utils/removeFileExtension";

export const exportComp = async (
  compIds: string[],
  compType: string,
  propertyComps?: boolean
): Promise<{ blob: Blob; fileName?: string }> => {
  const urlEnding = propertyComps ? "/properties" : "";
  const response = await apiClient.post(
    `/export/xlsx/${compType}${urlEnding}`,
    compIds,
    {
      responseType: "blob",
    }
  );
  const contentDisposition = response.headers?.["content-disposition"];
  const filename = contentDisposition?.split("filename=")?.[1];
  return { blob: response.data, fileName: removeFileExtension(filename) };
};
