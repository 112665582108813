import React, { useState } from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { isAdmin, SIDEBAR_CONFIG } from "config/constants";
import logo from "assets/images/logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./SideBar.module.scss";
import clsx from "clsx";
import { useSelector } from "react-redux";
import * as selector from "store/selectors/signIn";
import logoutConfirm from "assets/images/logoutConfirm.png";
import logout from "assets/svg/logout.svg";
import { ConfirmPopup } from "components/ConfirmPopup";
import { routes } from "config/routes";

export const SideBar = () => {
  const theme = useTheme();
  const location = useLocation();
  const userInfo = useSelector(selector.userInfo);
  const navigate = useNavigate();
  const [openConfirm, setOpenConfirm] = useState(false);
  const isMember = !isAdmin(userInfo.roles);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const pushToLogin = () => {
    navigate(routes.public.login);
  };

  return (
    <Box
      sx={{
        width: "90px",
        height: "800px",
        marginRight: "30px",
        borderRadius: "6px",
        border: "1px solid #F3F3F3",
        backgroundColor: "white",
      }}
    >
      <Box
        sx={{
          height: "120px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderBottom: "1px solid #E3E3E3",
          marginBottom: "30px",
        }}
      >
        <img
          src={logo}
          alt="logo"
          style={{
            marginRight: 0,
          }}
        />
      </Box>
      <Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingLeft: 0,
              marginBottom: "45px",
              gap: "6px",
            }}
          >
            <Typography
              sx={{
                marginRight: 0,
                fontSize: "18px",
                fontWeight: "600",
              }}
            >
              {userInfo.firstName[0]}
              {userInfo.lastName[0]}
            </Typography>
            <Box
              sx={{
                padding: "5px",
                borderRadius: "4px",
                backgroundColor: isMember
                  ? theme.palette.secondary.main
                  : theme.palette.primary.main,
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontSize: "12px",
                  fontWeight: "600",
                }}
              >
                {isMember ? "Member" : "Admin"}
              </Typography>
            </Box>
          </Box>
          <Box component="ul">
            {SIDEBAR_CONFIG.map((item) => {
              const isRouteSelected =
                location.pathname === item.route ||
                location.pathname.includes(item.route);
              const isRouteInvisibleForMember =
                item.route === routes.private.companyGroups && isMember;
              return (
                <Link
                  to={item.route}
                  key={item.id}
                  style={{
                    height: "60px",
                    paddingLeft: 0,
                    marginBottom: "10px",
                    display: isRouteInvisibleForMember ? "none" : "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: isRouteSelected
                      ? "#F9F9F9"
                      : "transparent",
                    borderLeft: isRouteSelected
                      ? `4px solid ${theme.palette.primary.main}`
                      : "0",
                  }}
                >
                  <Box
                    component="li"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <item.Icon
                      className={clsx({
                        [styles.activeRoute]: isRouteSelected,
                        [styles.inActiveRoute]: !isRouteSelected,
                      })}
                      style={{
                        marginRight: 0,
                      }}
                    />
                  </Box>
                </Link>
              );
            })}
            <Button
              onClick={handleOpenConfirm}
              sx={{
                width: "100%",
                justifyContent: "center",
                p: 0,
                pl: 0,

                "&:hover": {
                  backgroundColor: "transparent",
                },

                "& .MuiTypography-root": {
                  color: "#3D3D3D",
                },
              }}
            >
              <img
                src={logout}
                alt="logout"
                style={{
                  marginRight: 0,
                }}
              />
            </Button>
          </Box>
        </Box>
      </Box>
      <ConfirmPopup
        open={openConfirm}
        icon={logoutConfirm}
        title="Are you sure you want to log out?"
        subtitle=""
        cancelButtonTitle="NO, GO BACK"
        confirmButtonTitle="YES"
        handleClose={handleCloseConfirm}
        handleSubmit={pushToLogin}
      />
    </Box>
  );
};
