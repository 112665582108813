import { ICompTypes } from "../features/Comp/interfaces";
import { routes } from "config/routes";
import { ReactComponent as CompanyGroups } from "assets/svg/companyGroup.svg";
import { ReactComponent as Properties } from "assets/svg/properties.svg";
import { ReactComponent as Settings } from "assets/svg/settings.svg";
import { ReactComponent as MyDeals } from "assets/svg/bookmark.svg";
import { ReactComponent as Templates } from "assets/svg/templateIcon.svg";
import { templatesTranslations } from "../pages/Templates/translations";

export const appVersion = "1";

export const SIDEBAR_CONFIG = [
  {
    id: 1,
    title: "Company Groups",
    route: routes.private.companyGroups,
    Icon: CompanyGroups,
  },
  {
    id: 2,
    title: "Properties",
    route: routes.private.properties,
    Icon: Properties,
  },
  {
    id: 4,
    title: "My Deals",
    route: routes.private.myDeals,
    Icon: MyDeals,
  },
  {
    id: 5,
    title: templatesTranslations.templates,
    route: routes.private.templates,
    Icon: Templates,
  },
  {
    id: 6,
    title: "Settings",
    route: routes.private.settings,
    Icon: Settings,
  },
];

export const subscriptionColorConfig: {
  [key: string]: string;
} = {
  PRO: "#E1A34B",
  TEAM: "#67B75A",
  BASIC: "#D3D3D3",
};

export const COLLAPSED_ROUTES = [
  routes.private.properties,
  routes.private.viewProperties,
  routes.private.viewSaleComp,
  routes.private.viewRentComp,
  routes.private.viewExpenseComp,
  routes.private.mergeSaleComp,
  routes.private.mergeRentComp,
  routes.private.mergeExpenseComp,
  routes.private.newProperty,
];

export const titleStyles = {
  fontSize: "12px",
  fontWeight: "600",
};
export const valueStyles = {
  fontSize: "16px",
  fontWeight: "300",
};

export const SUBSCRIPTION_TYPES = {
  PRO: "PRO",
  TEAM: "TEAM",
  BASIC: "BASIC",
};

const ADMIN = "ADMIN";

export const isAdmin = (roles: string[]) => {
  return roles.includes(ADMIN);
};

export const forbiddenMemberRoutes = [
  routes.private.companyGroups,
  routes.private.viewCompanyGroup,
  routes.private.viewUser,
];

export const coinsValue = 1;
export const disabledAccountError = "Your SeniorComps account was disabled.";
export const deletedAccountError = "Your SeniorComps account was deleted.";
export const phonePrefix = "+1";
export const submitKey = "Enter";
export const removeKey = "removeOption";
export const percentMultiplicator = 100;
export const timezoneUTC = "UTC";
export const seniorType = "senior";
export const blockedStatus = "blocked";
export const logoutEvent = "logout";
export const broadcastLogoutChannel = "logout-channel";

export const maxIndex = 2;
export const slashKey = "/";
export const COMP_TYPES: { [key: string]: ICompTypes } = {
  sale: "sale",
  rent: "rent",
  expense: "expense",
};
export const zeroWidthSpace = "\u200b";
export const nonBreakingSpace = "\u00A0";
export const blackCircle = "\u25CF";
export const newline = "\n";
export const landingWidth = "1440px";
export const GOOGLE_ANALYTICS_ID = "G-LNRPZDCTGX";
export const AUTO_FILL_EVENT_NAME = "mui-auto-fill";
export const AUTO_FILL_CANCEL_EVENT_NAME = "mui-auto-fill-cancel";

export const SORT_DIRECTION: { [key: string]: "asc" | "desc" } = {
  asc: "asc",
  desc: "desc",
};

export const DEFAULT_PROPERTY_ZOOM_LEVEL = 17;
export const MIDDLE_CLICK_KEY = 1;
export const LEFT_CLICK_KEY = 0;
