import { TypedDispatch } from "store";
import {
  DealLoadingPayload,
  DealTypes,
  ICreateDeal,
  IDeal,
  IDealCompsCount,
  IDealsInfo,
} from "./interfaces";
import { addDeal } from "api/addDeal";
import { getAllDeals } from "api/getAllDeals";
import { IEditDeal, editDealById } from "api/editDealById";
import { IEditDealSubjects, editSubjectById } from "api/editSubjectById";
import { addSubjects } from "api/addSubjects";
import { addCompetitors } from "api/addCompetitors";
import { searchByDealName } from "api/searchByDealName";
import { ISelectedDealProperties } from "pages/MyDeals/components/DealPropertyModal/DealPropertyModal";
import { deleteDeals } from "api/deleteDeals";
import { archiveDeals } from "api/archiveDeals";
import { unarchiveDeals } from "api/unarchiveDeals";
import { getDealCompsCount } from "api/getDealCompsCount";

export const SET_MODAL_OPEN = "SET_MODAL_OPEN";
export const SET_DEALS = "SET_DEALS";
export const SET_DEALS_LOADING = "SET_DEALS_LOADING";
export const SET_SUCCESS_DEALS_OPEN = "SET_SUCCESS_DEALS_OPEN";
export const SET_EMAIL_MODAL_OPEN = "SET_EMAIL_MODAL_OPEN";
export const SET_CURRENT_DEAL = "SET_CURRENT_DEAL";
export const SET_DEAL_TYPE = "SET_DEAL_TYPE";
export const SET_DEAL_COMPS_COUNT = "SET_DEAL_COMPS_COUNT";

export const setModalOpen = (value: {
  [key in "deal" | "dealProperty"]?: boolean;
}) => ({
  type: SET_MODAL_OPEN,
  payload: value,
});

export const setDeals = (value: IDealsInfo) => ({
  type: SET_DEALS,
  payload: value,
});

export const setDealsLoading = (value: DealLoadingPayload) => ({
  type: SET_DEALS_LOADING,
  payload: value,
});

export const setSuccessModalOpen = (value: boolean) => ({
  type: SET_SUCCESS_DEALS_OPEN,
  payload: value,
});

export const setEmailModalOpen = (value: boolean) => ({
  type: SET_EMAIL_MODAL_OPEN,
  payload: value,
});

export const setCurrentDeal = (value: IDeal) => ({
  type: SET_CURRENT_DEAL,
  payload: value,
});

export const setDealType = (value: DealTypes) => ({
  type: SET_DEAL_TYPE,
  payload: value,
});

export const setDealCompsCount = (value: IDealCompsCount | null) => ({
  type: SET_DEAL_COMPS_COUNT,
  payload: value,
});

export const createDeal =
  (request: ICreateDeal, status?: string) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setDealsLoading({ deals: true }));
    try {
      await addDeal(request);
      dispatch(fetchDeals(status));
    } catch (error) {
      dispatch(setDealsLoading({ deals: false }));
    }
  };

export const fetchDeals =
  (status?: string) => async (dispatch: TypedDispatch) => {
    dispatch(setDealsLoading({ deals: true }));
    try {
      const response = await getAllDeals(status);
      dispatch(setDeals(response));
      dispatch(setDealsLoading({ deals: false }));
    } catch (error) {
      dispatch(setDealsLoading({ deals: false }));
    }
  };

export const updateDealById =
  (id: string, request: IEditDeal) => async (dispatch: TypedDispatch) => {
    dispatch(setDealsLoading({ deals: true }));
    try {
      await editDealById(id, request);
      dispatch(fetchDeals());
    } catch (error) {
      dispatch(setDealsLoading({ deals: false }));
    }
  };

export const updateSubjectEmail =
  (dealId: string, id: string, request: IEditDealSubjects) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setDealsLoading({ deals: true }));
    try {
      await editSubjectById(dealId, id, request);
      dispatch(fetchDeals());
    } catch (error) {
      dispatch(setDealsLoading({ deals: false }));
    }
  };

export const addSubjectsToDeal =
  (id: string, request: ISelectedDealProperties[]) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setDealsLoading({ deals: true }));
    try {
      await addSubjects(id, request);
      dispatch(fetchDeals());
    } catch (error) {
      dispatch(setDealsLoading({ deals: false }));
    }
  };

export const addCompetitorsToDeal =
  (dealId: string, id: string, request: string[]) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setDealsLoading({ deals: true }));
    try {
      await addCompetitors(dealId, id, request);
      dispatch(fetchDeals());
    } catch (error) {
      dispatch(setDealsLoading({ deals: false }));
    }
  };

export const addFilteredDeals =
  (paramName: string, value: string, status?: string) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setDealsLoading({ deals: true }));
    try {
      if (value) {
        const response = await searchByDealName(paramName, value, status);
        dispatch(setDeals(response));
        dispatch(setDealsLoading({ deals: false }));
      } else {
        dispatch(fetchDeals());
      }
    } catch (error) {
      dispatch(setDealsLoading({ deals: false }));
    }
  };

export const deleteSelectedDeals =
  (request: string[], urlEnding?: string) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setDealsLoading({ deals: true }));
    try {
      await deleteDeals(request, urlEnding);
    } catch (error) {
      dispatch(setDealsLoading({ deals: false }));
    }
  };

export const archiveSelectedDeals =
  (request: string[]) => async (dispatch: TypedDispatch) => {
    dispatch(setDealsLoading({ deals: true }));
    try {
      await archiveDeals(request);
      dispatch(fetchDeals());
    } catch (error) {
      dispatch(setDealsLoading({ deals: false }));
    }
  };

export const unarchiveSelectedDeals =
  (request: string[], status?: string) => async (dispatch: TypedDispatch) => {
    dispatch(setDealsLoading({ deals: true }));
    try {
      await unarchiveDeals(request);
      dispatch(fetchDeals(status));
    } catch (error) {
      dispatch(setDealsLoading({ deals: false }));
    }
  };

export const fetchDealCompsCount =
  (ids: string[]) => async (dispatch: TypedDispatch) => {
    dispatch(setDealsLoading({ dealCompsCount: true }));
    try {
      const response = await getDealCompsCount(ids);
      dispatch(setDealCompsCount(response));
      dispatch(setDealsLoading({ dealCompsCount: false }));
    } catch (error) {
      dispatch(setDealsLoading({ dealCompsCount: false }));
    }
  };
