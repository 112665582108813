import { newline } from "config/constants";

export const VALIDATION_ERRORS = {
  addFile: "Upload Excel file",
  filesLimit: "Upload failed: Only 1 file upload is available at a time",
};

export const UPLOAD_CONFIG = {
  supportedFiles: "XLSX, CSV",
  supportedFileNames: ["Rent_", "Sale_", "Expense_"],
  additionalText: `${newline}${newline}Filename must begin with either Rent_, Sale_, or Expense_${newline}
  For portfolio sale comps, use a single Property ID and then edit manually through the UI`,
  filesLimit: 1,
};
