import React, { useEffect } from "react";
import { routes } from "config/routes";
import { localStorageManager } from "lib/localStorageManager";
import { Navigate, useLocation, matchPath } from "react-router-dom";
import { fetchUserInfo } from "features/SignIn/SignInActions";
import { useTypedDispatch } from "store";
import { useSelector } from "react-redux";
import * as selectors from "store/selectors/signIn";
import { fetchCompanyGroupInfo } from "features/CompanyGroups/CompanyGroupsActions";
import { broadcastLogoutChannel, logoutEvent } from "config/constants";

type Props = {
  children: JSX.Element;
};

export const PrivateRoute = ({ children }: Props) => {
  const dispatch = useTypedDispatch();
  const userInfo = useSelector(selectors.userInfo);
  const location = useLocation();

  useEffect(() => {
    const id = localStorageManager.getAuthServiceId();
    if (id && !userInfo.id) {
      dispatch(fetchUserInfo(id));
    }
  }, [dispatch]);

  useEffect(() => {
    const isPathMatch = matchPath(
      routes.private.viewCompanyGroup,
      location.pathname
    );

    if (userInfo.companyId && !isPathMatch) {
      dispatch(fetchCompanyGroupInfo(userInfo.companyId));
    }
  }, [location.pathname, userInfo.companyId]);

  useEffect(() => {
    const channel = new BroadcastChannel(broadcastLogoutChannel);
    channel.onmessage = (event) => {
      if (event.data === logoutEvent) {
        window.location.replace(routes.public.login);
      }
    };

    return () => {
      channel.close();
    };
  }, []);

  const auth = localStorageManager.getToken();
  return auth ? (
    children
  ) : (
    <Navigate to={routes.public.login} state={{ ...location }} />
  );
};
