import React from "react";
import { toast, TypeOptions } from "react-toastify";
import { Box, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

import styles from "./ToastMessage.module.scss";

type Content = {
  message: string;
  type: TypeOptions;
};

const ContentComponent = (props: Content) => {
  return (
    <Box className={styles.toastContent}>
      {props.type === "success" && (
        <CheckCircleIcon className={styles.svgSuccess} />
      )}
      {props.type === "error" && <ErrorIcon className={styles.svgError} />}
      <Typography
        sx={{
          fontSize: "14px",
        }}
      >
        {props.message}
      </Typography>
    </Box>
  );
};

type Props = {
  message: string;
  type: TypeOptions;
  position:
    | "top-right"
    | "top-center"
    | "top-left"
    | "bottom-right"
    | "bottom-center"
    | "bottom-left";
};

export const toastMessage = ({ message, type, position }: Props) => {
  return toast(<ContentComponent message={message} type={type} />, {
    type,
    position,
  });
};
