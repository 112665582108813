import { apiClient } from "lib/apiClient";
import { PricingAnalysisConfigData } from "./getPricingAnalysisConfig";

export const getDefaultPricingAnalysisConfig = async (
  propertyId: string
): Promise<PricingAnalysisConfigData[]> => {
  const response = await apiClient.get(
    `/configuration/analysis/pricing/rent/default/${propertyId}`
  );

  return response.data;
};
