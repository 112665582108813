import { ICreateUser } from "features/CompanyGroups/interfaces";
import { IUsers } from "./getCompanyGroupUsers";
import { apiClient } from "lib/apiClient";

export interface IAddUserRequest extends ICreateUser {
  authServiceId: string;
}

export const addUser = async (request: IAddUserRequest): Promise<IUsers> => {
  const response = await apiClient.post(`/users`, request);

  return response.data;
};
