import {
  IExpenseCompTableInfo,
  IRentCompTableInfo,
  ISaleCompTableInfo,
} from "pages/Comps/components/CompsDetails/constants";
import { IAttachments } from "api/getAllAttachments";
import { IPropertyDetails } from "api/getPropertyById";
import { ICreateSaleComp } from "pages/Comps/components/CreateSaleComp/interfaces";
import * as constant from "./constantTypes";
import {
  IExpenseCompTableData,
  IRentCompTableData,
  ISaleCompTableData,
} from "api/getCompsTableData";
import { ISortProperty } from "features/Property/interfaces";
import { RentUsavedValuesType } from "pages/Comps/components/RentCompModal/constants";
import { CancelTokenSource } from "axios";
import { FieldsUnsavedValuesType } from "pages/Comps/components/CreateSaleComp/constants";
import { PricingAnalysisConfigData } from "api/getPricingAnalysisConfig";
import { ExpenseAnalysisConfig } from "api/editExpenseAnalysisConfig";
import { ExpenseAnalysisData } from "api/getExpenseAnalysisChart";
import { ExpenseUnsavedValuesType } from "pages/Comps/components/ExpenseCompModal/constants/validationSchema";

export type ICompTypes = "sale" | "rent" | "expense";
export type ICompServices =
  | "activeAdult"
  | "independentLiving"
  | "assistedLiving"
  | "memoryCare"
  | "skilledNursing";

export type CompCancelKeys =
  | "compPrice"
  | "saleTableInfo"
  | "saleTableCount"
  | "saleCompInfo"
  | "saleCompAttachments"
  | "rentTableInfo"
  | "rentTableCount"
  | "rentCompInfo"
  | "rentCompAttachments"
  | "expenseTableInfo"
  | "expenseTableCount"
  | "expenseCompInfo"
  | "expenseCompAttachments"
  | "pricingAnalysis"
  | "expenseAnalysisData"
  | "expenseAnalysisCompCount";

export const COMP_ACTIONS_KEYS: {
  [key in ICompTypes]: {
    compTableInfo: CompCancelKeys;
    compTableCount: CompCancelKeys;
    compInfo: CompCancelKeys;
    compAttachments: CompCancelKeys;
  };
} = {
  sale: {
    compTableInfo: "saleTableInfo",
    compTableCount: "saleTableCount",
    compInfo: "saleCompInfo",
    compAttachments: "saleCompAttachments",
  },
  rent: {
    compTableInfo: "rentTableInfo",
    compTableCount: "rentTableCount",
    compInfo: "rentCompInfo",
    compAttachments: "rentCompAttachments",
  },
  expense: {
    compTableInfo: "expenseTableInfo",
    compTableCount: "expenseTableCount",
    compInfo: "expenseCompInfo",
    compAttachments: "expenseCompAttachments",
  },
};

export type CompCancelTokenKeys = CompCancelKeys | ICompTypes | "compPrice";

export type CompCancelToken = {
  [key in CompCancelTokenKeys]?: CancelTokenSource | null;
};

export type ServiceAndOccupancyType = {
  activeAdultOfferedUnits: string;
  activeAdultOccupancy: string;
  independentLivingUnits: string;
  independentLivingOccupancy: string;
  assistedLivingUnits: string;
  assistedLivingBeds: string;
  assistedLivingOccupancy: string;
  assistedLivingPrivate: string;
  assistedLivingMedicaid: string;
  memoryCareUnits: string;
  memoryCareBeds: string;
  memoryCareOccupancy: string;
  memoryCarePrivate: string;
  memoryCareMedicaid: string;
  skilledNursingUnits: string;
  skilledNursingBeds: string;
  skilledNursingOccupancy: string;
  skilledNursingPrivate: string;
  skilledNursingMedicaid: string;
  skilledNursingMedicare: string;
  skilledNursingInsurance: string;
  skilledNursingOther: string;
};

export type RoomRatesType = {
  wardAssistedLivingMin: string;
  wardAssistedLivingMax: string;
  wardMemoryCareMin: string;
  wardMemoryCareMax: string;
  wardSkilledNursingMin: string;
  wardSkilledNursingMax: string;
  semiPrivateAssistedLivingMin: string;
  semiPrivateAssistedLivingMax: string;
  semiPrivateMemoryCareMin: string;
  semiPrivateMemoryCareMax: string;
  semiPrivateSkilledNursingMin: string;
  semiPrivateSkilledNursingMax: string;
  studioActiveAdultMin: string;
  studioActiveAdultMax: string;
  studioIndependentLivingMin: string;
  studioIndependentLivingMax: string;
  studioAssistedLivingMin: string;
  studioAssistedLivingMax: string;
  studioMemoryCareMin: string;
  studioMemoryCareMax: string;
  studioSkilledNursingMin: string;
  studioSkilledNursingMax: string;
  oneBedroomActiveAdultMin: string;
  oneBedroomActiveAdultMax: string;
  oneBedroomIndependentLivingMin: string;
  oneBedroomIndependentLivingMax: string;
  oneBedroomAssistedLivingMin: string;
  oneBedroomAssistedLivingMax: string;
  oneBedroomMemoryCareMin: string;
  oneBedroomMemoryCareMax: string;
  twoBedroomActiveAdultMin: string;
  twoBedroomActiveAdultMax: string;
  twoBedroomIndependentLivingMin: string;
  twoBedroomIndependentLivingMax: string;
  twoBedroomAssistedLivingMin: string;
  twoBedroomAssistedLivingMax: string;
  twoBedroomMemoryCareMin: string;
  twoBedroomMemoryCareMax: string;
  threeBedroomActiveAdultMin: string;
  threeBedroomActiveAdultMax: string;
  threeBedroomIndependentLivingMin: string;
  threeBedroomIndependentLivingMax: string;
  threeBedroomAssistedLivingMin: string;
  threeBedroomAssistedLivingMax: string;
  threeBedroomMemoryCareMin: string;
  threeBedroomMemoryCareMax: string;
  cottageActiveAdultMin: string;
  cottageActiveAdultMax: string;
  cottageIndependentLivingMin: string;
  cottageIndependentLivingMax: string;
  cottageAssistedLivingMin: string;
  cottageAssistedLivingMax: string;
  cottageMemoryCareMin: string;
  cottageMemoryCareMax: string;
  secondPersonActiveAdultMin: string;
  secondPersonActiveAdultMax: string;
  secondPersonIndependentLivingMin: string;
  secondPersonIndependentLivingMax: string;
  secondPersonAssistedLivingMin: string;
  secondPersonAssistedLivingMax: string;
  secondPersonMemoryCareMin: string;
  secondPersonMemoryCareMax: string;
  communityFeeActiveAdultMin: string;
  communityFeeActiveAdultMax: string;
  communityFeeIndependentLivingMin: string;
  communityFeeIndependentLivingMax: string;
  communityFeeAssistedLivingMin: string;
  communityFeeAssistedLivingMax: string;
  communityFeeMemoryCareMin: string;
  communityFeeMemoryCareMax: string;
  additionalCareAssistedLivingMin: string;
  additionalCareAssistedLivingMax: string;
  additionalCareMemoryCareMin: string;
  additionalCareMemoryCareMax: string;
  careFeeStructureAssistedLiving: string;
  careFeeStructureMemoryCare: string;

  incentivesActiveAdultMin: string;
  incentivesActiveAdultMax: string;
  incentivesIndependentLivingMin: string;
  incentivesIndependentLivingMax: string;
  incentivesAssistedLivingMin: string;
  incentivesAssistedLivingMax: string;
  incentivesMemoryCareMin: string;
  incentivesMemoryCareMax: string;
};

export type RoomSizesType = {
  sizesWardAssistedLivingMin: string;
  sizesWardAssistedLivingMax: string;
  sizesWardMemoryCareMin: string;
  sizesWardMemoryCareMax: string;
  sizesWardSkilledNursingMin: string;
  sizesWardSkilledNursingMax: string;
  sizesSemiPrivateAssistedLivingMin: string;
  sizesSemiPrivateAssistedLivingMax: string;
  sizesSemiPrivateMemoryCareMin: string;
  sizesSemiPrivateMemoryCareMax: string;
  sizesSemiPrivateSkilledNursingMin: string;
  sizesSemiPrivateSkilledNursingMax: string;
  sizesStudioActiveAdultMin: string;
  sizesStudioActiveAdultMax: string;
  sizesStudioIndependentLivingMin: string;
  sizesStudioIndependentLivingMax: string;
  sizesStudioAssistedLivingMin: string;
  sizesStudioAssistedLivingMax: string;
  sizesStudioMemoryCareMin: string;
  sizesStudioMemoryCareMax: string;
  sizesStudioSkilledNursingMin: string;
  sizesStudioSkilledNursingMax: string;
  sizesOneBedroomActiveAdultMin: string;
  sizesOneBedroomActiveAdultMax: string;
  sizesOneBedroomIndependentLivingMin: string;
  sizesOneBedroomIndependentLivingMax: string;
  sizesOneBedroomAssistedLivingMin: string;
  sizesOneBedroomAssistedLivingMax: string;
  sizesOneBedroomMemoryCareMin: string;
  sizesOneBedroomMemoryCareMax: string;
  sizesTwoBedroomActiveAdultMin: string;
  sizesTwoBedroomActiveAdultMax: string;
  sizesTwoBedroomIndependentLivingMin: string;
  sizesTwoBedroomIndependentLivingMax: string;
  sizesTwoBedroomAssistedLivingMin: string;
  sizesTwoBedroomAssistedLivingMax: string;
  sizesTwoBedroomMemoryCareMin: string;
  sizesTwoBedroomMemoryCareMax: string;
  sizesThreeBedroomActiveAdultMin: string;
  sizesThreeBedroomActiveAdultMax: string;
  sizesThreeBedroomIndependentLivingMin: string;
  sizesThreeBedroomIndependentLivingMax: string;
  sizesThreeBedroomAssistedLivingMin: string;
  sizesThreeBedroomAssistedLivingMax: string;
  sizesThreeBedroomMemoryCareMin: string;
  sizesThreeBedroomMemoryCareMax: string;
  sizesCottageActiveAdultMin: string;
  sizesCottageActiveAdultMax: string;
  sizesCottageIndependentLivingMin: string;
  sizesCottageIndependentLivingMax: string;
  sizesCottageAssistedLivingMin: string;
  sizesCottageAssistedLivingMax: string;
  sizesCottageMemoryCareMin: string;
  sizesCottageMemoryCareMax: string;
};

export type FeePlansType = {
  firstPlanContractTermsType: string | null;
  firstPlanContractTermsRefund: string | null;
  firstPlanContractTermsAmortPeriod: string | null;
  firstPlanContractTermsAdminFee: string | null;
  firstPlanStudioEntranceFeeMin: string | null;
  firstPlanStudioEntranceFeeMax: string | null;
  firstPlanOneBedroomEntranceFeeMin: string | null;
  firstPlanOneBedroomEntranceFeeMax: string | null;
  firstPlanTwoBedroomEntranceFeeMin: string | null;
  firstPlanTwoBedroomEntranceFeeMax: string | null;
  firstPlanThreeBedroomEntranceFeeMin: string | null;
  firstPlanThreeBedroomEntranceFeeMax: string | null;
  firstPlanCottageEntranceFeeMin: string | null;
  firstPlanCottageEntranceFeeMax: string | null;
  firstPlanSecondPersonEntranceFeeMin: string | null;
  firstPlanSecondPersonEntranceFeeMax: string | null;
  firstPlanStudioMonthlyFeeMin: string | null;
  firstPlanStudioMonthlyFeeMax: string | null;
  firstPlanOneBedroomMonthlyFeeMin: string | null;
  firstPlanOneBedroomMonthlyFeeMax: string | null;
  firstPlanTwoBedroomMonthlyFeeMin: string | null;
  firstPlanTwoBedroomMonthlyFeeMax: string | null;
  firstPlanThreeBedroomMonthlyFeeMin: string | null;
  firstPlanThreeBedroomMonthlyFeeMax: string | null;
  firstPlanCottageMonthlyFeeMin: string | null;
  firstPlanCottageMonthlyFeeMax: string | null;
  firstPlanSecondPersonMonthlyFeeMin: string | null;
  firstPlanSecondPersonMonthlyFeeMax: string | null;
  firstPlanNotes: string | null;
  secondPlanContractTermsType: string | null;
  secondPlanContractTermsRefund: string | null;
  secondPlanContractTermsAmortPeriod: string | null;
  secondPlanContractTermsAdminFee: string | null;
  secondPlanStudioEntranceFeeMin: string | null;
  secondPlanStudioEntranceFeeMax: string | null;
  secondPlanOneBedroomEntranceFeeMin: string | null;
  secondPlanOneBedroomEntranceFeeMax: string | null;
  secondPlanTwoBedroomEntranceFeeMin: string | null;
  secondPlanTwoBedroomEntranceFeeMax: string | null;
  secondPlanThreeBedroomEntranceFeeMin: string | null;
  secondPlanThreeBedroomEntranceFeeMax: string | null;
  secondPlanCottageEntranceFeeMin: string | null;
  secondPlanCottageEntranceFeeMax: string | null;
  secondPlanSecondPersonEntranceFeeMin: string | null;
  secondPlanSecondPersonEntranceFeeMax: string | null;
  secondPlanStudioMonthlyFeeMin: string | null;
  secondPlanStudioMonthlyFeeMax: string | null;
  secondPlanOneBedroomMonthlyFeeMin: string | null;
  secondPlanOneBedroomMonthlyFeeMax: string | null;
  secondPlanTwoBedroomMonthlyFeeMin: string | null;
  secondPlanTwoBedroomMonthlyFeeMax: string | null;
  secondPlanThreeBedroomMonthlyFeeMin: string | null;
  secondPlanThreeBedroomMonthlyFeeMax: string | null;
  secondPlanCottageMonthlyFeeMin: string | null;
  secondPlanCottageMonthlyFeeMax: string | null;
  secondPlanSecondPersonMonthlyFeeMin: string | null;
  secondPlanSecondPersonMonthlyFeeMax: string | null;
  secondPlanNotes: string | null;
  thirdPlanContractTermsType: string | null;
  thirdPlanContractTermsRefund: string | null;
  thirdPlanContractTermsAmortPeriod: string | null;
  thirdPlanContractTermsAdminFee: string | null;
  thirdPlanStudioEntranceFeeMin: string | null;
  thirdPlanStudioEntranceFeeMax: string | null;
  thirdPlanOneBedroomEntranceFeeMin: string | null;
  thirdPlanOneBedroomEntranceFeeMax: string | null;
  thirdPlanTwoBedroomEntranceFeeMin: string | null;
  thirdPlanTwoBedroomEntranceFeeMax: string | null;
  thirdPlanThreeBedroomEntranceFeeMin: string | null;
  thirdPlanThreeBedroomEntranceFeeMax: string | null;
  thirdPlanCottageEntranceFeeMin: string | null;
  thirdPlanCottageEntranceFeeMax: string | null;
  thirdPlanSecondPersonEntranceFeeMin: string | null;
  thirdPlanSecondPersonEntranceFeeMax: string | null;
  thirdPlanStudioMonthlyFeeMin: string | null;
  thirdPlanStudioMonthlyFeeMax: string | null;
  thirdPlanOneBedroomMonthlyFeeMin: string | null;
  thirdPlanOneBedroomMonthlyFeeMax: string | null;
  thirdPlanTwoBedroomMonthlyFeeMin: string | null;
  thirdPlanTwoBedroomMonthlyFeeMax: string | null;
  thirdPlanThreeBedroomMonthlyFeeMin: string | null;
  thirdPlanThreeBedroomMonthlyFeeMax: string | null;
  thirdPlanCottageMonthlyFeeMin: string | null;
  thirdPlanCottageMonthlyFeeMax: string | null;
  thirdPlanSecondPersonMonthlyFeeMin: string | null;
  thirdPlanSecondPersonMonthlyFeeMax: string | null;
  thirdPlanNotes: string | null;
  valid?: boolean;
};

export type ParticipantDataType = {
  sellerProfitType: string | null;
  buyerLegalName: string | null;
  buyerTrueName: string | null;
  participantPhone: string | null;
  participantCompanyName: string | null;
  sellerLegalName: string | null;
  buyerProfitType: string | null;
  participantName: string | null;
  participantEmail: string | null;
  sellerTrueName: string | null;
  verificationSources: string[];
};

export type TransactionDataType = {
  inPlaceReservesPerUnitBed: number;
  assetsType: string;
  propertyRights: string;
  buyerReservesPerUnitBed: number;
  inPlaceExpenses: number;
  buyerManagementFees: number;
  inPlaceRevenues: number;
  inPlaceManagementFees: number;
  sellerExpenses: number;
  sellerReservesPerUnitBed: number;
  inPlaceOccupancy: number | null;
  buyerExpenses: number;
  buyerRevenues: number;
  buyerOccupancy: number | null;
  transactionComment: string | null;
  saleConditions: string;
  propertyCondition: string | null;
  financing: string;
  sellerManagementFees: number;
  sellerOccupancy: number | null;
  sellerRevenues: number;
};

export type ServiceDataType = {
  activeAdultUnits: number | null;
  assistedLivingUnits: number | null;
  memoryCareBeds: number | null;
  memoryCareUnits: number | null;
  independentLivingUnits: number | null;
  skilledNursingUnits: number | null;
  assistedLivingBeds: number | null;
  independentLivingBeds: number | null;
  skilledNursingBeds: number | null;
};

export interface IReviewedProperties {
  id: string;
  name: string;
}

export type CompInfoType = {
  companyName: string;
  firstName: string;
  lastName: string;
  id: string;
  name: string;
  ownerRelatedStatus: string;
  closingDate: string;
  price: number;
  properties: IReviewedProperties[];
  defaultProperty: string;
  participantData: ParticipantDataType;
  transactionData: TransactionDataType;
  serviceData: ServiceDataType;
  saleType: string;
  permissions: string[];
  createdByAdmin: boolean;
  adminNotes?: string | null;
  activeAdultOffered: boolean;
  assistedLiving: boolean;
  independentLivingOffered: boolean;
  memoryCareOffered: boolean;
  skilledNursing: boolean;
  internalNotes: string | null;
};

export type RentCompInfoType = {
  comment: string;
  internalNotes: string | null;
  companyName: string;
  contactCompanyName: string | null;
  contactPersonName: string | null;
  createdByAdmin: boolean;
  email: string | null;
  firstName: string;
  id: string;
  lastName: string;
  name: string;
  ownerRelatedStatus: string;
  permissions: string[];
  phone: string | null;
  propertyId: string;
  roomRates: RoomRatesType;
  roomSizes: RoomSizesType;
  feePlans: FeePlansType;
  servicesAndOccupancy: ServiceAndOccupancyType;
  verificationDate: string;
  verificationSource: string;
  rentStatus: string | null;
  adminNotes?: string | null;
  activeAdultOffered?: boolean;
  assistedLiving?: boolean;
  independentLivingOffered?: boolean;
  memoryCareOffered?: boolean;
  skilledNursing?: boolean;
};

export interface IMixData {
  residentDaysMedicaidPayorMix: number | null;
  capacityILUnitMix: number | null;
  residentDaysSNOccupancyMix: number | null;
  capacityALUnitMix: number | null;
  residentDaysMCOccupancyMix: number | null;
  residentDaysAAOccupancyMix: number | null;
  residentDaysPrivatePayorMix: number | null;
  residentDaysMedicarePayorMix: number | null;
  residentDaysInsurancePayorMix: number | null;
  capacityMCUnitMix: number | null;
  capacitySNUnitMix: number | null;
  residentDaysOtherPayorMix: number | null;
  residentDaysILOccupancyMix: number | null;
  capacityAAUnitMix: number | null;
  residentDaysALOccupancyMix: number | null;
}

export interface IRevenuesData {
  totalAARevenues: number | null;
  totalCommunityFeesRevenues: number | null;
  totalSecondOccupancyRevenues: number | null;
  totalMCRevenues: number | null;
  totalCareFeesRevenues: number | null;
  totalCollectionLossRevenues: number | null;
  totalSNRevenues: number | null;
  totalILRevenues: number | null;
  totalALRevenues: number | null;
  totalOtherRevenues: number | null;
}

export interface IExpenseData {
  payrollMarketingExpenses: number | null;
  totalPropertyTaxesExpenses: number | null;
  totalPayrollTaxesExpenses: number | null;
  nonPayrollDietaryExpenses: number | null;
  payrollOthersExpenses: number | null;
  totalUtilitiesExpenses: number | null;
  totalActivitiesExpenses: number | null;
  payrollAdministrativeExpenses: number | null;
  totalMarketingExpenses: number | null;
  totalEmployeeBenefitsExpenses: number | null;
  totalLiabilityInsuranceExpenses: number | null;
  totalOtherInsuranceExpenses: number | null;
  totalDietaryExpenses: number | null;
  payrollActivitiesExpenses: number | null;
  totalWorkersCompensationExpenses: number | null;
  nonPayrollResidentCareExpenses: number | null;
  totalHousekeepingExpenses: number | null;
  totalManagementFeesExpenses: number | null;
  managementFeesExpenses: string | null;
  payrollResidentCareExpenses: number | null;
  totalPropertyInsuranceExpenses: number | null;
  nonPayrollAdministrativeExpenses: number | null;
  nonPayrollMaintenanceExpenses: number | null;
  payrollHousekeepingExpenses: number | null;
  payrollDietaryExpenses: number | null;
  reservesExpenses: string | null;
  totalOthersExpenses: number | null;
  totalBedProviderTaxesExpenses: number | null;
  nonPayrollHousekeepingExpenses: number | null;
  nonPayrollActivitiesExpenses: number | null;
  totalReservesExpenses: number | null;
  totalResidentCareExpenses: number | null;
  nonPayrollMarketingExpenses: number | null;
  totalAdministrativeExpenses: number | null;
  totalMaintenanceExpenses: number | null;
  payrollMaintenanceExpenses: number | null;
  nonPayrollOthersExpenses: number | null;
}

export type ExpenseCompInfoType = {
  companyName: string | null;
  firstName: string | null;
  lastName: string | null;
  id: string;
  name: string;
  financialType: number;
  financialYear: number;
  performance: string;
  oer: number;
  mixData: IMixData;
  revenuesData: IRevenuesData;
  expensesData: IExpenseData;
  comment: string | null;
  internalNotes: string | null;
  ownerRelatedStatus: string;
  propertyId: string;
  permissions: string[];
  createdByAdmin: boolean;
  adminNotes?: string | null;
  activeAdultOffered?: boolean;
  assistedLiving?: boolean;
  independentLivingOffered?: boolean;
  memoryCareOffered?: boolean;
  skilledNursing?: boolean;
};

export interface IRange {
  min: number | null;
  max: number | null;
}

export interface IRentRoom {
  values: IRange;
}

export interface ICompetitorsInfo extends IRange {
  propertyName: string;
}

export interface ICompetitorsRentRoom extends IRentRoom {
  properties: ICompetitorsInfo[];
}

export type RentAnalysisFields = {
  [key: string]: IRentRoom | ICompetitorsRentRoom | null;
  semiPrivate: IRentRoom | ICompetitorsRentRoom | null;
  studio: IRentRoom | ICompetitorsRentRoom | null;
  oneBedroom: IRentRoom | ICompetitorsRentRoom | null;
  twoBedroom: IRentRoom | ICompetitorsRentRoom | null;
  threeBedroom: IRentRoom | ICompetitorsRentRoom | null;
  secondPerson: IRentRoom | ICompetitorsRentRoom | null;
  communityFee: IRentRoom | ICompetitorsRentRoom | null;
  additionalCare: IRentRoom | ICompetitorsRentRoom | null;
  ward: IRentRoom | ICompetitorsRentRoom | null;
  cottage: IRentRoom | ICompetitorsRentRoom | null;
};

export type PricingAnalysis = {
  currentPropertyComp: RentAnalysisFields | Record<string, never>;
  propertiesComps: RentAnalysisFields | Record<string, never>;
  status: string;
  serviceType: string;
  reason: string | null;
  maxValue: number;
};

export type PricingAnalysisAllData = {
  status: string;
  charts: PricingAnalysis[];
  reason: string;
};

export type PricingAnalysisState = {
  [key in ICompServices]: boolean;
};

export type CompLoadingPayload = {
  [key in
    | "sale"
    | "rent"
    | "expense"
    | "compsExport"
    | "compPrice"
    | "pricingAnalysis"
    | "pricingAnalysisConfig"
    | "expenseAnalysisConfig"
    | "expenseAnalysisCompCount"
    | "expenseAnalysisData"
    | "propertiesOptions"
    | "filtredMyDealsCompsExport"
    | "rentCompAttachments"
    | "saleCompAttachments"
    | "expenseCompAttachments"]?: boolean;
};

export interface ISearchedPropertyOptions extends IPropertyDetails {
  isDefault?: boolean;
}

export interface ISaleCompInfo extends ICreateSaleComp {
  id: string;
}

export interface ICompPrice {
  SALE: number;
  RENT: number;
  EXPENSE: number;
}

export type CompTypesAttachments = {
  [key in ICompTypes]?: IAttachments[];
};

export type CompTypesRequestedProperties = {
  [key in ICompTypes]?: ISearchedPropertyOptions[];
};

export interface ICompsGroup<T> {
  parentComp: T | null;
  memberComps: T[];
}

export interface ICompState {
  compType: string;
  compSubType: string;
  compProperties: ISearchedPropertyOptions[];
  requestedCompProperties: {
    sale: ISearchedPropertyOptions[];
    rent: ISearchedPropertyOptions[];
    expense: ISearchedPropertyOptions[];
  };
  propertiesOptions: ISearchedPropertyOptions[];
  isCompOpen: boolean;
  createCompFormStatus: ICompFormState;
  editFormStatus: ICompFormUpdateState;
  copyFormStatus: ICompFormUpdateState;
  unsavedForm:
    | RentUsavedValuesType
    | FieldsUnsavedValuesType
    | ExpenseUnsavedValuesType
    | null;
  compAttachments: {
    sale: IAttachments[];
    rent: IAttachments[];
    expense: IAttachments[];
  };
  compInfo: {
    sale: CompInfoType;
    rent: RentCompInfoType;
    expense: ExpenseCompInfoType;
  };
  successModalMessage: string;
  isSuccessOpen: boolean;
  isLoading: {
    sale: boolean;
    rent: boolean;
    expense: boolean;
    compsExport: boolean;
    compPrice: boolean;
    pricingAnalysis: boolean;
    pricingAnalysisConfig: boolean;
    expenseAnalysisConfig: boolean;
    expenseAnalysisCompCount: boolean;
    expenseAnalysisData: boolean;
    propertiesOptions: boolean;
    filtredMyDealsCompsExport: boolean;
    rentCompAttachments: boolean;
    saleCompAttachments: boolean;
    expenseCompAttachments: boolean;
  };
  tableCompsData: {
    sale: ISaleCompTableData | null;
    rent: IRentCompTableData | null;
    expense: IExpenseCompTableData | null;
  };
  reviewCompsData: ReviewedCompTableData;
  compPrice: ICompPrice;
  sortCompBy: ISortProperty;
  cancelToken: CompCancelToken;
  pricingAnalysis: PricingAnalysisAllData | null;
  pricingAnalysisConfig: PricingAnalysisConfigData[];
  expenseAnalysisCompCount: number | null;
  expenseAnalysisConfig: ExpenseAnalysisConfig | null;
  expenseAnalysisData: ExpenseAnalysisData | null;
  isAdjustmentMode: boolean;
}

export type CompTableData = {
  [key in ICompTypes]?:
    | ISaleCompTableData
    | IRentCompTableData
    | IExpenseCompTableData
    | null;
};

export type TSetReviewedCompDataKeys = ICompTypes | "comps";

export type TSetReviewedCompData = {
  [key in TSetReviewedCompDataKeys]?:
    | ICompsGroup<CompInfoType>[]
    | ICompsGroup<RentCompInfoType>[]
    | ICompsGroup<ExpenseCompInfoType>[]
    | null;
} & {
  membership: string;
};

export type ReviewedCompTableData = {
  sale: ICompsGroup<CompInfoType>[] | null;
  rent: ICompsGroup<RentCompInfoType>[] | null;
  expense: ICompsGroup<ExpenseCompInfoType>[] | null;
  membership: string;
};

interface SetCompType {
  type: constant.SET_COMP_TYPE;
  payload: string;
}

interface SetCompSubType {
  type: constant.SET_COMP_SUB_TYPE;
  payload: string;
}

interface SetCompProperties {
  type: constant.SET_COMP_PROPERTIES;
  payload: ISearchedPropertyOptions[];
}

interface SetOpenComp {
  type: constant.SET_OPEN_COMP_CREATE;
  payload: boolean;
}

interface SetCreateCompFormStatus {
  type: constant.SET_CREATE_COMP_FORM_STATUS;
  payload: ICompFormState;
}

interface SetEditCompFormStatus {
  type: constant.SET_EDIT_COMP_FORM_STATUS;
  payload: ICompFormUpdateState;
}

interface SetCopyCompFormStatus {
  type: constant.SET_COPY_COMP_FORM_STATUS;
  payload: ICompFormUpdateState;
}

interface SetCompFormValues {
  type: constant.SET_COMP_FORM_VALUES;
  payload:
    | RentUsavedValuesType
    | FieldsUnsavedValuesType
    | ExpenseUnsavedValuesType
    | null;
}

interface SetSaleCompOptions {
  type: constant.SET_SALE_COMP_OPTIONS;
  payload: ISearchedPropertyOptions[];
}

interface SetCompInfo {
  type: constant.SET_COMP_INFO;
  payload: {
    [key: string]: CompInfoType | RentCompInfoType | ExpenseCompInfoType;
  };
}

interface SetRequestCompProperties {
  type: constant.SET_REQUESTED_COMP_PROPERTIES;
  payload: CompTypesRequestedProperties;
}

interface SetCompAttachments {
  type: constant.SET_COMP_ATTACHMENTS;
  payload: CompTypesAttachments;
}

interface SetSuccessModal {
  type: constant.SET_SUCCESS_COMP_OPEN;
  payload: boolean;
}

interface SetCompLoading {
  type: constant.SET_COMP_LOADING;
  payload: CompLoadingPayload;
}

interface SetTableCompsData {
  type: constant.SET_TABLE_COMPS_DATA;
  payload: ISaleCompTableData;
}

interface SetReviewedCompData {
  type: constant.SET_REVIEWED_COMP_DATA;
  payload:
    | ISaleCompTableInfo[]
    | IRentCompTableInfo[]
    | IExpenseCompTableInfo[];
}

interface SetSuccessModalMessage {
  type: constant.SET_SUCCESS_MODAL_MESSAGE;
  payload: string;
}

interface SetCompPrice {
  type: constant.SET_COMP_PRICE;
  payload: { [key: string]: number };
}

interface SetSortComp {
  type: constant.SET_SORT_COMP;
  payload: ISortProperty;
}

export enum CompFormStatusEnum {
  NONE = "NONE",
  UNSAVED = "UNSAVED",
  IN_PROGRESS = "IN_PROGRESS",
}

export type CompFormStatusType =
  | CompFormStatusEnum.NONE
  | CompFormStatusEnum.UNSAVED
  | CompFormStatusEnum.IN_PROGRESS;

export interface ICompFormState {
  type: ICompTypes;
  status: CompFormStatusType;
}

export interface ICompFormUpdateState extends ICompFormState {
  id: string | null;
}

interface SetCancelToken {
  type: constant.SET_COMP_CANCEL_TOKEN;
  payload: CompCancelToken;
}

interface SetCompPricingAnalysis {
  type: constant.SET_COMP_PRICING_ANALYSIS;
  payload: PricingAnalysisAllData | null;
}

interface SetCompPricingAnalysisConfig {
  type: constant.SET_COMP_PRICING_ANALYSIS_CONFIG;
  payload: PricingAnalysisConfigData[];
}

interface SetExpenseAnalysisCompCount {
  type: constant.SET_EXPENSE_ANALYSIS_COMP_COUNT;
  payload: number | null;
}

interface SetExpenseAnalysisConfig {
  type: constant.SET_EXPENSE_ANALYSIS_CONFIG;
  payload: ExpenseAnalysisConfig | null;
}

interface SetExpenseAnalysisData {
  type: constant.SET_EXPENSE_ANALYSIS_DATA;
  payload: ExpenseAnalysisData | null;
}

interface SetAdjustmentMode {
  type: constant.SET_ADJUSTMENT_MODE;
  payload: boolean;
}

export type Actions =
  | SetCompType
  | SetCompSubType
  | SetCompProperties
  | SetOpenComp
  | SetCreateCompFormStatus
  | SetEditCompFormStatus
  | SetCopyCompFormStatus
  | SetCompFormValues
  | SetCompInfo
  | SetRequestCompProperties
  | SetCompAttachments
  | SetSaleCompOptions
  | SetSuccessModal
  | SetCompLoading
  | SetReviewedCompData
  | SetSuccessModalMessage
  | SetTableCompsData
  | SetCompPrice
  | SetSortComp
  | SetCancelToken
  | SetCompPricingAnalysis
  | SetCompPricingAnalysisConfig
  | SetExpenseAnalysisCompCount
  | SetExpenseAnalysisConfig
  | SetExpenseAnalysisData
  | SetAdjustmentMode;
