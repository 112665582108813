import React from "react";
import {
  FormControlLabel,
  Checkbox,
  Typography,
  Theme,
  SxProps,
} from "@mui/material";
import { ReactComponent as SmallCheckbox } from "assets/svg/smallCheckbox.svg";
import { ReactComponent as SmallActiveCheckbox } from "assets/svg/smallActiveCheckbox.svg";
import { ReactComponent as SmallInactiveCheckbox } from "assets/svg/smallInactiveCheckbox.svg";
import styles from "./FilterCheckBox.module.scss";
import clsx from "clsx";
import { submitKey } from "config/constants";

type Props = {
  title: string;
  value: string | boolean;
  handleChange?: (value: string) => void;
  checked: boolean;
  fontWeight?: number;
  fontSize?: string;
  labelStyle?: SxProps<Theme>;
  checkBoxStyle?: {
    [key: string]: string | number;
  };
  error?: boolean;
  disabled?: boolean;
  iconAttributes?: {
    [key: string]: string | number;
  };
};

export const FilterCheckBox = ({
  title,
  value,
  checked,
  handleChange,
  fontWeight,
  fontSize,
  labelStyle,
  checkBoxStyle,
  error,
  disabled,
  iconAttributes,
}: Props) => {
  return (
    <FormControlLabel
      onClick={(event) => {
        event.stopPropagation();
      }}
      label={
        <Typography
          sx={{
            fontSize: fontSize || "12px",
            fontWeight: fontWeight || 600,
          }}
        >
          {title}
        </Typography>
      }
      control={
        <Checkbox
          icon={
            <SmallCheckbox
              className={clsx({
                [styles.errorBorder]: error,
              })}
              fill={disabled ? "#F0F0F0" : "none"}
              {...iconAttributes}
            />
          }
          checkedIcon={
            disabled ? (
              <SmallInactiveCheckbox {...iconAttributes} />
            ) : (
              <SmallActiveCheckbox {...iconAttributes} />
            )
          }
          value={value}
          disabled={disabled}
          onChange={(event) => {
            if (handleChange) {
              handleChange(event.target.value);
            }
          }}
          onKeyDown={(event) => {
            if (event.key === submitKey) {
              event.preventDefault();
              event.stopPropagation();
              handleChange &&
                handleChange((event.target as HTMLButtonElement).value);
            }
          }}
          checked={checked}
          sx={{
            "&.MuiCheckbox-root": {
              p: 0,
              m: "0 6px 0 8px",
              ...checkBoxStyle,
            },
          }}
        />
      }
      sx={{
        mr: 0,
        ...labelStyle,
      }}
    />
  );
};
