import { IPropertyInfo } from "pages/Properties/interfaces";
import { apiClient } from "lib/apiClient";

export const getPropertyDetails = async (
  id: string
): Promise<IPropertyInfo> => {
  const response = await apiClient.get(`/properties/${id}`);

  return response.data;
};
