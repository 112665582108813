import { ICompanyGroup } from "./getCompanyGroups";
import { apiClient } from "lib/apiClient";

export const getCompanyGroupById = async (
  id: string
): Promise<ICompanyGroup> => {
  const response = await apiClient.get(`/companies/${id}`);

  return response.data;
};
