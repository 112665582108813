import { templatesTranslations } from "../translations";

export const getSeniorCompTableHeaders = (isAdminUser: boolean) =>
  isAdminUser
    ? [
        templatesTranslations.templateName,
        templatesTranslations.date,
        templatesTranslations.subscriptionType,
        "",
      ]
    : [templatesTranslations.templateName];

export const SORT_COLUMN = templatesTranslations.templateName;
