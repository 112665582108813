import {
  SIGN_IN_SUCCESS,
  OPEN_NEW_PASSWORD,
  SET_USER_CRED,
  SIGN_IN_ERROR,
  OPEN_ERROR_MODAL,
  OPEN_POLICY_MODAL,
  SET_SUCCESS_SIGN_IN_OPEN,
  SET_SIGN_IN_LOADING,
  USER_LOG_OUT,
  SET_EMAIL_VERIFICATION,
} from "./SignInActions";
import { Actions } from "./interfaces";
import { ISignInState } from "./Model";

export const initialState: ISignInState = {
  userInfo: {
    agreedToLegalDocs: false,
    id: "",
    companyId: "",
    authServiceId: "",
    firstName: "",
    lastName: "",
    email: "",
    phonePrefix: "",
    phone: "",
    creationDate: "",
    lastModifiedDate: "",
    roles: [],
  },
  isErrorModalOpen: false,
  isPasswordOpen: false,
  isPolicyModalOpen: false,
  isSuccessOpen: false,
  isLoading: {
    signIn: false,
    editPassword: false,
    editEmail: false,
    emailVerification: false,
  },
  oldUserCred: {
    user: "",
    password: "",
  },
  singInError: {
    type: "",
    message: "",
  },
  emailVerification: "",
};

export const SignInReducer = (
  state = initialState,
  action: Actions
): ISignInState => {
  switch (action.type) {
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
      };
    case OPEN_ERROR_MODAL:
      return {
        ...state,
        isErrorModalOpen: action.payload,
      };
    case SIGN_IN_ERROR:
      return {
        ...state,
        singInError: action.payload,
      };
    case OPEN_NEW_PASSWORD:
      return {
        ...state,
        isPasswordOpen: action.payload,
      };
    case OPEN_POLICY_MODAL:
      return {
        ...state,
        isPolicyModalOpen: action.payload,
      };
    case SET_USER_CRED:
      return {
        ...state,
        oldUserCred: action.payload,
      };
    case SET_SUCCESS_SIGN_IN_OPEN:
      return {
        ...state,
        isSuccessOpen: action.payload,
      };
    case SET_SIGN_IN_LOADING:
      return {
        ...state,
        isLoading: { ...state.isLoading, ...action.payload },
      };
    case SET_EMAIL_VERIFICATION:
      return {
        ...state,
        emailVerification: action.payload,
      };
    case USER_LOG_OUT:
      return state;
    default:
      return state;
  }
};
