import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { TSubscriptionPlan, TTemplate } from "features/Templates/types";
import { ReactComponent as SmallCheckbox } from "assets/svg/smallCheckbox.svg";
import { ReactComponent as SmallActiveCheckbox } from "assets/svg/smallActiveCheckbox.svg";
import { resolveDate } from "utils/resolveDate";
import { templatesTranslations } from "../translations";
import { parseUrlToBlob } from "api/parseUrlToBlob";
import FileSaver from "file-saver";
import { StyledNameCell, StyledTypography } from "./Components.styled";
import { toastMessage } from "components/ToastMessage";
import { ShareTemplateModal } from "./ShareTemplateModal";

interface ITemplateTableRowProps {
  data: TTemplate;
  isAdminUser: boolean;
  isAbleToRemove: boolean;
  checkedTemplates?: string[];
  handleCheckTemplate?: (templateId: string) => void;
  handleUpdatePlans?: (id: string, subscriptions: TSubscriptionPlan[]) => void;
}

export const TemplateTableRow = ({
  data,
  isAdminUser,
  isAbleToRemove,
  handleCheckTemplate,
  checkedTemplates,
  handleUpdatePlans,
}: ITemplateTableRowProps) => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const toggleShareModal = () => {
    setIsShareModalOpen((prevState) => !prevState);
  };

  const handleDownloadFile = async () => {
    try {
      const blob = await parseUrlToBlob(data.linkToFile);
      const file = new File([blob], data.title);

      FileSaver.saveAs(file, data.title);
    } catch (err) {
      toastMessage({
        type: "error",
        position: "top-right",
        message: templatesTranslations.somethingWentWrong,
      });
    }
  };

  const isItemChecked = checkedTemplates?.find(
    (templateId) => templateId === data.id
  );

  const handleUpdateSubscriptions = (subscriptions: TSubscriptionPlan[]) => {
    handleUpdatePlans?.(data.id, subscriptions);
  };

  return (
    <>
      <TableRow>
        <StyledNameCell scope="row">
          <Box display="flex" alignItems="center" height={33}>
            {isAbleToRemove && (
              <Checkbox
                icon={<SmallCheckbox />}
                checkedIcon={<SmallActiveCheckbox />}
                onChange={() => {
                  handleCheckTemplate?.(data.id);
                }}
                checked={Boolean(isItemChecked)}
              />
            )}
            <StyledTypography
              onClick={handleDownloadFile}
              variant="h5"
              fontWeight={400}
              noWrap
            >
              {data.title}
            </StyledTypography>
          </Box>
        </StyledNameCell>
        {isAdminUser && (
          <>
            <TableCell>
              <Typography variant="h5">
                {resolveDate(data.creationDate, "MM/dd/yyyy")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h5" fontWeight={400}>
                {data.subscriptions
                  ?.map(
                    (subscription) =>
                      subscription.charAt(0).toUpperCase() +
                      subscription.slice(1).toLowerCase()
                  )
                  .join(", ")}
              </Typography>
            </TableCell>
            <TableCell>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  sx={{ height: "27px" }}
                  size="small"
                  classes={"outlined"}
                  onClick={toggleShareModal}
                >
                  {templatesTranslations.share}
                </Button>
              </Box>
            </TableCell>
          </>
        )}
      </TableRow>
      {isShareModalOpen && (
        <ShareTemplateModal
          templateName={data.title}
          handleClose={toggleShareModal}
          handleUpdateSubscriptions={handleUpdateSubscriptions}
          subscriptions={data.subscriptions}
        />
      )}
    </>
  );
};
