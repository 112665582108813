import * as yup from "yup";
import { splitPhone } from "utils/splitPhone";
import { IPropertyData, IPropertyInfo } from "pages/Properties/interfaces";
import { jpeg, png } from "components/UploadMultipleFiles/constants";
import { returnNumberOrValue } from "utils/returnNumberOrValue";

const yearOpenedRegExp = /\b\d{4}\b/;
export const fileExtensions = [jpeg, png];
export const bytesLimit = 10485760;
export const COUNTRY_PR = "PR";
export const excludedTextFields = [
  "propertyName",
  "operator",
  "website",
  "sightMapLink",
  "street",
  "expansionOfRenovation",
  "federalProviderNumber",
  "note",
];

export const validationSchema = yup.object().shape({
  propertyName: yup
    .string()
    .required("Please, fill in all required fields")
    .trim(),
  phone: yup
    .string()
    .optional()
    .min(10, "Entered data is not valid, please try again"),
  profitType: yup.string().optional(),
  operator: yup.string().optional(),
  website: yup.string().optional(),
  sightMapLink: yup.string().optional(),
  progressStatus: yup.string().optional(),
  lat: yup
    .number()
    .required("Please fill in all required fields in Property Address"),
  lng: yup
    .number()
    .required("Please fill in all required fields in Property Address"),
  country: yup.string().optional(),
  state: yup
    .string()
    .nullable()
    .required("Please fill in all required fields in Property Address"),
  region: yup
    .string()
    .required("Please fill in all required fields in Property Address"),
  city: yup
    .string()
    .nullable()
    .required("Please fill in all required fields in Property Address"),
  county: yup
    .string()
    .nullable()
    .required("Please fill in all required fields in Property Address"),
  street: yup
    .string()
    .required("Please fill in all required fields in Property Address"),
  zipCode: yup
    .string()
    .required("Please fill in all required fields in Property Address")
    .min(5, "Entered data is not valid, please try again")
    .max(5),
  marketType: yup.string().required("Please, fill in all required fields"),
  stories: yup.number().optional().max(999),
  buildingSize: yup.string().optional().max(9999999),
  yearOpened: yup
    .string()
    .matches(yearOpenedRegExp, {
      message: "Entered data is not valid, please try again",
    })
    .optional(),
  expansionOfRenovation: yup.string().optional().max(50),
  paymentType: yup.string().optional(),
  offeredServices: yup
    .array()
    .of(yup.string())
    .min(1, "Select at least one Service Offered"),
  alLicensedBeds: yup.number().optional().max(9999),
  mcLicensedBeds: yup.number().optional().max(9999),
  snLicensedBeds: yup.number().optional().max(9999),
  federalProviderNumber: yup.string().optional().max(50),
  note: yup.string().optional().max(5000),
});

export interface IOptions {
  value: string;
  title: string;
}

export const profitTypeMenuItems: IOptions[] = [
  {
    value: "FOR_PROFIT",
    title: "For profit",
  },
  {
    value: "NOT_FOR_PROFIT",
    title: "Not for profit",
  },
];

export const PROGRESS_STATUSES: IOptions[] = [
  {
    value: "OPEN",
    title: "Open",
  },
  {
    value: "IN_DEVELOPMENT",
    title: "In Development",
  },
  {
    value: "CLOSED",
    title: "Closed",
  },
];

export const propertyServices = [
  {
    id: 1,
    title: "Active Adult",
    value: "activeAdult",
    serviceValue: "activeAdultOffered",
  },
  {
    id: 2,
    title: "Independent Living",
    value: "independentLiving",
    serviceValue: "independentLivingOffered",
  },
  {
    id: 3,
    title: "Assisted Living",
    value: "assistedLiving",
    serviceValue: "assistedLiving",
  },
  {
    id: 4,
    title: "Memory Care",
    value: "memoryCare",
    serviceValue: "memoryCareOffered",
  },
  {
    id: 5,
    title: "Skilled Nursing",
    value: "skilledNursing",
    serviceValue: "skilledNursing",
  },
];

export const paymentType: IOptions[] = [
  {
    value: "RENTAL",
    title: "Rental",
  },
  {
    value: "ENTRANCE_FEE",
    title: "Entrance Fee",
  },
];

export const marketType: IOptions[] = [
  {
    value: "PRIMARY_TOP_30_METRO",
    title: "Primary: Top 30 Metro",
  },
  {
    value: "SECONDARY_31_100_METRO",
    title: "Secondary: 31-100 Metro",
  },
  {
    value: "TERTIARY",
    title: "Tertiary",
  },
];

export const defineOfferedService = (
  selectedValues: string[],
  searchedValue: string
) => {
  return selectedValues.includes(searchedValue);
};

export const defineObjectToSend = (values: IPropertyData) => {
  const parsedPhone = splitPhone(values.phone);
  return {
    name: values.propertyName,
    phonePrefix: parsedPhone.phonePrefix ? parsedPhone.phonePrefix : null,
    phone: values.phone ? values.phone.trim() : null,
    website: values.website ? values.website : null,
    progressStatus: values.progressStatus || null,
    sightMapLink: values.sightMapLink || null,
    operator: values.operator ? values.operator : null,
    profitType: values.profitType ? values.profitType : null,
    latitude: +values.lat,
    longitude: +values.lng,
    country: values.country,
    state: values.state,
    county: values.county,
    region: values.region,
    city: values.city,
    street: values.street,
    zipCode: values.zipCode,
    marketType: values.marketType,
    storeys: values.stories ? +values.stories : null,
    buildingSizeSfTotal: values.buildingSize ? +values.buildingSize : null,
    yearOpened: values.yearOpened ? +values.yearOpened : null,
    expansionOrRenovationHistory: values.expansionOfRenovation
      ? values.expansionOfRenovation
      : null,
    primaryPaymentType: values.paymentType ? values.paymentType : null,
    activeAdultOffered: defineOfferedService(
      values.offeredServices,
      "activeAdult"
    ),
    assistedLiving: defineOfferedService(
      values.offeredServices,
      "assistedLiving"
    ),
    independentLivingOffered: defineOfferedService(
      values.offeredServices,
      "independentLiving"
    ),
    memoryCareOffered: defineOfferedService(
      values.offeredServices,
      "memoryCare"
    ),
    skilledNursing: defineOfferedService(
      values.offeredServices,
      "skilledNursing"
    ),
    licensedBedsAl: values.alLicensedBeds ? +values.alLicensedBeds : null,
    licensedBedsMc: values.mcLicensedBeds ? +values.mcLicensedBeds : null,
    licensedBedsSn: values.snLicensedBeds ? +values.snLicensedBeds : null,
    federalProviderNumber: values.federalProviderNumber || null,
    notes: values.note ? values.note : null,
  };
};

export const defineSelectedServices = (propertyData: any) => {
  return propertyServices
    .filter((item) => propertyData[item.serviceValue])
    .map((item) => item.value);
};

export const defaultPhoto = {
  url: "",
  creationDate: "",
  extension: "",
  filename: "",
  id: "",
  lastModifiedDate: "",
};

export const defineProfitType = (value: string | null) => {
  const title = profitTypeMenuItems.find((item) => item.value === value)?.title;
  return title || value;
};

export const defineMarketType = (value: string) => {
  const title = marketType.find((item) => item.value === value)?.title;
  return title || value;
};

export const definePaymentType = (value: string | null) => {
  const title = paymentType.find((item) => item.value === value)?.title;
  return title || value;
};

export const defineDefaultSelectedServices = () => {
  return propertyServices.map((item) => item.value);
};

export const defineDefaultFieldsValues = (
  propertyDetails: IPropertyInfo,
  phone: string
): React.SetStateAction<IPropertyData> => {
  return {
    propertyName: propertyDetails.name,
    phone: phone,
    profitType: propertyDetails.profitType ? propertyDetails.profitType : "",
    operator: propertyDetails.operator ? propertyDetails.operator : "",
    sightMapLink: propertyDetails.sightMapLink ?? "",
    website: propertyDetails.website ? propertyDetails.website : "",
    progressStatus: propertyDetails.progressStatus || "",
    lat: `${propertyDetails.latitude}`,
    lng: `${propertyDetails.longitude}`,
    country: propertyDetails.country,
    state: propertyDetails.state,
    region: propertyDetails.region,
    city: propertyDetails.city,
    county: propertyDetails.county,
    street: propertyDetails.street,
    zipCode: propertyDetails.zipCode,
    marketType: propertyDetails.marketType,
    stories: returnNumberOrValue(propertyDetails.storeys, ""),
    buildingSize: returnNumberOrValue(propertyDetails.buildingSizeSfTotal, ""),
    yearOpened: propertyDetails.yearOpened
      ? `${propertyDetails.yearOpened}`
      : "",
    expansionOfRenovation: propertyDetails.expansionOrRenovationHistory
      ? `${propertyDetails.expansionOrRenovationHistory}`
      : "",
    paymentType: propertyDetails.primaryPaymentType
      ? `${propertyDetails.primaryPaymentType}`
      : "",
    offeredServices: defineSelectedServices(propertyDetails),
    alLicensedBeds: returnNumberOrValue(propertyDetails.licensedBedsAl, ""),
    mcLicensedBeds: returnNumberOrValue(propertyDetails.licensedBedsMc, ""),
    snLicensedBeds: returnNumberOrValue(propertyDetails.licensedBedsSn, ""),
    federalProviderNumber: propertyDetails.federalProviderNumber
      ? `${propertyDetails.federalProviderNumber}`
      : "",
    note: propertyDetails.notes ? `${propertyDetails.notes}` : "",
  };
};

export const CONFIRM_POPUP_TITLES = (isEdit?: boolean) => ({
  confirmTitle: isEdit
    ? "Cancel property editing"
    : "Cancel adding new property",
  confirmSubtitle: isEdit
    ? "You have unsaved changes for a property. Are you sure you want to cancel?"
    : "You have unsaved changes for a new property. Are you sure you want to cancel?",
  cancelButtonTitle: "NO, GO BACK",
  confirmButtonTitle: "YES, CANCEL",
});
