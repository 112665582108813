import { useCallback, useRef } from "react";

export const useAbortPreviousRequest = () => {
  const previousController = useRef<null | AbortController>(null);

  const abortRequest = (previousSignal: AbortController | null) => {
    if (previousSignal) {
      previousSignal.abort();
    }

    const controller = new AbortController();
    previousController.current = controller;

    return controller;
  };

  const abortPreviousRequest = useCallback(() => {
    return abortRequest(previousController.current);
  }, []);

  return { abortPreviousRequest, signal: previousController.current };
};
