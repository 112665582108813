import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { useSelector } from "react-redux";
import { theme } from "./materialUiTheme";
import * as selector from "store/selectors/companyGroups";

interface IThemeProps {
  children: JSX.Element | JSX.Element[];
}

export const Theme = ({ children }: IThemeProps) => {
  const isAdminPage = useSelector(selector.isAdminPageType);
  return (
    <ThemeProvider theme={createTheme(theme({ isAdminPage }))}>
      {children}
    </ThemeProvider>
  );
};
