import React from "react";
import { FormHelperText, SxProps, Theme } from "@mui/material";

export type Props = {
  isVisible: boolean;
  value: string | undefined;
  fontSize?: string;
  textStyles?: SxProps<Theme>;
};

export const ErrorHelperText = ({
  isVisible,
  value,
  fontSize,
  textStyles,
}: Props) => {
  return (
    <>
      {isVisible && value && (
        <FormHelperText
          sx={{
            margin: "5px 0px",
            color: "error.main",
            fontSize: fontSize || "14px",
            fontWeight: "300",
            lineHeight: "normal",
            whiteSpace: "pre-wrap",
            ...textStyles,

            "@media screen and (max-width: 600px)": {
              fontSize: "12px",
            },
          }}
        >
          {value}
        </FormHelperText>
      )}
    </>
  );
};
