import { apiClient } from "lib/apiClient";

export const userActivation = async <T>(
  id: string,
  activate: boolean
): Promise<T> => {
  const methodToUse = activate ? "post" : "delete";
  const response = await apiClient[methodToUse](`/users/${id}/activation`);

  return response.data;
};
