import { apiClient } from "lib/apiClient";

export interface ICompanyGroup {
  city: string;
  contactEmail: string;
  contactFirstName: string;
  contactLastName: string;
  contactPhone: string;
  contactPhonePrefix: string;
  creationDate: string;
  id: string;
  lastModifiedDate: string;
  lastLoginDate: string | null;
  status: string;
  name: string;
  state: string;
  street: string;
  zipCode: string;
  active: boolean;
  userListIsFull: boolean;
  wallet: {
    balance: number | null;
    id: string;
  };
  subscription: {
    endDate: string;
    id: string;
    startDate: string;
    subscriptionType: string;
  };
  country: string | null;
  statesCounties: Record<string, string[]>;
}

export type ICompanyGroups = {
  active: number;
  disabled: number;
  total: number;
  objects: ICompanyGroup[];
};

export const getCompanyGroups = async (
  status: string
): Promise<ICompanyGroups> => {
  const response = await apiClient.get(`/companies?status=${status}&size=200`);

  return response.data;
};
