import React from "react";
import { Box, Modal, Backdrop, Fade } from "@mui/material";
import { Props } from "./interfaces";
import { submitKey } from "config/constants";
import closeIcon from "assets/svg/closeIconDark.svg";

export const ModalComponent = (props: Props) => {
  const handleEnter = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === submitKey) {
      event.preventDefault();
    }
  };

  return (
    <Modal
      onKeyDown={handleEnter}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            maxHeight: "90%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            overflow: "auto",
            ...props.boxStyles,

            "@media screen and (max-width:500px)": {
              maxWidth: "90%",
            },
          }}
        >
          {props.closable && (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: props.headerChildren
                  ? "space-between"
                  : "flex-end",
              }}
            >
              {props.headerChildren}
              <Box
                data-testid="CloseIcon"
                onClick={props.handleClose}
                sx={{
                  m: "15px 20px 0px 0px",
                  cursor: "pointer",
                  "&:hover": {
                    opacity: 0.7,
                  },
                }}
              >
                <img alt="close" src={closeIcon} />
              </Box>
            </Box>
          )}
          {props.children}
        </Box>
      </Fade>
    </Modal>
  );
};
