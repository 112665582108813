import { ExpenseCompInfoType } from "features/Comp/interfaces";
import { apiClient } from "lib/apiClient";
import { ICreateExpenseComp } from "pages/Comps/components/ExpenseCompModal/interfaces";

export const addExpenseComp = async (
  request: ICreateExpenseComp,
  originalCompId: string | null
): Promise<ExpenseCompInfoType> => {
  const response = await apiClient.post(
    `/comps/expense${
      originalCompId ? `?originalCompId=${originalCompId}` : ""
    }`,
    request
  );

  return response.data;
};
