import { disabledAccountError } from "config/constants";
import { FormikValues, FormikErrors } from "formik";
import { editUser } from "api/editUser";
import { IEditUser } from "features/CompanyGroups/interfaces";
import { CognitoUser } from "amazon-cognito-identity-js";
import { TypedDispatch } from "store/index";
import { routes } from "config/routes";
import { Auth, Amplify } from "aws-amplify";
import { IUserDetails } from "api/getUserById";
import { getCurrentUser } from "api/getCurrentUser";
import { Dispatch } from "redux";
import { localStorageManager } from "lib/localStorageManager";
import {
  ISignInError,
  IUserCred,
  Actions,
  SignInLoadingPayload,
} from "./interfaces";
import { signInConfig } from "./signInConfig";
import { NavigateFunction } from "react-router-dom";
import { login } from "api/login";
import { signInErrors } from "./constants";
import { activateEmail } from "api/activateEmail";

Amplify.configure(signInConfig);

const newPasswordRequired = "NEW_PASSWORD_REQUIRED";
const errorOTPCode = "CodeMismatchException";
const existedEmail = "already exists";
const attemptsExceededMessage =
  "Attempt limit exceeded, please try after some time.";

export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
export const OPEN_NEW_PASSWORD = "OPEN_NEW_PASSWORD";
export const SET_USER_CRED = "SET_USER_CRED";
export const FETCH_USER_INFO_ERROR = "FETCH_USER_INFO_ERROR";
export const OPEN_ERROR_MODAL = "OPEN_ERROR_MODAL";
export const OPEN_POLICY_MODAL = "OPEN_POLICY_MODAL";
export const SET_SUCCESS_SIGN_IN_OPEN = "SET_SUCCESS_SIGN_IN_OPEN";
export const SET_SIGN_IN_LOADING = "SET_SIGN_IN_LOADING";
export const SET_EMAIL_VERIFICATION = "SET_EMAIL_VERIFICATION";
export const USER_LOG_OUT = "USER_LOG_OUT";

export const setUserCred = (value: IUserCred): Actions => ({
  type: SET_USER_CRED,
  payload: value,
});

export const setNewPasswordModal = (value: boolean): Actions => ({
  type: OPEN_NEW_PASSWORD,
  payload: value,
});

export const setErrorModal = (value: boolean): Actions => ({
  type: OPEN_ERROR_MODAL,
  payload: value,
});
export const setPolicyModal = (value: boolean): Actions => ({
  type: OPEN_POLICY_MODAL,
  payload: value,
});

export const signInError = (payload: ISignInError): Actions => ({
  type: SIGN_IN_ERROR,
  payload,
});

export const signInSuccess = (payload: IUserDetails): Actions => ({
  type: SIGN_IN_SUCCESS,
  payload,
});

export const setSuccessModalOpen = (payload: boolean): Actions => ({
  type: SET_SUCCESS_SIGN_IN_OPEN,
  payload,
});

export const setSignInLoading = (payload: SignInLoadingPayload): Actions => ({
  type: SET_SIGN_IN_LOADING,
  payload,
});

export const setUserLogOut = () => ({
  type: USER_LOG_OUT,
});

export const setEmailVerification = (payload: string) => ({
  type: SET_EMAIL_VERIFICATION,
  payload,
});

export const logInUser = async (
  userInfo: IEditUser,
  navigate: NavigateFunction
) => {
  try {
    await editUser(userInfo);
    navigate(routes.private.properties);
  } catch (error) {
    console.log(error);
  }
};

export const fetchUserInfo =
  (
    id: string,
    isLogin?: boolean,
    navigate?: NavigateFunction,
    resetForm?: () => void,
    prevPath?: string
  ) =>
  async (dispatch: TypedDispatch) => {
    try {
      const response = await getCurrentUser(id);
      dispatch(signInSuccess(response));
      if (!response.agreedToLegalDocs) {
        dispatch(setPolicyModal(true));
      } else if (isLogin && navigate && resetForm) {
        navigate(prevPath || routes.private.properties);
        resetForm();
      }
    } catch (error) {
      dispatch(setSignInLoading({ signIn: false }));
      if (error.response.status === 401) {
        dispatch(signInError(signInErrors[error.response.data]));
        if (error.response.data === disabledAccountError) {
          dispatch(setErrorModal(true));
        }
      }
    }
  };

export const signIn =
  (
    userName: string,
    password: string,
    navigate: NavigateFunction,
    resetForm: () => void,
    prevPath?: string
  ) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setSignInLoading({ signIn: true }));
    try {
      const response = await Auth.signIn(userName, password);
      if (response.challengeName === newPasswordRequired) {
        dispatch(
          setUserCred({
            user: response,
            password,
          })
        );
        dispatch(setNewPasswordModal(true));
      }

      if (response?.signInUserSession?.accessToken) {
        localStorageManager.setToken(
          response.signInUserSession.accessToken.jwtToken
        );
        await login();
        localStorageManager.setUserAuthServiceId(response.attributes.sub);
        dispatch(
          fetchUserInfo(
            response.attributes.sub,
            true,
            navigate,
            resetForm,
            prevPath
          )
        );
      }
      dispatch(setSignInLoading({ signIn: false }));
    } catch (error) {
      dispatch(setSignInLoading({ signIn: false }));
      const errorKey =
        error?.response?.status === 401 ? error.response.data : error.message;
      dispatch(signInError(signInErrors[errorKey]));
      if (error?.response?.data === disabledAccountError) {
        dispatch(setErrorModal(true));
      }
    }
  };

export const changePassword =
  (user: CognitoUser | any, newPassword: string) =>
  async (dispatch: Dispatch) => {
    try {
      await Auth.completeNewPassword(user, newPassword);
      dispatch(setNewPasswordModal(false));
    } catch (error) {
      console.log(error);
    }
  };

export const sendForgotPasswordOTP =
  (
    email: string,
    handleStep: (step: string) => void,
    step: string,
    setErrorText: (value: string) => void
  ) =>
  async () => {
    try {
      await Auth.forgotPassword(email);
      handleStep(step);
    } catch (error) {
      setErrorText("Incorrect e–mail, please retry");
    }
  };

export const forgotPassword =
  (
    email: string,
    code: string,
    password: string,
    handleStep: (step: string) => void,
    step: string,
    resetForm: () => void,
    setFieldError: (field: string, errorMessage: string) => void
  ) =>
  async () => {
    try {
      await Auth.forgotPasswordSubmit(email, code, password);
      handleStep(step);
      resetForm();
    } catch (error) {
      if (error.code === errorOTPCode) {
        setFieldError("code", "Incorrect code, please retry");
      }
    }
  };

export const changeProfilePassword =
  (
    oldPassword: string,
    newPassword: string,
    setErrors: (errors: FormikErrors<FormikValues>) => void,
    errors: FormikErrors<FormikValues>,
    resetForm: () => void
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setSignInLoading({ editPassword: true }));
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
      dispatch(setSignInLoading({ editPassword: false }));
      dispatch(setSuccessModalOpen(true));
      resetForm();
    } catch (error) {
      dispatch(setSignInLoading({ editPassword: false }));
      error.message === attemptsExceededMessage
        ? setErrors({
            ...errors,
            currentPassword:
              "Too many attempts. Please try again in 15 minutes",
          })
        : setErrors({
            ...errors,
            currentPassword: "Password is incorrect",
          });
    }
  };

export const changeProfileEmail =
  (
    userInfo: IUserDetails,
    setErrors: (errors: FormikErrors<FormikValues>) => void,
    errors: FormikErrors<FormikValues>
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setSignInLoading({ editEmail: true }));
    try {
      await editUser(userInfo);
      dispatch(setSignInLoading({ editEmail: false }));
      dispatch(signInSuccess(userInfo));
      dispatch(setSuccessModalOpen(true));
    } catch (error) {
      dispatch(setSignInLoading({ editEmail: false }));
      error.response.data.includes(existedEmail)
        ? setErrors({
            ...errors,
            email: "User with this email already exists in the system",
          })
        : console.log(error);
    }
  };

export const verifyEmail =
  (token: string) => async (dispatch: TypedDispatch) => {
    dispatch(setSignInLoading({ emailVerification: true }));
    try {
      await activateEmail(token);
      dispatch(setEmailVerification("success"));
      dispatch(setSignInLoading({ emailVerification: false }));
    } catch (error) {
      dispatch(setEmailVerification("fail"));
      dispatch(setSignInLoading({ emailVerification: false }));
    }
  };
