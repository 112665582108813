import { TableCell, styled, Typography, Button } from "@mui/material";

export const StyledTypography = styled(Typography)({
  textDecoration: "underline",
  cursor: "pointer",
});

export const StyledNameCell = styled(TableCell)({ maxWidth: "400px" });

export const StyledAddButton = styled(Button)({
  padding: "11px 7px",
  width: "128px",
  height: "36px",
});
