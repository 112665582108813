import React from "react";
import { Box } from "@mui/material";

export const PageWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        padding: "40px 50px 0 50px",
        backgroundColor: "#F9F9F9",
        minHeight: "100vh",
      }}
    >
      {children}
    </Box>
  );
};
