import React, { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import { ErrorHelperText } from "components/ErrorHelperText";
import { useDropzone } from "react-dropzone";
import styles from "./UploadMultipleFiles.module.scss";
import attachment from "assets/images/attachment.png";
import redCancelIcon from "assets/svg/redCancelIcon.svg";
import greenSuccessIcon from "assets/svg/greenSuccessIcon.svg";
import close from "assets/images/close.png";
import {
  bytesLimit,
  errorFileSize,
  fileExtensions,
  sortedKey,
} from "./constants";
import { IMultipleFileData } from "pages/Comps/components/CreateSaleComp/interfaces";
import { validateFiles } from "utils/validateFiles";
import { IFilesData, IMultipleAttachments, IUploadedFiles } from "./interfaces";

type Props = {
  handleFilesError: (value: boolean) => void;
  handleFilesValue: (value: IMultipleFileData[]) => void;
  attachmentsToDelete: string[];
  files: IMultipleFileData[];
  handleAttachmentsToDelete?: (ids: string[]) => void;
  savedFiles?: IMultipleFileData[];
  attachments: IMultipleAttachments[];
  isFilesVisible?: boolean;
  supportedFiles?: string;
  supportedExtensions?: string[];
  maxSizeLimit?: number;
  limitText?: string;
  fileKey?: string;
  outerFilesBoxStyles?: SxProps<Theme>;
  fileItemStyles?: SxProps<Theme>;
  inputStyles?: SxProps<Theme>;
  inputBoxStyles?: SxProps<Theme>;
  additionalText?: string;
  additionalNodeInfo?: ReactNode;
  supportedFileNames?: string[];
  hasLimitText?: boolean;
  hasMultipleUpload?: boolean;
  validationErrorsBox?: React.ReactNode;
  filesLimit?: number;
};

export const UploadMultipleFiles = ({
  handleFilesError,
  handleFilesValue,
  handleAttachmentsToDelete,
  attachmentsToDelete,
  files,
  savedFiles,
  isFilesVisible,
  supportedFiles = "JPEG, JPG, PNG, PDF",
  supportedExtensions = fileExtensions,
  maxSizeLimit = bytesLimit,
  limitText = "50MB",
  attachments,
  fileKey = "file",
  outerFilesBoxStyles,
  fileItemStyles,
  supportedFileNames,
  additionalText = "",
  additionalNodeInfo,
  inputStyles,
  inputBoxStyles,
  hasLimitText = true,
  hasMultipleUpload = true,
  validationErrorsBox,
  filesLimit,
}: Props) => {
  const [filesSize, setFilesSize] = useState<number>(0);
  const [filesData, setFilesData] = useState<IFilesData[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const isMaxSizeReached: boolean = filesSize > maxSizeLimit;
  const sortedFilesData = useMemo(() => {
    return filesData.every((item) => sortedKey in item)
      ? filesData.sort((item1, item2) => (item1.order > item2.order ? 1 : -1))
      : filesData;
  }, [filesData]);

  const { getRootProps } = useDropzone({
    noClick: true,
    multiple: hasMultipleUpload,
    onDrop: (acceptedFiles) => {
      handleDataToUpload(acceptedFiles);
    },
  });

  useEffect(() => {
    const parsedAttachments = attachments.map((item, index) => {
      return {
        name: `${item.filename}${item.extension || ""}`,
        key: `${item.filename}${item.id || ""}`,
        size: item.fileSize || item.size,
        errorText: "",
        order: item.order || index,
      };
    });
    if (isFilesVisible) setFilesData((prev) => [...prev, ...parsedAttachments]);
  }, [attachments]);

  useEffect(() => {
    const validFiles = extractValidFiles();
    const totalFilesSize = validateFileSize(validFiles);
    changeFilesSize(totalFilesSize);
    if (isMaxSizeReached || validFiles.length !== filesData.length) {
      handleFilesError(false);
    } else {
      handleFilesError(true);
    }
  }, [filesData, filesSize]);

  useEffect(() => {
    if (savedFiles?.length) {
      setFilesData(
        savedFiles.map((file) => {
          const fileData = Object.fromEntries(file.data.entries());

          return {
            name: file.id,
            key: file.id,
            size: (fileData?.photo as File)?.size ?? fileData.size,
            errorText: "",
            order: file.order,
          };
        })
      );

      savedFiles && handleFilesValue(savedFiles);
    }
  }, [savedFiles]);

  const extractValidFiles = (): IFilesData[] => {
    return filesData.filter(
      (fileData) => !fileData.errorText || fileData.errorText === errorFileSize
    );
  };

  const validateFileSize = (validFiles: IFilesData[]) => {
    return validFiles.reduce((acc, file) => {
      const summarySize = acc + file.size;
      file.errorText = summarySize > maxSizeLimit ? errorFileSize : "";

      return summarySize;
    }, 0);
  };

  const changeFilesSize = (updatedSize: number) => {
    setFilesSize(updatedSize);
  };

  const handleFileData = (updatedValue: IFilesData[] | []) => {
    if (filesLimit && updatedValue.length > filesLimit) {
      clearInputValue();
    } else {
      setFilesData(updatedValue);
    }
  };

  const clearInputValue = () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const deleteFileData = (id: string) => {
    const updatedFiles = filesData.filter((fileData) => fileData.key !== id);
    const updatedFilesToSend = files.filter((item) => item.id !== id);

    const attachmentIdToDelete = attachments.find(
      (item) => `${item.filename}${item.id || ""}` === id
    )?.id;
    if (attachmentIdToDelete) {
      handleAttachmentsToDelete?.([
        ...attachmentsToDelete,
        attachmentIdToDelete,
      ]);
    }
    handleFilesValue(updatedFilesToSend);
    handleFileData(updatedFiles);
    clearInputValue();
  };

  const uploader = (validatedFiles: IUploadedFiles[]) => {
    const formDatas: IMultipleFileData[] = [];
    let dataOrder = filesData.length
      ? filesData[filesData.length - 1].order + 1
      : 0;
    const promises: Promise<IFilesData>[] = validatedFiles.map((file) => {
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.addEventListener("load", (event: ProgressEvent<FileReader>) => {
          if (!event?.target?.result) return;
          resolve({
            name: file.name,
            key: file.name,
            size: file.size,
            errorText: file.errorText,
            order: dataOrder,
          });
          if (!file.errorText) {
            const formData = new FormData();
            formData.append(fileKey, file);
            formDatas.push({
              id: file.name,
              data: formData,
              order: dataOrder,
            });
            handleFilesError(true);
            dataOrder += 1;
          }
        });
        reader.addEventListener("error", (event: ProgressEvent<FileReader>) =>
          reject(console.log(event.target?.error))
        );
        if (file) {
          reader.readAsDataURL(file);
        }
      });
    });
    Promise.all(promises)
      .then((data) => {
        handleFileData([...filesData, ...data]);
        return formDatas;
      })
      .then((fileList) => {
        handleFilesValue([...files, ...fileList]);
      });
  };

  const checkFileName = (uploadedFiles: File[]) => {
    return uploadedFiles.filter(
      (uploadedFile) =>
        !filesData.some((item) => item.name === uploadedFile.name)
    );
  };

  const handleDataToUpload = (uploadedFiles: File[]) => {
    const filteredFiles = checkFileName(uploadedFiles);
    const validatedFiles = validateFiles(
      filteredFiles,
      supportedExtensions,
      supportedFiles?.toLowerCase().split(", "),
      supportedFileNames
    );
    uploader(validatedFiles);
  };

  const handleOnChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (files?.length) {
      handleDataToUpload(Array.from(files));
    }
  };

  return (
    <Box {...getRootProps()}>
      <Box
        sx={{
          mb: "5px",
          minHeight: "80px",
          height: "100%",
          p: "15px",
          display: "flex",
          alignItems: additionalNodeInfo ? "flex-start" : "center",
          backgroundColor: "primary.light",
          borderRadius: "6px",
          ...inputBoxStyles,
        }}
      >
        <Box
          sx={{
            height: "35px",
            mr: "10px",
            p: "15px",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            border: "1px solid #E3E3E3",
            borderRadius: "4px",
            ...inputStyles,
          }}
        >
          <img
            src={attachment}
            alt="attachment"
            style={{
              marginRight: "5px",
            }}
          />
          <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
            Choose file
          </Typography>
          <input
            type="file"
            onChange={(event) => handleOnChangeEvent(event)}
            className={styles.fileSave}
            ref={inputRef}
            multiple={hasMultipleUpload}
          />
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              whiteSpace: "pre-line",
              lineHeight: "16px",
              color: "rgba(61, 61, 61, .7)",
            }}
          >
            {`Files supported: ${supportedFiles}${additionalText}`}
          </Typography>
          {additionalNodeInfo}
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {isMaxSizeReached && hasLimitText ? (
          <ErrorHelperText
            value={`Max. limit of ${limitText} reached`}
            isVisible={isMaxSizeReached}
            fontSize="12px"
          />
        ) : hasLimitText ? (
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "300",
            }}
          >
            Upload max. {limitText}
          </Typography>
        ) : null}
      </Box>
      <Box sx={{ ...outerFilesBoxStyles }}>
        {sortedFilesData.map(
          (fileData) =>
            fileData?.name && (
              <Box sx={{ mb: "15px" }} key={fileData.key}>
                <Box
                  sx={{
                    width: "50%",
                    pb: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderBottom: "1px solid #E3E3E3",
                    ...fileItemStyles,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        mr: "10px",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                    >
                      {fileData?.name}
                      <img
                        style={{
                          paddingLeft: "4px",
                        }}
                        src={
                          fileData.errorText ? redCancelIcon : greenSuccessIcon
                        }
                        alt="icon"
                      />
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => {
                      deleteFileData(fileData.key);
                    }}
                    sx={{
                      minWidth: "20px",
                      minHeight: "20px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "rgba(222, 53, 53, .1)",
                      borderRadius: "50%",
                    }}
                    id={fileData.key}
                  >
                    <img
                      src={close}
                      alt="close"
                      style={{
                        width: "7px",
                        height: "7px",
                        cursor: "pointer",
                      }}
                    />
                  </Box>
                </Box>
                <ErrorHelperText
                  isVisible={!!fileData.errorText}
                  value={fileData.errorText}
                  fontSize={"12px"}
                />
              </Box>
            )
        )}
      </Box>
      {validationErrorsBox}
    </Box>
  );
};
