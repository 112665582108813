import { combineReducers } from "@reduxjs/toolkit";
import VisitorsReducer from "../features/Visitors/VisitorsReducer";
import CompanyGroupsReducer from "../features/CompanyGroups/CompanyGroupsReducer";
import PropertyReducer from "../features/Property/PropertyReducer";
import { SignInReducer } from "features/SignIn/SignInReducer";
import CompReducer from "../features/Comp/CompReducer";
import DealsReducer from "../features/Deals/DealsReducer";
import { Actions as VisitorActions } from "features/Visitors/interfaces";
import { Actions as SignInActions } from "features/SignIn/interfaces";
import { Actions as CompanyActions } from "features/CompanyGroups/interfaces";
import { Actions as PropertyActions } from "features/Property/interfaces";
import { Actions as CompActions } from "features/Comp/interfaces";
import { Actions as DealsActions } from "features/Deals/interfaces";
import templatesSlice from "features/Templates/slice";

const appReducer = combineReducers({
  templates: templatesSlice.reducer,
  VisitorsReducer,
  SignInReducer,
  CompanyGroupsReducer,
  PropertyReducer,
  CompReducer,
  DealsReducer,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer = (
  state: RootState | undefined,
  action:
    | VisitorActions
    | SignInActions
    | CompanyActions
    | PropertyActions
    | CompActions
    | DealsActions
) => {
  if (action.type === "USER_LOG_OUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
