import { Tabs as MuiTabs, TabsTypeMap } from "@mui/material";
import { styled } from "@mui/system";

export const Tabs = styled(MuiTabs)<TabsTypeMap["props"]>(({ theme }) => ({
  "& .MuiTab-root": {
    padding: "0 10px",
    alignItems: "flex-start",
    textTransform: "none",
    color: theme.palette.primary.main,
    fontSize: "14px",
    fontWeight: "600",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.secondary.main,
    height: "4px",
  },
  "& .Mui-selected": {
    color: `${theme.palette.secondary.main} !important`,
  },
  "& .MuiTabs-flexContainer": {
    gap: theme.spacing(5),
  },
}));
