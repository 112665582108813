import { apiClient } from "lib/apiClient";
import { ExpenseAnalysisConfig } from "./editExpenseAnalysisConfig";

export const getExpenseAnalysisConfig = async (
  propertyId: string
): Promise<ExpenseAnalysisConfig> => {
  const response = await apiClient.get(
    `/configuration/analysis/expense/${propertyId}`
  );

  return response.data;
};
