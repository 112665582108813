import { Box, Collapse, TableCell, TableRow, Typography } from "@mui/material";
import React, { useState } from "react";
import { TTemplate } from "features/Templates/types";
import {
  StyledCollapsibleTableCell,
  StyledExpandIcon,
  StyledIconButton,
} from "./MyTemplates.styled";
import { StyledTypography } from "../Components.styled";
import { parseUrlToBlob } from "../../../../api/parseUrlToBlob";
import FileSaver from "file-saver";
import { toastMessage } from "../../../../components/ToastMessage";
import { templatesTranslations } from "../../translations";

interface IGroupedTableRowProps {
  data: TTemplate[];
  companyName: string;
}

export const GroupedTableRow = ({
  data,
  companyName,
}: IGroupedTableRowProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpenRow = () => setIsOpen((prevState) => !prevState);

  const handleDownloadFile =
    (linkToFile: string, title: string) => async () => {
      try {
        const blob = await parseUrlToBlob(linkToFile);
        const file = new File([blob], title);

        FileSaver.saveAs(file, title);
      } catch (err) {
        toastMessage({
          type: "error",
          position: "top-right",
          message: templatesTranslations.somethingWentWrong,
        });
      }
    };

  return (
    <>
      <TableRow style={{ border: "none", height: "40px" }}>
        <TableCell>
          <StyledIconButton
            isOpen={isOpen}
            aria-label="expand row"
            size="small"
            onClick={toggleOpenRow}
          >
            <StyledExpandIcon isOpen={isOpen} />
          </StyledIconButton>
        </TableCell>
        <TableCell>
          <Typography variant="h5">{companyName}</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <StyledCollapsibleTableCell isOpen={isOpen} colSpan={6}>
          <Collapse in={isOpen} timeout="auto">
            <Box display="flex" flexDirection="column" gap={1} mb={2}>
              {data.map((item) => (
                <StyledTypography
                  height={34}
                  variant="h5"
                  key={item.id}
                  onClick={handleDownloadFile(item.linkToFile, item.title)}
                >
                  {item.title}
                </StyledTypography>
              ))}
            </Box>
          </Collapse>
        </StyledCollapsibleTableCell>
      </TableRow>
    </>
  );
};
