import { RootState } from "../rootReducer";

export const isNewPasswordOpen = (state: RootState) =>
  state.SignInReducer.isPasswordOpen;
export const oldUserCred = (state: RootState) =>
  state.SignInReducer.oldUserCred;
export const singInError = (state: RootState) =>
  state.SignInReducer.singInError;
export const isErrorModalOpen = (state: RootState) =>
  state.SignInReducer.isErrorModalOpen;
export const userInfo = (state: RootState) => state.SignInReducer.userInfo;
export const isPolicyModalOpen = (state: RootState) =>
  state.SignInReducer.isPolicyModalOpen;
export const isSuccessOpen = (state: RootState) =>
  state.SignInReducer.isSuccessOpen;
export const isLoading = (state: RootState) => state.SignInReducer.isLoading;
export const emailVerification = (state: RootState) =>
  state.SignInReducer.emailVerification;
