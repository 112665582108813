import { IStates } from "./PropertiesFilter";
import {
  IFilterStates,
  ISelectedFilters,
  ISelectedCompFilters,
  ISelectedServicesParams,
  AdvancedSearchType,
} from "features/Property/interfaces";
import { propertyServices } from "../../../AddProperty/components/PropertyForm/constants";
import { states } from "config/states";
import * as yup from "yup";
import { isAfter, isEqual, isFuture } from "date-fns";

export const validationSchema = yup.object().shape({
  closingDateFrom: yup
    .date()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value)),
  closingDateTo: yup
    .date()
    .test("validDateValue", "Invalid date", (_, testContext) => {
      const { closingDateTo, closingDateFrom } = testContext.parent;
      if (closingDateTo && closingDateFrom) {
        return (
          (isAfter(closingDateTo, closingDateFrom) ||
            isEqual(closingDateTo, closingDateFrom)) &&
          !isFuture(closingDateTo)
        );
      }
      return !isFuture(closingDateTo);
    })
    .nullable()
    .transform((value) => (isNaN(value) ? null : value)),
  pricePerUnitFrom: yup.number(),
  pricePerUnitTo: yup
    .number()
    .when("pricePerUnitFrom", (pricePerUnitFrom, field) =>
      pricePerUnitFrom && field
        ? field.moreThan(yup.ref("pricePerUnitFrom"))
        : field
    ),
  plYearFrom: yup.string().length(4).nullable(),
  plYearTo: yup
    .string()
    .nullable()
    .when("plYearFrom", (plYearFrom, field) =>
      plYearFrom && field
        ? field.test("moreThan", "Incorrect value", (value: string) => {
            return value ? +value >= +plYearFrom : true;
          })
        : field.length(4).nullable()
    ),
  revenueFrom: yup.number().max(9999),
  revenueTo: yup
    .number()
    .max(9999)
    .when("revenueFrom", (revenueFrom, field) =>
      revenueFrom && field ? field.min(yup.ref("revenueFrom")) : field
    ),
  expenseFrom: yup.number().max(999),
  expenseTo: yup
    .number()
    .max(999)
    .when("expenseFrom", (expenseFrom, field) =>
      expenseFrom && field ? field.min(yup.ref("expenseFrom")) : field
    ),
  licensedBedsAlFrom: yup.number().max(9999),
  licensedBedsAlTo: yup
    .number()
    .max(9999)
    .when("licensedBedsAlFrom", (licensedBedsAlFrom, field) =>
      licensedBedsAlFrom && field
        ? field.min(yup.ref("licensedBedsAlFrom"))
        : field
    ),
  licensedBedsMcFrom: yup.number().max(9999),
  licensedBedsMcTo: yup
    .number()
    .max(9999)
    .when("licensedBedsMcFrom", (licensedBedsMcFrom, field) =>
      licensedBedsMcFrom && field
        ? field.min(yup.ref("licensedBedsMcFrom"))
        : field
    ),
  licensedBedsSnFrom: yup.number().max(9999),
  licensedBedsSnTo: yup
    .number()
    .max(9999)
    .when("licensedBedsSnFrom", (licensedBedsSnFrom, field) =>
      licensedBedsSnFrom && field
        ? field.min(yup.ref("licensedBedsSnFrom"))
        : field
    ),
  verificationDateFrom: yup
    .date()
    .nullable()
    .transform((value) => (isNaN(value) ? null : value)),
  verificationDateTo: yup
    .date()
    .test("validDateValue", "Invalid date", (_, testContext) => {
      const { verificationDateTo, verificationDateFrom } = testContext.parent;
      if (verificationDateTo && verificationDateFrom) {
        return (
          (isAfter(verificationDateTo, verificationDateFrom) ||
            isEqual(verificationDateTo, verificationDateFrom)) &&
          !isFuture(verificationDateTo)
        );
      }
      return !isFuture(verificationDateTo);
    })
    .nullable()
    .transform((value) => (isNaN(value) ? null : value)),
  yearOpenedFrom: yup.number(),
  yearOpenedTo: yup
    .number()
    .when("yearOpenedFrom", (yearOpenedFrom, field) =>
      yearOpenedFrom && field ? field.min(yup.ref("yearOpenedFrom")) : field
    ),
});

export const services = [
  {
    title: "Active Adult",
    value: "activeAdult",
  },
  {
    title: "Independent Living",
    value: "independentLiving",
  },
  {
    title: "Assisted Living",
    value: "assistedLiving",
    accordion: {
      from: "licensedBedsAlFrom",
      to: "licensedBedsAlTo",
    },
  },
  {
    title: "Memory Care",
    value: "memoryCare",
    accordion: {
      from: "licensedBedsMcFrom",
      to: "licensedBedsMcTo",
    },
  },
  {
    title: "Skilled Nursing",
    value: "skilledNursing",
    accordion: {
      from: "licensedBedsSnFrom",
      to: "licensedBedsSnTo",
    },
  },
];

export const resolveExpandedStates = () => {
  return states.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.value]: false,
    };
  }, {});
};

export const resolveDefaultStates = () => {
  return states.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.value]: {
        isSelected: false,
        selectedCounty: [],
      },
    };
  }, {});
};

export const defineDefaultLocationFilters = (
  locationConfig: Record<string, string[]>
) =>
  Object.entries(locationConfig).length
    ? Object.entries(locationConfig).reduce<IFilterStates>(
        (acc, [state, county]) => {
          acc[state] = {
            isSelected: false,
            selectedCounty: county,
          };

          return acc;
        },
        {}
      )
    : resolveDefaultStates();

export const resolveSelectedServices = (selectedServices: string[]) => {
  return propertyServices.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.serviceValue]: selectedServices.includes(curr.value) || null,
    };
  }, {});
};

export const createRequestParams = (
  filterStates: IFilterStates,
  searchedPropertyName: string,
  selectedFilters: ISelectedFilters,
  selectedCompFilters: ISelectedCompFilters,
  selectedServicesParams: ISelectedServicesParams,
  selectedAdvancedFilters: AdvancedSearchType,
  dealView: boolean,
  companyStatesCounties: Record<string, string[]>
) => {
  const stateAndCountyFilter = Object.entries(filterStates).reduce(
    (acc: IStates, [stateKey, stateConfig]) => {
      if (stateConfig.isSelected || stateConfig.selectedCounty.length) {
        acc[stateKey] =
          stateConfig.isSelected && !companyStatesCounties?.[stateKey]?.length
            ? []
            : stateConfig.isSelected && companyStatesCounties?.[stateKey]
            ? companyStatesCounties?.[stateKey]
            : stateConfig.selectedCounty;
      }
      return acc;
    },
    {}
  );
  const savedStateAndCounty = Object.entries(filterStates).reduce(
    (acc: IFilterStates, [key, county]) => {
      if (county.isSelected || county.selectedCounty.length) {
        acc[key] = {
          isSelected: county.isSelected,
          selectedCounty: county.selectedCounty.length
            ? county.selectedCounty
            : companyStatesCounties?.[key]?.length
            ? companyStatesCounties?.[key]
            : [],
        };
      }
      return acc;
    },
    {}
  );
  const selectedServices = resolveSelectedServices(selectedFilters.services);

  const requestFilters = {
    checkedCountry: selectedFilters.country,
    states: stateAndCountyFilter,
    searchedPropertyName,
    selectedServices,
    selectedCompTypes: selectedCompFilters,
    selectedServicesParams,
    propertyPhoto: selectedFilters.propertyPhoto,
    availableComps: selectedFilters.availableComps,
    dealView,
    advancedSearch: selectedAdvancedFilters,
  };
  const savedFilterParams = {
    ...requestFilters,
    states: savedStateAndCounty,
  };
  return { requestFilters, savedFilterParams };
};

export const defineIsFiltersApplied = (filters: {
  states: IStates;
  checkedCountry: string[];
  searchedPropertyName: string;
  selectedServices: {
    [key: string]: boolean | null;
  };
}) => {
  return Object.values(filters).some((item) => {
    if (typeof item === "object") {
      return Object.values(item).some((checkboxChecked) => !checkboxChecked);
    }
    return item.length;
  });
};

export const AUTO_DELETED_SERVICES_FIELDS = [
  {
    key: "assistedLiving",
    relatedFields: ["licensedBedsAlFrom", "licensedBedsAlTo"],
  },
  {
    key: "memoryCare",
    relatedFields: ["licensedBedsMcFrom", "licensedBedsMcTo"],
  },
  {
    key: "skilledNursing",
    relatedFields: ["licensedBedsSnFrom", "licensedBedsSnTo"],
  },
];

export const defaultFilters = {
  checkedCountry: [],
  states: {},
  searchedPropertyName: "",
  selectedServices: {
    activeAdultOffered: true,
    independentLivingOffered: true,
    assistedLiving: true,
    memoryCareOffered: true,
    skilledNursing: true,
  },
  selectedCompTypes: {
    isSaleCompsSelected: false,
    closingDateFrom: null,
    closingDateTo: null,
    pricePerUnitFrom: "",
    pricePerUnitTo: "",
    isRentCompsSelected: false,
    verificationDateFrom: null,
    verificationDateTo: null,
    isExpenseCompsSelected: false,
    plYearFrom: "",
    plYearTo: "",
    revenueFrom: "",
    revenueTo: "",
    expenseFrom: "",
    expenseTo: "",
  },
  selectedServicesParams: {
    licensedBedsAlFrom: "",
    licensedBedsAlTo: "",
    licensedBedsMcFrom: "",
    licensedBedsMcTo: "",
    licensedBedsSnFrom: "",
    licensedBedsSnTo: "",
  },
  propertyPhoto: null,
  availableComps: {
    hasSeniorComp: false,
    hasMemberComp: false,
    hasCompUnderReview: false,
  },
  dealView: false,
};

export const excludedTextFields = ["operatorName", "federalProviderNumber"];
