import React, { useEffect, useState } from "react";
import {
  deleteTemplates,
  getSeniorTemplates,
  getTemplatesCount,
} from "features/Templates/controllers";
import { useTypedDispatch } from "store";
import { ReactComponent as TrashIcon } from "assets/svg/smallSecondaryButtonIcons/trashIcon.svg";
import { useAbortPreviousRequest } from "utils/hooks/useAbortPreviousRequest";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import styles from "./MyTemplates/MyTemplates.module.scss";
import { CustomLoader } from "components/CustomLoader";
import { templatesTranslations } from "../translations";
import { useSelector } from "react-redux";
import {
  getSeniorCompsSortDirection,
  getSeniorCompsTemplates,
  getSeniorCompsTemplatesIsLoading,
} from "features/Templates/selectors";
import {
  getSeniorCompTableHeaders,
  SORT_COLUMN,
} from "../helpers/getSeniorCompTableHeaders";
import { TemplateTableRow } from "./TemplateTableRow";
import { LOADING_STATE } from "types/general";
import { LOADER_BOX_STYLE } from "./constants";
import { SORT_DIRECTION } from "config/constants";
import { updateSortDirection } from "features/Templates/slice";
import { toastMessage } from "components/ToastMessage";
import { TSubscriptionPlan } from "features/Templates/types";
import { SuccessModal } from "components/SuccessModal";
import successChecked from "assets/images/successChecked.png";

interface ISeniorCompsTemplatesProps {
  isAdminUser: boolean;
  handleUpdatePlans?: (id: string, subscriptions: TSubscriptionPlan[]) => void;
}

export const SeniorCompsTemplates = ({
  isAdminUser,
  handleUpdatePlans,
}: ISeniorCompsTemplatesProps) => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [checkedTemplates, setCheckedTemplates] = useState<Array<string>>([]);
  const dispatch = useTypedDispatch();
  const { abortPreviousRequest } = useAbortPreviousRequest();
  const data = useSelector(getSeniorCompsTemplates);
  const loadingState = useSelector(getSeniorCompsTemplatesIsLoading);
  const sortDirection = useSelector(getSeniorCompsSortDirection);

  useEffect(() => {
    const { signal } = abortPreviousRequest();
    dispatch(getSeniorTemplates({ abortSignal: signal, sortDirection }));
  }, [sortDirection]);

  const handleSortDirection = () => {
    const newDirection =
      sortDirection === SORT_DIRECTION.asc
        ? SORT_DIRECTION.desc
        : SORT_DIRECTION.asc;
    dispatch(updateSortDirection(newDirection));
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const handleCheckTemplate = (templateId: string) => {
    const isTemplateAlreadyChecked = checkedTemplates.includes(templateId);
    setCheckedTemplates((prevState) =>
      isTemplateAlreadyChecked
        ? prevState.filter(
            (checkedTemplateId) => checkedTemplateId !== templateId
          )
        : [...prevState, templateId]
    );
  };

  const handleDeleteTemplates = () => {
    dispatch(deleteTemplates(checkedTemplates)).then((resultAction) => {
      if (deleteTemplates.rejected.match(resultAction)) {
        toastMessage({
          message: templatesTranslations.somethingWentWrong,
          position: "top-right",
          type: "error",
        });
      } else {
        setIsSuccessModalOpen(true);
      }

      dispatch(getTemplatesCount());
      dispatch(getSeniorTemplates({}));
      setCheckedTemplates([]);
    });
  };

  return (
    <>
      {isAdminUser ? (
        <Box display="flex" justifyContent="flex-end" mb={3}>
          <Button
            disabled={!checkedTemplates.length}
            classes="outlined"
            onClick={handleDeleteTemplates}
            startIcon={<TrashIcon />}
            size="small"
          >
            {templatesTranslations.remove}
          </Button>
        </Box>
      ) : null}
      <Table className={styles.tableWrapper}>
        <TableHead>
          <TableRow>
            {getSeniorCompTableHeaders(isAdminUser).map((item) => (
              <TableCell key={item} colSpan={1}>
                {item === SORT_COLUMN ? (
                  <TableSortLabel
                    active={item === SORT_COLUMN}
                    direction={sortDirection}
                    onClick={handleSortDirection}
                  >
                    {item}
                  </TableSortLabel>
                ) : (
                  item
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody sx={{ position: "relative" }}>
          {loadingState === LOADING_STATE.pending ? (
            <CustomLoader
              styles={{
                color: "#E3E3E3",
                opacity: 0.6,
              }}
              boxStyles={LOADER_BOX_STYLE}
            />
          ) : (
            data?.map((item) => {
              return (
                <TemplateTableRow
                  key={item.id}
                  isAdminUser={isAdminUser}
                  isAbleToRemove={isAdminUser}
                  data={item}
                  checkedTemplates={checkedTemplates}
                  handleUpdatePlans={handleUpdatePlans}
                  handleCheckTemplate={handleCheckTemplate}
                />
              );
            })
          )}
        </TableBody>
      </Table>
      {isSuccessModalOpen && (
        <SuccessModal
          title={templatesTranslations.successToDelete}
          icon={successChecked}
          open
          handleClose={handleCloseSuccessModal}
        />
      )}
    </>
  );
};
