import { createStyles, ThemeOptions } from "@mui/material";

interface IThemeProps {
  isAdminPage: boolean;
}

const baseTheme = {
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 16,
  },
  palette: {
    common: {
      white: "#FFFFFF",
    },
    primary: {
      main: "#3D3D3D",
      dark: "#4F5463",
      light: "#F9F9F9",
    },
    text: {
      primary: "#3D3D3D",
      secondary: "#BFBFBF",
    },
    secondary: {
      main: "#E1A34B",
    },
    error: {
      main: "#DE3535",
    },
  },
};

export const theme = ({ isAdminPage }: IThemeProps) =>
  createStyles({
    ...baseTheme,

    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .Mui-error": {
              color: "#DE3535",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#D3D3D3",
            },
            "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
              borderColor: "#D3D3D3",
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          listbox: {
            fontSize: "12px",
            fontWeight: 600,
          },
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            fontFamily: "'Open Sans', sans-serif",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            height: "44px",
            textTransform: "none",
            borderRadius: 6,
            fontSize: "16px",
            fontWeight: "600",
            color: "#FFFFFF",

            "& .MuiTypography-root": {
              color: "#FFFFFF",
            },

            "&:hover": {
              backgroundColor: "#D79B47",
              color: "#FFFFFF",
            },
            "&:active": {
              backgroundColor: "#C99142",
              color: "#FFFFFF",
            },
            "&:disabled": {
              backgroundColor: "#EAC692",
              color: "#FFFFFF",
            },
          },
          textSizeLarge: {
            padding: "8px 21px",
          },
          sizeSmall: {
            height: "34px",
            padding: "9px 16px",
            fontSize: "12px",
          },
          containedPrimary: {
            color: "white",
          },
        },
        variants: [
          {
            props: { classes: "mainIcon" },
            style: {
              backgroundColor: "#E1A34B",
              color: "#FFFFFF",
              fill: "#FFFFFF",

              "& .MuiTypography-root": {
                color: "#FFFFFF",
              },

              "&:hover": {
                backgroundColor: "#D79B47",
                color: "#FFFFFF",
              },
              "&:active": {
                backgroundColor: "#C99142",
                color: "#FFFFFF",
              },

              "&:disabled": {
                backgroundColor: "#F9F9F9",
                color: "#BFBFBF",
                fill: "#BFBFBF",

                "& .MuiTypography-root": {
                  color: "#BFBFBF",
                },
              },
            },
          },
          {
            props: { classes: "dark" },
            style: {
              backgroundColor: "#4F5463",
              color: "#FFFFFF",
              fill: "#FFFFFF",

              "& .MuiTypography-root": {
                color: "#FFFFFF",
              },

              "&:hover": {
                backgroundColor: "#3D3D3D",
                color: "#FFFFFF",
                fill: "#FFFFFF",
              },

              "&:active": {
                backgroundColor: "#3D3D3D",
                color: "#FFFFFF",
                fill: "#FFFFFF",
              },

              "&:disabled": {
                backgroundColor: "#F9F9F9",
                color: "#BFBFBF",
                fill: "#BFBFBF",

                "& .MuiTypography-root": {
                  color: "#BFBFBF",
                },
              },
            },
          },
          {
            props: { classes: "light" },
            style: {
              backgroundColor: "#E3E3E3",
              color: "#3D3D3D",

              "& .MuiTypography-root": {
                color: "#3D3D3D",
              },

              "&:hover": {
                backgroundColor: "#D3D3D3",
                color: "#3D3D3D",
              },

              "&:active": {
                backgroundColor: "#D3D3D3",
                color: "#3D3D3D",
              },

              "&:disabled": {
                backgroundColor: "#F9F9F9",
                color: "#BFBFBF",
                fill: "#BFBFBF",

                "& .MuiTypography-root": {
                  color: "#BFBFBF",
                },
              },
            },
          },
          {
            props: { classes: "outlined" },
            style: {
              backgroundColor: "#FFFFFF",
              color: "#3D3D3D",
              border: "1px solid #E3E3E3",

              "& .MuiTypography-root": {
                color: "#3D3D3D",
              },

              "&:hover": {
                backgroundColor: "#F9F9F9",
                color: "#3D3D3D",
              },

              "&:active": {
                backgroundColor: "#F9F9F9",
                color: "#3D3D3D",
              },

              "&:disabled": {
                backgroundColor: "#F9F9F9",
                color: "#BFBFBF",
                fill: "#BFBFBF",
                border: "1px solid #E3E3E3",

                "& .MuiTypography-root": {
                  color: "#BFBFBF",
                },

                "& *": {
                  fill: "#BFBFBF",
                },
              },
            },
          },
          {
            props: { classes: "icon" },
            style: {
              backgroundColor: "#F9F9F9",
              fill: "#3D3D3D",

              "&:hover": {
                backgroundColor: "#E3E3E3",
                fill: "#3D3D3D",
              },

              "&:active": {
                backgroundColor: "#E3E3E3",
                fill: "#3D3D3D",
              },

              "&:disabled": {
                backgroundColor: "#F9F9F9",
                fill: "#BFBFBF",
              },
            },
          },
        ],
      },
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            background: "none",
            color: "rgb(65,68,79)",
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: "rgb(245, 246, 249)",
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&.MuiTableRow-head": {
              backgroundColor: "#F9F9F9",
            },
          },
          head: {
            backgroundColor: "#FFF",
            "& .MuiTableCell-root": {
              backgroundColor: "#F9F9F9",
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontWeight: 600,
          },
          root: {
            fontSize: 16,
            lineHeight: 1.2,
            padding: "6px 12px",
            maxHeight: "45px",
            letterSpacing: "0.02em",
            border: "none",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            borderRadius: "6px",
            border: "1px solid rgba(219, 226, 234, 1)",
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          page: {
            fontSize: 12,
            color: "rgb(131,131,131)",
            "&.selected": {
              backgroundColor: "#EBEBEB",
              color: "#FFFFFF",
              fontWeight: 500,
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          outlined: {
            fontWeight: 500,
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            border: "none",
          },
        },
      },
      MuiSelect: {
        styleOverrides: {},
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            padding: "24px",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: 20,
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            transition: "opacity .3s",
            transform: "none",
            boxShadow: "0 0 15px rgba(0, 0, 0, .1)",
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            marginBottom: "6px",
            display: "block",
            fontSize: "12px",
            fontWeight: "600",
            color: "black",
            "&.MuiInputLabel-root": {},
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            fontSize: "14px",
          },
        },
      },
      MuiTabs: {
        styleOverrides: {},
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            height: 20,
            padding: 0,
            width: 35,
          },
          switchBase: {
            padding: 2,
            margin: 2,
            "&.Mui-checked": {
              transform: "translateX(16px)",
              color: "#fff",
              "& + .MuiSwitch-track": {
                backgroundColor: baseTheme.palette.secondary.main,
                opacity: 1,
                border: 0,
              },
              "&.Mui-disabled + .MuiSwitch-track": {
                opacity: 0.5,
              },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
              color: "#33cf4d",
              border: "6px solid #fff",
            },

            "&.Mui-checked .MuiSwitch-thumb": {
              boxShadow:
                "0px 2px 1px -1px #C18C42, 0px 1px 1px 0px #C18C42, 0px 1px 3px 0px #C18C42",
            },
          },
          track: {
            borderRadius: 26 / 2,
            backgroundColor: "#E9E9EA",
            opacity: 1,
          },
          thumb: {
            boxSizing: "border-box",
            width: 12,
            height: 12,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: "6px",
            fontSize: 12,
          },
          clickable: {
            fontSize: 12,
            border: "1px solid #DBE2EA",
            backgroundColor: "#FAFAFA",
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            minWidth: "auto",
            color: baseTheme.palette.text.primary,
            letterSpacing: "0.03em",
          },
          h1: {
            fontSize: isAdminPage ? "24px" : "34px",
            fontWeight: "600",
            lineHeight: isAdminPage ? "1em" : "1.3em`",

            "@media screen and (max-width: 1000px)": {
              fontSize: "28px",
            },
            "@media screen and (max-width: 600px)": {
              fontSize: "24px",
              lineHeight: "1.4em",
            },
          },
          h2: {
            fontSize: "30px",
            fontWeight: "600",

            "@media screen and (max-width: 1000px)": {
              fontSize: "24px",
            },
          },
          h3: {
            fontSize: "18px",
            fontWeight: "600",
            lineHeight: "1.4em",

            "@media screen and (max-width: 1000px)": {
              fontSize: "16px",
            },
          },
          h4: {
            fontSize: "20px",
            fontWeight: "600",

            "@media screen and (max-width: 600px)": {
              fontSize: "16px",
            },
          },
          h5: {
            fontSize: "14px",
            fontWeight: "300",
            lineHeight: "1.3em",

            "@media screen and (max-width: 600px)": {
              fontSize: "12px",
            },
          },
          subtitle1: {
            fontSize: "18px",
            fontWeight: "300",
            lineHeight: "1.5em",

            "@media screen and (max-width: 1000px)": {
              fontSize: "16px",
            },
          },
          subtitle2: {
            fontSize: "14px",
            fontWeight: "300",
            lineHeight: "1.5em",

            "@media screen and (max-width: 1000px)": {
              fontSize: "16px",
              lineHeight: "1.6em",
            },
          },
          body1: {
            fontSize: "16px",
            fontWeight: "300",
            lineHeight: "1.6em",

            "@media screen and (max-width: 1000px)": {
              fontSize: "14px",
            },
            "@media screen and (max-width: 600px)": {
              fontSize: "16px",
            },
          },
          body2: {
            fontSize: "16px",
            fontWeight: "300",
            lineHeight: "1.6em",

            "@media screen and (max-width: 600px)": {
              fontSize: "12px",
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            border: 0,

            "&:before": {
              backgroundColor: "white",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 6,
            fontSize: "14px",
            fontWeight: "300",
          },
          input: {
            padding: "12px 14px",
            fontSize: "14px",
            fontWeight: "300",
          },
        },
      },
    },
  }) as ThemeOptions;
