import { LOADING_STATE } from "types/general";
import { IState } from "./types";
import { SORT_DIRECTION } from "config/constants";

export const TEMPLATES_SLICE_NAME = "templates";

export const initialState: IState = {
  senior: {
    loadingState: LOADING_STATE.idle,
    sortDirection: SORT_DIRECTION.asc,
  },
  groupedTemplates: {
    loadingState: LOADING_STATE.idle,
  },
  member: {
    loadingState: LOADING_STATE.idle,
  },
  count: {
    memberTemplatesCount: 0,
    seniorTemplatesCount: 0,
  },
  templatesOptions: {
    loadingState: LOADING_STATE.idle,
    data: [],
  },
};
