import React, { ReactNode, memo, useEffect } from "react";
import Box from "@mui/material/Box";
import { Header } from "./components/Header";
import { Footer } from "components/Footer";
import { useLocation } from "react-router-dom";
import { useTypedDispatch } from "store";
import { setPageType } from "features/CompanyGroups/CompanyGroupsActions";
import { localStorageManager } from "lib/localStorageManager";
import { routes } from "config/routes";
import { checkRoute } from "utils/checkRoute";
import { Helmet } from "react-helmet";
import { GOOGLE_ANALYTICS_ID } from "config/constants";

type Props = {
  children: ReactNode;
};

export const VisitorsLayout = memo<Props>(({ children }) => {
  const { pathname } = useLocation();
  const dispatch = useTypedDispatch();
  const shouldAddTracking =
    process.env.NODE_ENV === "production" && pathname === routes.public.login;

  useEffect(() => {
    const isGeneralRoute = checkRoute(pathname, routes);
    if (isGeneralRoute) localStorageManager.clearStorage();
    const token = localStorageManager.getToken();
    dispatch(setPageType(!!token));
  }, [pathname]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Helmet>
          {shouldAddTracking && [
            <script
              key={1}
              type="text/javascript"
              id="hs-script-loader"
              async
              defer
              src={`//js-na1.hs-scripts.com/${process.env.REACT_APP_HUBSPOT_CLIENT_ID}.js`}
            ></script>,
            <script
              key={2}
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}
              onLoad={() => {
                window.dataLayer = window.dataLayer || [];

                function gtag(...args: any[]) {
                  window.dataLayer.push(args);
                }
                gtag("js", new Date());
                gtag("config", GOOGLE_ANALYTICS_ID);
              }}
            ></script>,
          ]}
        </Helmet>
        <Header />
        <Box component="main">{children}</Box>
        <Footer />
      </Box>
    </Box>
  );
});
