import { apiClient } from "lib/apiClient";
import { ExpenseAnalysisConfig } from "./editExpenseAnalysisConfig";
import { CancelTokenSource } from "axios";

export type ExpenseAnalysisCompCount = {
  compCount: number;
};

export const getExpenseAnalysisCompCount = async (
  propertyId: string,
  request: ExpenseAnalysisConfig,
  compId: string,
  cancelTokenSource: CancelTokenSource
): Promise<ExpenseAnalysisCompCount> => {
  const response = await apiClient.post(
    `/property/${propertyId}/analysis/expense/count?compId=${compId}`,
    request,
    { cancelToken: cancelTokenSource.token }
  );

  return response.data;
};
