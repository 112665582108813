import React, { ReactNode, useEffect } from "react";
import { ModalComponent } from "components/ModalComponent";
import { Box, Typography, useTheme } from "@mui/material";
import { CustomLoader } from "components/CustomLoader";

type Props = {
  title: string;
  titleStyles?: React.CSSProperties;
  subtitle?: string | ReactNode;
  subtitleStyles?: React.CSSProperties;
  icon?: string;
  open: boolean;
  handleClose: () => void;
  isLoading?: boolean;
  autoClosingTime?: number;
};

export const SuccessModal = ({
  title,
  subtitle,
  icon,
  open,
  handleClose,
  isLoading,
  autoClosingTime = 3000,
  titleStyles,
  subtitleStyles,
}: Props) => {
  const theme = useTheme();

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        handleClose();
      }, autoClosingTime);
      return () => {
        clearTimeout(timer);
      };
    }
    return;
  }, [open]);

  return (
    <ModalComponent
      open={open}
      handleClose={handleClose}
      closable={!isLoading}
      boxStyles={{
        width: "460px",
        height: "275px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {isLoading ? (
        <CustomLoader
          styles={{
            color: "#E3E3E3",
            opacity: 0.6,
          }}
          boxStyles={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            position: "relative",
            top: "50%",
            transform: "translate(0, -50%)",
          }}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: "45px",
          }}
        >
          <img
            src={icon}
            alt="success"
            style={{
              marginBottom: "30px",
            }}
          />

          <Typography
            variant="h4"
            sx={{
              textAlign: "center",
              marginBottom: "5px",
              maxWidth: "300px",
              ...titleStyles,
            }}
          >
            {title}
          </Typography>
          <Typography variant="h5" sx={{ ...subtitleStyles }}>
            {subtitle}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          width: "100%",
          height: "8px",
          backgroundColor: theme.palette?.secondary.main,
        }}
      />
    </ModalComponent>
  );
};
