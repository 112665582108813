import { apiClient } from "lib/apiClient";

export const deductCompCoins = async <T>(
  compId: string,
  compType: string
): Promise<T> => {
  const response = await apiClient.post(`/purchases/comps/${compType}/${compId}
  `);

  return response.data;
};
