import { ICompanyGroup } from "./getCompanyGroups";
import { apiClient } from "lib/apiClient";
import { IEditCompanyGroup } from "features/CompanyGroups/interfaces";

export const editCompanyGroup = async (
  request: IEditCompanyGroup
): Promise<ICompanyGroup> => {
  const response = await apiClient.put(`/companies`, request);

  return response.data;
};
