import { Box } from "@mui/material";
import React from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./BurgerMenu.module.scss";
import { NavItems } from "../NavItems";

type Props = {
  open: boolean;
  handleMenuOpen: () => void;
  handleComponentLoad: () => void;
  className?: string;
};

export const BurgerMenu = ({
  open,
  handleMenuOpen,
  handleComponentLoad,
  className,
}: Props) => {
  return (
    <>
      <IconButton
        size="large"
        color="inherit"
        aria-label={open ? "close menu" : "open menu"}
        className={className}
        onClick={open ? handleComponentLoad : handleMenuOpen}
      >
        {open ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
      <Box
        component="nav"
        aria-label="mobile navigation"
        className={[styles.mobileNav, open && styles.mobileNavActive].join(" ")}
      >
        <NavItems className={styles.link} />
      </Box>
    </>
  );
};
