import { apiClient } from "lib/apiClient";
import {
  IPropertyExpenseComps,
  IPropertyRentComps,
  IPropertySaleComps,
} from "./getAllCompsByPropertyIds";

export type GroupedCompType<T> = {
  parentComp: T | null;
  memberComps?: T[];
  comps?: T[];
};

export type GroupedPropertyComps = {
  sale: GroupedCompType<IPropertySaleComps>[];
  rent: GroupedCompType<IPropertyRentComps>[];
  expense: GroupedCompType<IPropertyExpenseComps>[];
};

export const getGroupedCompsByPropertyIds = async (
  propertyId: string
): Promise<GroupedPropertyComps> => {
  const response = await apiClient.get(
    `/comps/grouped?properties=${propertyId}`
  );

  return response.data;
};
