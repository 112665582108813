import { apiClient } from "lib/apiClient";

export interface IEditStatus {
  compStatus: string;
}
export const editSaleCompStatus = async (
  compType: string,
  compId: string,
  compStatus: string,
  coins?: number
): Promise<IEditStatus> => {
  const response = await apiClient.post(
    `/reviews/comps/${compType}/${compId}`,
    {
      compStatus,
      coins,
    }
  );

  return response.data;
};
