import { apiClient } from "lib/apiClient";

export type UploadCompErrorType = {
  message: string;
};

type Response = {
  validationErrors: UploadCompErrorType[];
  state: "SUCCESS" | "ERROR";
  savedComps: number;
};

export const uploadXlsxFile = async (
  userId: string,
  file: FormData
): Promise<Response> => {
  const response = await apiClient.post(`/comps/upload/user/${userId}`, file);

  return response.data;
};
