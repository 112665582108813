import { ICreateSaleComp } from "pages/Comps/components/CreateSaleComp/interfaces";
import { apiClient } from "lib/apiClient";

type Response = {
  name: string;
  ownerRelatedStatus: string;
  closingDate: string;
  pricePerUnit: number;
  properties: string[];
  participantData: string;
  verificationSources: string[];
  participantCompanyName: string;
  participantName: string;
  participantPhone: string;
  participantEmail: string;
  sellerLegalName: string;
  sellerTrueName: string;
  sellerProfitType: string;
  buyerLegalName: string;
  buyerTrueName: string;
  buyerProfitType: string;
  id: string;
};

export const addSaleComp = async (
  request: ICreateSaleComp,
  originalCompId: string | null
): Promise<Response> => {
  const response = await apiClient.post(
    `/comps/sale${originalCompId ? `?originalCompId=${originalCompId}` : ""}`,
    request
  );

  return response.data;
};
