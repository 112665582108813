export type Routes = {
  public: {
    [key: string]: string;
  };
  private: {
    [key: string]: string;
  };
};

export const routes: Routes = {
  public: {
    terms: "/terms",
    policy: "/policy",
    login: "/login",
    signIn: "/login/signIn",
    reset: "/login/reset",
  },
  private: {
    companyGroups: "/companyGroups",
    viewCompanyGroup: "/companyGroups/view/:id",
    viewUser: "/companyGroups/user/:id",
    properties: "/properties",
    viewProperties: "/properties/view/:id",
    viewSaleComp: "/properties/sale-comp/view/:id",
    viewRentComp: "/properties/rent-comp/view/:id",
    viewExpenseComp: "/properties/expense-comp/view/:id",
    mergeSaleComp: "/merge/sale-comp/:id",
    mergeRentComp: "/merge/rent-comp/:id",
    mergeExpenseComp: "/merge/expense-comp/:id",
    comps: "/comps",
    settings: "/settings",
    myDeals: "/myDeals",
    emailActivation: "/email-activation",
    newProperty: "/new-property",
    templates: "/templates",
  },
};
