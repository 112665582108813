import { IUploadedFiles } from "components/UploadMultipleFiles/interfaces";

const getFileExtension = (filename: string) => {
  return filename
    .slice((Math.max(0, filename.lastIndexOf(".")) || Infinity) + 1)
    .toLowerCase();
};

export const validateFiles = (
  files: IUploadedFiles[],
  fileExtensions: string[],
  supportedFilesExtensions: string[],
  fileNames?: string[]
) => {
  const formatValidatedFiles = files.map((file) => {
    if (
      fileNames?.length &&
      !fileNames.some((name) => file.name.startsWith(name))
    ) {
      file.errorText = "Upload failed: Incorrect file name";
    }

    const fileExtFromName = getFileExtension(file.name);
    const isValidType = fileExtensions.includes(file.type);
    const isValidExt = supportedFilesExtensions.includes(
      fileExtFromName.toLowerCase()
    );

    if (isValidExt || isValidType) {
      return file;
    } else {
      file.errorText = "Upload failed: Incorrect file format";
      return file;
    }
  });
  return formatValidatedFiles;
};
