import { IRequestParams } from "pages/Properties/components/PropertiesFilter/PropertiesFilter";
import { editExpenseComp, IEditExpenseComp } from "api/editExpenseComp";
import { editRentComp, IEditRentComp } from "api/editRentComp";
import { addRentComp } from "api/addRentComp";
import { ICreateRentComp } from "pages/Comps/components/RentCompModal/interfaces";
import { getAllAttachments } from "api/getAllAttachments";
import { editSaleComp, IEditSaleComp } from "api/editSaleComp";
import { RootState } from "store/rootReducer";
import { searchByPropertyName } from "api/searchByPropertyName";
import {
  CompInfoType,
  CompLoadingPayload,
  ISearchedPropertyOptions,
  RentCompInfoType,
  ExpenseCompInfoType,
  CompTableData,
  ICompPrice,
  CompTypesAttachments,
  ICompTypes,
  CompTypesRequestedProperties,
  CompCancelToken,
  COMP_ACTIONS_KEYS,
  ICompFormState,
  ICompFormUpdateState,
  PricingAnalysisAllData,
  ICompsGroup,
  TSetReviewedCompData,
} from "./interfaces";
import { addCompFiles } from "api/addCompFiles";
import {
  fetchPropertyDetails,
  fetchPropertyPhotoById,
  fetchPropertiesComps,
} from "features/Property/PropertyActions";
import { TypedDispatch } from "store/index";
import { addSaleComp } from "api/addSaleComp";
import { ICreateSaleComp } from "pages/Comps/components/CreateSaleComp/interfaces";
import { getPropertyById } from "api/getPropertyById";
import { editSaleCompStatus } from "api/editSaleCompStatus";
import { deleteCompAttachments } from "api/deleteCompAttachments";
import { addExpenseComp } from "api/addExpenseComp";
import { ICreateExpenseComp } from "pages/Comps/components/ExpenseCompModal/interfaces";
import { getCompsTableData } from "api/getCompsTableData";
import { handleCompExport } from "utils/handleCompExport";
import { getReviewedComps } from "api/getReviewedComps";
import {
  getCompInfo,
  IDetailedExpenseCompInfo,
  IDetailedRentCompInfo,
  IDetailedSaleCompInfo,
} from "api/getCompInfo";
import { getCompsTableCount } from "api/getCompsTableCount";
import { getCompPrice } from "api/getCompPrice";
import { addCompPrice } from "api/addCompPrice";
import { setSuccessModalOpen as setSuccessSignInModalOpen } from "features/SignIn/SignInActions";
import { deleteComp } from "api/deleteComp";
import { ISortProperty } from "features/Property/interfaces";
import { RentUsavedValuesType } from "pages/Comps/components/RentCompModal/constants";
import axios from "axios";
import {
  getPricingAnalysisConfig,
  PricingAnalysisConfigData,
} from "api/getPricingAnalysisConfig";
import { getDefaultPricingAnalysisConfig } from "api/getDefaultPricingAnalysisConfig";
import { getPricingAnalysisAllData } from "api/getPticingAnalysisAllData";
import { ExpenseUnsavedValuesType } from "pages/Comps/components/ExpenseCompModal/constants/validationSchema";
import { getExpenseAnalysisCompCount } from "api/getExpenseAnalysisCompCount";
import { ExpenseAnalysisConfig } from "api/editExpenseAnalysisConfig";
import { getExpenseAnalysisConfig } from "api/getExpenseAnalysisConfig";
import {
  ExpenseAnalysisData,
  getExpenseAnalysisChart,
} from "api/getExpenseAnalysisChart";
import { FieldsUnsavedValuesType } from "pages/Comps/components/CreateSaleComp/constants";
import { handleExportMyDealsComps } from "utils/handleExportMyDealsComps";
import { IExportMyDealsCompsRequestParams } from "api/exportMyDealsComps";

export const SET_COMP_TYPE = "SET_COMP_TYPE";
export const SET_COMP_SUB_TYPE = "SET_COMP_SUB_TYPE";
export const SET_COMP_PROPERTIES = "SET_COMP_PROPERTIES";
export const SET_OPEN_COMP_CREATE = "SET_OPEN_COMP_CREATE";
export const SET_CREATE_COMP_FORM_STATUS = "SET_CREATE_COMP_FORM_STATUS";
export const SET_EDIT_COMP_FORM_STATUS = "SET_EDIT_COMP_FORM_STATUS";
export const SET_COPY_COMP_FORM_STATUS = "SET_COPY_COMP_FORM_STATUS";
export const SET_COMP_FORM_VALUES = "SET_COMP_FORM_VALUES";
export const SET_SALE_COMP_OPTIONS = "SET_SALE_COMP_OPTIONS";
export const SET_COMP_INFO = "SET_COMP_INFO";
export const SET_REQUESTED_COMP_PROPERTIES = "SET_REQUESTED_COMP_PROPERTIES";
export const SET_COMP_ATTACHMENTS = "SET_COMP_ATTACHMENTS";
export const SET_SUCCESS_COMP_OPEN = "SET_SUCCESS_COMP_OPEN";
export const SET_COMP_LOADING = "SET_COMP_LOADING";
export const SET_TABLE_COMPS_DATA = "SET_TABLE_COMPS_DATA";
export const SET_REVIEWED_COMP_DATA = "SET_REVIEWED_COMP_DATA";
export const SET_SUCCESS_MODAL_MESSAGE = "SET_SUCCESS_MODAL_MESSAGE";
export const SET_COMP_PRICE = "SET_COMP_PRICE";
export const SET_SORT_COMP = "SET_SORT_COMP";
export const SET_COMP_CANCEL_TOKEN = "SET_COMP_CANCEL_TOKEN";
export const SET_COMP_PRICING_ANALYSIS = "SET_COMP_PRICING_ANALYSIS";
export const SET_COMP_PRICING_ANALYSIS_CONFIG =
  "SET_COMP_PRICING_ANALYSIS_CONFIG";
export const SET_EXPENSE_ANALYSIS_COMP_COUNT =
  "SET_EXPENSE_ANALYSIS_COMP_COUNT";
export const SET_EXPENSE_ANALYSIS_CONFIG = "SET_EXPENSE_ANALYSIS_CONFIG";
export const SET_EXPENSE_ANALYSIS_DATA = "SET_EXPENSE_ANALYSIS_DATA";
export const SET_ADJUSTMENT_MODE = "SET_ADJUSTMENT_MODE";

export const setCompType = (value: string) => ({
  type: SET_COMP_TYPE,
  payload: value,
});

export const setCompSubType = (value: string) => ({
  type: SET_COMP_SUB_TYPE,
  payload: value,
});

export const setSaleCompOptions = (value: ISearchedPropertyOptions[]) => ({
  type: SET_SALE_COMP_OPTIONS,
  payload: value,
});

export const setCompProperties = (value: ISearchedPropertyOptions[]) => ({
  type: SET_COMP_PROPERTIES,
  payload: value,
});

export const setRequestCompProperties = (
  value: CompTypesRequestedProperties
) => ({
  type: SET_REQUESTED_COMP_PROPERTIES,
  payload: value,
});

export const setOpenCompModal = (value: boolean) => ({
  type: SET_OPEN_COMP_CREATE,
  payload: value,
});

export const setCreateCompFormStatus = (value: ICompFormState) => ({
  type: SET_CREATE_COMP_FORM_STATUS,
  payload: value,
});

export const setEditCompFormStatus = (value: ICompFormUpdateState) => ({
  type: SET_EDIT_COMP_FORM_STATUS,
  payload: value,
});

export const setCopyCompFormStatus = (value: ICompFormUpdateState) => ({
  type: SET_COPY_COMP_FORM_STATUS,
  payload: value,
});

export const setCompFormValues = (
  value:
    | RentUsavedValuesType
    | FieldsUnsavedValuesType
    | ExpenseUnsavedValuesType
    | null
) => ({
  type: SET_COMP_FORM_VALUES,
  payload: value,
});

export const setCompInfo = (value: {
  [key: string]: CompInfoType | RentCompInfoType | ExpenseCompInfoType;
}) => ({
  type: SET_COMP_INFO,
  payload: value,
});

export const setCompAttachments = (value: CompTypesAttachments) => ({
  type: SET_COMP_ATTACHMENTS,
  payload: value,
});

export const setSuccessModalOpen = (payload: boolean) => ({
  type: SET_SUCCESS_COMP_OPEN,
  payload,
});

export const setCompLoading = (payload: CompLoadingPayload) => ({
  type: SET_COMP_LOADING,
  payload,
});

export const setTableCompsData = (payload: CompTableData) => ({
  type: SET_TABLE_COMPS_DATA,
  payload,
});

export const setReviewedCompData = (value: TSetReviewedCompData) => ({
  type: SET_REVIEWED_COMP_DATA,
  payload: value,
});

export const setSuccessModalMessage = (value: string) => ({
  type: SET_SUCCESS_MODAL_MESSAGE,
  payload: value,
});

export const setCompPrice = (value: ICompPrice) => ({
  type: SET_COMP_PRICE,
  payload: value,
});

export const setSortComp = (value: ISortProperty) => ({
  type: SET_SORT_COMP,
  payload: value,
});

export const setCompCancelToken = (value: CompCancelToken) => ({
  type: SET_COMP_CANCEL_TOKEN,
  payload: value,
});

export const setCompPricingAnalysis = (value: PricingAnalysisAllData) => ({
  type: SET_COMP_PRICING_ANALYSIS,
  payload: value,
});

export const setCompPricingAnalysisConfig = (
  value: PricingAnalysisConfigData[]
) => ({
  type: SET_COMP_PRICING_ANALYSIS_CONFIG,
  payload: value,
});

export const setExpenseAnalysisCompCount = (value: number | null) => ({
  type: SET_EXPENSE_ANALYSIS_COMP_COUNT,
  payload: value,
});

export const setExpenseAnalysisConfig = (
  value: ExpenseAnalysisConfig | null
) => ({
  type: SET_EXPENSE_ANALYSIS_CONFIG,
  payload: value,
});

export const setExpenseAnalysisData = (value: ExpenseAnalysisData | null) => ({
  type: SET_EXPENSE_ANALYSIS_DATA,
  payload: value,
});

export const setAdjustmentMode = (value: boolean) => ({
  type: SET_ADJUSTMENT_MODE,
  payload: value,
});

export const addFiles = async (
  files: FormData[],
  compType: string,
  id: string
) => {
  files.forEach(async (file) => {
    try {
      await addCompFiles(compType, id, file);
    } catch (error) {
      console.log(error);
    }
  });
};

export const createSaleComp =
  (
    propertyData: ICreateSaleComp,
    propertyId: string,
    files: FormData[],
    compType: string,
    originalCompId: string | null,
    closeModal?: () => void
  ) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setCompLoading({ sale: true }));
    try {
      const response = await addSaleComp(propertyData, originalCompId);
      if (files.length) {
        addFiles(files, compType, response.id);
      }
      dispatch(setCompLoading({ sale: false }));
      closeModal?.();
      await dispatch(fetchPropertiesComps(propertyId));
    } catch (error) {
      dispatch(setCompLoading({ sale: false }));
    }
  };

export const addSaleCompOptions =
  (name: string) => async (dispatch: TypedDispatch) => {
    dispatch(setCompLoading({ propertiesOptions: true }));
    try {
      if (name) {
        const response = await searchByPropertyName(name);
        const result = response.map((item) => {
          return {
            ...item,
            isDefault: false,
          };
        });
        dispatch(setSaleCompOptions(result));
        dispatch(setCompLoading({ propertiesOptions: false }));
      } else {
        dispatch(setSaleCompOptions([]));
        dispatch(setCompLoading({ propertiesOptions: false }));
      }
    } catch (error) {
      dispatch(setCompLoading({ propertiesOptions: false }));
    }
  };

export const fetchCompInfo =
  (id: string, compType: ICompTypes, propertyId: string) =>
  async (dispatch: TypedDispatch, getState: () => RootState) => {
    const compTypeKey = COMP_ACTIONS_KEYS[compType];
    dispatch(setCompLoading({ [compType]: true }));
    try {
      const { CompReducer } = getState();
      const { isLoading, cancelToken } = CompReducer;
      if (isLoading[compType] && cancelToken[compTypeKey.compInfo]) {
        cancelToken[compTypeKey.compInfo]?.cancel();
      }
      const cancelTokenSource = axios.CancelToken.source();
      dispatch(
        setCompCancelToken({ [compTypeKey.compInfo]: cancelTokenSource })
      );
      const compInfo = await getCompInfo(
        id,
        compType,
        propertyId,
        cancelTokenSource
      );
      await dispatch(fetchAllAttachments(id, compType));
      if (compType === "sale") {
        const request = (compInfo as IDetailedSaleCompInfo).properties.map(
          (item) => {
            return getPropertyById(item.id);
          }
        );
        await Promise.all(request).then((result) => {
          const resultsToSet = result.map((item, index) => {
            const isDefault = (compInfo as IDetailedSaleCompInfo)
              .defaultProperty
              ? (compInfo as IDetailedSaleCompInfo).defaultProperty ===
                item.propertyId
              : index === 0;
            return {
              ...item,
              isDefault,
            };
          });
          dispatch(setRequestCompProperties({ [compType]: resultsToSet }));
        });
      } else {
        const property = getPropertyById(
          (compInfo as IDetailedRentCompInfo | IDetailedExpenseCompInfo)
            ?.propertyId
        );
        Promise.resolve(property).then((result) => {
          dispatch(setRequestCompProperties({ [compType]: [result] }));
        });
      }
      dispatch(setCompLoading({ [compType]: false }));
      dispatch(setCompInfo({ [compType]: compInfo }));
    } catch (error) {
      !axios.isCancel(error) && dispatch(setCompLoading({ [compType]: false }));
    }
  };

export const editSaleStatus =
  (compId: string, compStatus: string, coins?: number) =>
  async (dispatch: TypedDispatch, getState: () => RootState) => {
    try {
      const { CompReducer } = getState();
      const { sale: saleCompInfo } = CompReducer.compInfo;
      await editSaleCompStatus("sale", compId, compStatus, coins);
      const updatedStatus = {
        ...saleCompInfo,
        ownerRelatedStatus: compStatus,
      };
      dispatch(setCompInfo({ sale: updatedStatus }));
    } catch (error) {
      console.log(error);
    }
  };

export const fetchAllAttachments =
  (compId: string, compType: ICompTypes) =>
  async (dispatch: TypedDispatch, getState: () => RootState) => {
    const compTypeKey = COMP_ACTIONS_KEYS[compType];
    dispatch(setCompLoading({ [`${compType}CompAttachments`]: true }));
    try {
      const { CompReducer } = getState();
      const { cancelToken } = CompReducer;
      if (cancelToken[compTypeKey.compAttachments]) {
        cancelToken[compTypeKey.compAttachments]?.cancel();
      }
      const cancelTokenSource = axios.CancelToken.source();
      dispatch(
        setCompCancelToken({ [compTypeKey.compAttachments]: cancelTokenSource })
      );
      const attachments = await getAllAttachments(
        compId,
        compType,
        cancelTokenSource
      );
      dispatch(setCompAttachments({ [compType]: attachments }));
      dispatch(setCompLoading({ [`${compType}CompAttachments`]: false }));
    } catch (error) {
      dispatch(setCompLoading({ [`${compType}CompAttachments`]: false }));
    }
  };

export const changeSaleComp =
  (
    editComp: IEditSaleComp,
    attachmentsToDelete?: string[],
    files?: FormData[],
    closeModal?: () => void,
    handleOpenSuccess?: () => void
  ) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setCompLoading({ sale: true }));
    try {
      await editSaleComp(editComp);
      if (attachmentsToDelete && attachmentsToDelete.length) {
        const requests = attachmentsToDelete.map((id) =>
          deleteCompAttachments(id)
        );
        await Promise.all(requests);
      }
      if (files && files.length) {
        const createAttachmentRequests = files.map((item) => {
          return addCompFiles("sale", editComp.id, item);
        });
        await Promise.all(createAttachmentRequests);
      }
      dispatch(setCompLoading({ sale: false }));
      closeModal?.();
      handleOpenSuccess?.();
    } catch (error) {
      dispatch(setCompLoading({ sale: false }));
    }
  };

export const createRentComp =
  (
    propertyData: ICreateRentComp,
    propertyId: string,
    closeModal: () => void,
    files: FormData[],
    compType: string,
    originalCompId: string | null
  ) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setCompLoading({ rent: true }));
    try {
      const response = await addRentComp(propertyData, originalCompId);
      if (files.length) {
        await addFiles(files, compType, response.id);
      }
      dispatch(setCompLoading({ rent: false }));
      dispatch(fetchPropertiesComps(propertyId));
      closeModal();
    } catch (error) {
      dispatch(setCompLoading({ rent: false }));
    }
  };

export const editRentStatus =
  (compId: string, compStatus: string) =>
  async (dispatch: TypedDispatch, getState: () => RootState) => {
    try {
      const { CompReducer } = getState();
      const { rent: rentCompInfo } = CompReducer.compInfo;
      await editSaleCompStatus("rent", compId, compStatus);
      const updatedStatus = {
        ...rentCompInfo,
        ownerRelatedStatus: compStatus,
      };
      dispatch(setCompInfo({ rent: updatedStatus }));
    } catch (error) {
      console.log(error);
    }
  };

export const changeRentComp =
  (
    editComp: IEditRentComp | RentCompInfoType,
    attachmentsToDelete: string[],
    files: FormData[],
    closeModal: () => void,
    handleOpenSuccess?: () => void
  ) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setCompLoading({ rent: true }));
    try {
      await editRentComp(editComp);
      if (attachmentsToDelete.length) {
        const requests = attachmentsToDelete.map((id) =>
          deleteCompAttachments(id)
        );
        await Promise.all(requests);
      }
      if (files.length) {
        const createAttachmentRequests = files.map((item) => {
          return addCompFiles("rent", editComp.id, item);
        });
        await Promise.all(createAttachmentRequests);
      }
      dispatch(setCompLoading({ rent: false }));
      closeModal();
      handleOpenSuccess?.();
    } catch (error) {
      dispatch(setCompLoading({ rent: false }));
    }
  };

export const createExpenseComp =
  (
    propertyData: ICreateExpenseComp,
    propertyId: string,
    closeModal: () => void,
    files: FormData[],
    compType: string,
    originalCompId: string | null
  ) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setCompLoading({ expense: true }));
    try {
      const response = await addExpenseComp(propertyData, originalCompId);
      if (files.length) {
        await addFiles(files, compType, response.id);
      }
      dispatch(setCompLoading({ expense: false }));
      closeModal();
      await dispatch(fetchPropertiesComps(propertyId));
    } catch (error) {
      dispatch(setCompLoading({ expense: false }));
    }
  };

export const changeExpenseComp =
  (
    editComp: IEditExpenseComp,
    attachmentsToDelete: string[],
    files: FormData[],
    closeModal: () => void,
    handleOpenSuccess?: () => void
  ) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setCompLoading({ expense: true }));
    try {
      await editExpenseComp(editComp);
      if (attachmentsToDelete.length) {
        const requests = attachmentsToDelete.map((id) =>
          deleteCompAttachments(id)
        );
        await Promise.all(requests);
      }
      if (files.length) {
        const createAttachmentRequests = files.map((item) => {
          return addCompFiles("expense", editComp.id, item);
        });
        await Promise.all(createAttachmentRequests);
      }
      dispatch(setCompLoading({ expense: false }));
      closeModal();
      handleOpenSuccess?.();
    } catch (error) {
      dispatch(setCompLoading({ expense: false }));
    }
  };

export const editExpenseStatus =
  (compId: string, compStatus: string) =>
  async (dispatch: TypedDispatch, getState: () => RootState) => {
    try {
      const { CompReducer } = getState();
      const { expense: expenseCompInfo } = CompReducer.compInfo;
      await editSaleCompStatus("expense", compId, compStatus);
      const updatedStatus = {
        ...expenseCompInfo,
        ownerRelatedStatus: compStatus,
      };
      dispatch(setCompInfo({ expense: updatedStatus }));
    } catch (error) {
      console.log(error);
    }
  };

export const fetchCompsTableInfo =
  (
    page: number,
    pageSize: number,
    compType: ICompTypes,
    requestParams: IRequestParams,
    isSeniorComp: boolean,
    sortCompParam?: ISortProperty
  ) =>
  async (dispatch: TypedDispatch, getState: () => RootState) => {
    const compTypeKey = COMP_ACTIONS_KEYS[compType];
    dispatch(setCompLoading({ [compType]: true }));
    try {
      const { CompReducer } = getState();
      const { tableCompsData, isLoading, cancelToken } = CompReducer;
      if (isLoading[compType] && cancelToken[compTypeKey.compTableInfo]) {
        cancelToken[compTypeKey.compTableInfo]?.cancel();
      }
      const cancelTokenSource = axios.CancelToken.source();
      dispatch(
        setCompCancelToken({ [compTypeKey.compTableInfo]: cancelTokenSource })
      );
      const response = await getCompsTableData(
        page,
        pageSize,
        compType,
        requestParams,
        isSeniorComp,
        cancelTokenSource,
        sortCompParam
      );
      dispatch(
        setTableCompsData({
          [compType]: {
            ...tableCompsData[compType],
            info: response,
          },
        })
      );
      dispatch(setCompLoading({ [compType]: false }));
    } catch (error) {
      !axios.isCancel(error) && dispatch(setCompLoading({ [compType]: false }));
    }
  };

export const fetchCompsTableCount =
  (compType: ICompTypes, requestParams: IRequestParams) =>
  async (dispatch: TypedDispatch, getState: () => RootState) => {
    const compTypeKey = COMP_ACTIONS_KEYS[compType];
    dispatch(setCompLoading({ [compType]: true }));
    try {
      const { CompReducer } = getState();
      const { tableCompsData, isLoading, cancelToken } = CompReducer;
      if (isLoading[compType] && cancelToken[compTypeKey.compTableCount]) {
        cancelToken[compTypeKey.compTableCount]?.cancel();
      }
      const cancelTokenSource = axios.CancelToken.source();
      dispatch(
        setCompCancelToken({
          [compTypeKey.compTableCount]: cancelTokenSource,
        })
      );
      const response = await getCompsTableCount(
        compType,
        requestParams,
        cancelTokenSource
      );
      dispatch(
        setTableCompsData({
          [compType]: {
            ...tableCompsData[compType],
            count: response,
          },
        })
      );
      dispatch(setCompLoading({ [compType]: false }));
    } catch (error) {
      !axios.isCancel(error) && dispatch(setCompLoading({ [compType]: false }));
    }
  };

export const exportMultipleComps =
  (compType: string, compIds: string[], propertyComps?: boolean) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setCompLoading({ compsExport: true }));
    try {
      await handleCompExport(compType, compIds, "", "", propertyComps);
      dispatch(setCompLoading({ compsExport: false }));
    } catch (error) {
      dispatch(setCompLoading({ compsExport: false }));
    }
  };

export const fetchReviewedCompsData =
  (compType: ICompTypes | "comps", compId: string, propertyId: string) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setCompLoading({ [compType]: true }));
    try {
      const response = await getReviewedComps(compType, compId, propertyId);

      const comps = response.comps as
        | ICompsGroup<CompInfoType>[]
        | ICompsGroup<RentCompInfoType>[]
        | ICompsGroup<ExpenseCompInfoType>[];

      dispatch(fetchPropertyDetails(propertyId));
      dispatch(fetchPropertyPhotoById(propertyId));

      const reviewedCompData: TSetReviewedCompData = {
        [compType]: comps,
        membership: response.membership,
      };

      dispatch(setReviewedCompData(reviewedCompData));

      dispatch(setCompLoading({ [compType]: false }));
    } catch (error) {
      console.error("Error fetching reviewed comps data:", error);
      dispatch(setCompLoading({ [compType]: false }));
    }
  };

export const fetchCompPrice =
  () => async (dispatch: TypedDispatch, getState: () => RootState) => {
    try {
      const { CompReducer } = getState();
      const { cancelToken } = CompReducer;
      if (cancelToken.compPrice) {
        cancelToken.compPrice.cancel();
      }
      const cancelTokenSource = axios.CancelToken.source();
      dispatch(setCompCancelToken({ compPrice: cancelTokenSource }));
      const response = await getCompPrice();
      dispatch(setCompPrice(response));
    } catch (error) {
      console.log(error);
    }
  };

export const changeCompPrice =
  (newPrice: ICompPrice) => async (dispatch: TypedDispatch) => {
    dispatch(setCompLoading({ compPrice: true }));
    try {
      await addCompPrice(newPrice);
      dispatch(setCompPrice(newPrice));
      dispatch(setCompLoading({ compPrice: false }));
      dispatch(setSuccessSignInModalOpen(true));
    } catch (error) {
      dispatch(setCompLoading({ compPrice: false }));
    }
  };

export const deleteSelectedComp =
  (comptype: string, compId: string, propertyId: string) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setCompLoading({ [comptype]: true }));
    try {
      await deleteComp(compId, comptype, propertyId);
      dispatch(setCompLoading({ [comptype]: false }));
    } catch (error) {
      dispatch(setCompLoading({ [comptype]: false }));
    }
  };

export const manageCompFiles =
  (
    compType: ICompTypes,
    id: string,
    files: FormData[],
    attachmentsToDelete: string[]
  ) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setCompLoading({ [`${compType}CompAttachments`]: true }));
    try {
      if (attachmentsToDelete.length) {
        const requests = attachmentsToDelete.map((id) =>
          deleteCompAttachments(id)
        );
        await Promise.all(requests);
      }
      if (files.length) {
        const createAttachmentRequests = files.map((item) =>
          addCompFiles(compType, id, item)
        );
        await Promise.all(createAttachmentRequests);
      }
      await dispatch(fetchAllAttachments(id, compType));
      dispatch(setCompLoading({ [`${compType}CompAttachments`]: false }));
    } catch (error) {
      dispatch(setCompLoading({ [`${compType}CompAttachments`]: false }));
    }
  };

export const fetchPricingAnalysisData =
  (propertyId: string, isAdjusted: boolean) =>
  async (dispatch: TypedDispatch, getState: () => RootState) => {
    dispatch(setCompLoading({ pricingAnalysis: true }));
    try {
      const { CompReducer } = getState();
      const { isLoading, cancelToken } = CompReducer;
      if (isLoading.pricingAnalysis && cancelToken.pricingAnalysis) {
        cancelToken.pricingAnalysis.cancel();
      }
      const cancelTokenSource = axios.CancelToken.source();
      dispatch(setCompCancelToken({ pricingAnalysis: cancelTokenSource }));
      const response = await getPricingAnalysisAllData(
        propertyId,
        cancelTokenSource,
        isAdjusted
      );
      dispatch(setCompPricingAnalysis(response));
      dispatch(setCompLoading({ pricingAnalysis: false }));
    } catch (error) {
      !axios.isCancel(error) &&
        dispatch(setCompLoading({ pricingAnalysis: false }));
    }
  };

export const fetchPricingAnalysisConfig =
  (propertyId: string, isDefaultConfig?: boolean) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setCompLoading({ pricingAnalysisConfig: true }));
    try {
      const response = isDefaultConfig
        ? await getDefaultPricingAnalysisConfig(propertyId)
        : await getPricingAnalysisConfig(propertyId);

      dispatch(setCompPricingAnalysisConfig(response));
      dispatch(setCompLoading({ pricingAnalysisConfig: false }));
    } catch (error) {
      dispatch(setCompLoading({ pricingAnalysisConfig: false }));
    }
  };

export const fetchExpenseAnalysisCompCount =
  (propertyId: string, config: ExpenseAnalysisConfig, compId: string) =>
  async (dispatch: TypedDispatch, getState: () => RootState) => {
    dispatch(setCompLoading({ expenseAnalysisCompCount: true }));
    try {
      const { CompReducer } = getState();
      const { isLoading, cancelToken } = CompReducer;
      if (
        isLoading.expenseAnalysisCompCount &&
        cancelToken.expenseAnalysisCompCount
      ) {
        cancelToken.expenseAnalysisCompCount.cancel();
      }
      const cancelTokenSource = axios.CancelToken.source();
      dispatch(
        setCompCancelToken({ expenseAnalysisCompCount: cancelTokenSource })
      );
      const response = await getExpenseAnalysisCompCount(
        propertyId,
        config,
        compId,
        cancelTokenSource
      );
      dispatch(setExpenseAnalysisCompCount(response.compCount));
      dispatch(setCompLoading({ expenseAnalysisCompCount: false }));
    } catch (error) {
      !axios.isCancel(error) &&
        dispatch(setCompLoading({ expenseAnalysisCompCount: false }));
    }
  };

export const fetchExpenseAnalysisConfig =
  (propertyId: string) => async (dispatch: TypedDispatch) => {
    dispatch(setCompLoading({ expenseAnalysisConfig: true }));
    try {
      const response = await getExpenseAnalysisConfig(propertyId);
      dispatch(setExpenseAnalysisConfig(response));
      dispatch(setCompLoading({ expenseAnalysisConfig: false }));
    } catch (error) {
      dispatch(setCompLoading({ expenseAnalysisConfig: false }));
    }
  };

export const fetchExpenseAnalysisData =
  (propertyId: string, compId: string) =>
  async (dispatch: TypedDispatch, getState: () => RootState) => {
    dispatch(setCompLoading({ expenseAnalysisData: true }));
    try {
      const { CompReducer } = getState();
      const { isLoading, cancelToken } = CompReducer;
      if (isLoading.expenseAnalysisData && cancelToken.expenseAnalysisData) {
        cancelToken.expenseAnalysisData.cancel();
      }
      const cancelTokenSource = axios.CancelToken.source();
      dispatch(setCompCancelToken({ expenseAnalysisData: cancelTokenSource }));
      const response = await getExpenseAnalysisChart(
        propertyId,
        compId,
        cancelTokenSource
      );
      dispatch(setExpenseAnalysisData(response));
      dispatch(setCompLoading({ expenseAnalysisData: false }));
    } catch (error) {
      !axios.isCancel(error) &&
        dispatch(setCompLoading({ expenseAnalysisData: false }));
    }
  };

export const exportFiltredComps =
  (compType: string, requestParams: IExportMyDealsCompsRequestParams) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setCompLoading({ filtredMyDealsCompsExport: true }));
    try {
      await handleExportMyDealsComps(compType, requestParams);
      dispatch(setCompLoading({ filtredMyDealsCompsExport: false }));
    } catch (error) {
      dispatch(setCompLoading({ filtredMyDealsCompsExport: false }));
    }
  };
