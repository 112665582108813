import { useSelector } from "react-redux";
import * as signInSelectors from "store/selectors/signIn";
import { isAdmin } from "config/constants";
import React, { FC } from "react";
import { GroupedTemplates } from "./GroupedTemplates";
import { MemberTemplates } from "./MemberTemplates";
import { TSubscriptionPlan } from "features/Templates/types";

interface IMyTemplatesProps {
  handleUpdatePlans?: (id: string, subscriptions: TSubscriptionPlan[]) => void;
}

export const MyTemplates: FC<IMyTemplatesProps> = () => {
  const userInfo = useSelector(signInSelectors.userInfo);

  const isAdminUser = isAdmin(userInfo.roles);
  return isAdminUser ? <GroupedTemplates /> : <MemberTemplates />;
};
