import { routes } from "config/routes";
import React from "react";
import { NavLink, Link } from "react-router-dom";

import styles from "./NavItems.module.scss";

type Props = {
  className?: string;
};

export const NavItems = ({ className }: Props) => (
  <>
    <Link
      to={process.env.REACT_APP_HOME_LINK ?? ""}
      className={[className, styles.link].join(" ")}
    >
      Home
    </Link>
    <NavLink
      to={routes.public.login}
      className={({ isActive }) =>
        [
          className,
          styles.link,
          styles.loginLink,
          isActive && styles.activeLink,
        ].join(" ")
      }
    >
      Log In
    </NavLink>
  </>
);
