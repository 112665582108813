import { apiClient } from "lib/apiClient";

export type PricingAnalysisConfigData = {
  id: string;
  currentPropertyId: string;
  propertyId: string;
  compId: string;
  checked: boolean;
  propertyName: string;
  verificationDate: string;
  reason: string;
  compReason: string;
  createdByAdmin: boolean;
  permissions: string[];
  adjustmentValue: number | null;
};

export const getPricingAnalysisConfig = async (
  propertyId: string
): Promise<PricingAnalysisConfigData[]> => {
  const response = await apiClient.get(
    `/configuration/analysis/pricing/rent/config/${propertyId}`
  );

  return response.data;
};
