import { apiClient } from "lib/apiClient";

export const deductMultipleCompCoins = async <T>(
  comps: string[],
  compType: string
): Promise<T> => {
  const response = await apiClient.post(`/purchases/comps/${compType}`, comps);

  return response.data;
};
