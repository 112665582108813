import React from "react";
import ReactDOM from "react-dom/client";
import { enableMapSet } from "immer";
import { GlobalStyles } from "@mui/material";
import Helmet from "react-helmet";
import { ToastContainer } from "react-toastify";
import { globalStyles } from "config/styles/GlobalStyle";
import { PageRoot } from "pages/PageRoot";
import { Provider } from "react-redux";
import logo from "assets/svg/logo.svg";
import store from "./store";
import { Theme } from "config/styles/Theme";
import "react-toastify/ReactToastify.min.css";
import "./styles.css";

import * as Sentry from "@sentry/react";

import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

enableMapSet();

const appGlobalStyles = <GlobalStyles styles={globalStyles} />;

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay(),
  ],
  tracePropagationTargets: [
    "localhost",
    process.env.REACT_APP_BASE_URL as string,
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <Helmet
      title="SeniorComps"
      link={[
        {
          rel: "icon",
          type: "image/png",
          href: logo,
        },
      ]}
    />
    <Theme>
      {appGlobalStyles}
      <PageRoot />
    </Theme>
    <ToastContainer />
  </Provider>
);
