import { apiClient } from "lib/apiClient";

export interface IUser {
  active: boolean;
  authServiceId: string;
  creationDate: string;
  email: string;
  firstName: string;
  id: string;
  lastModifiedDate: string;
  lastLoginDate: string | null;
  lastName: string;
  phone: string;
  phonePrefix: string;
}

export type IUsers = {
  active: number;
  disabled: number;
  total: number;
  objects: IUser[];
};

export const getCompanyGroupUsers = async (
  id: string,
  status: string
): Promise<IUsers> => {
  const response = await apiClient.get(
    `/users?companyId=${id}&status=${status}`
  );

  return response.data;
};
