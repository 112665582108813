export const getIsAddButtonAvailable = (
  isAdminUser: boolean,
  tabKey: string
) => {
  if (isAdminUser && tabKey === "senior") {
    return true;
  }

  if (!isAdminUser && tabKey == "member") {
    return true;
  }

  return false;
};
