import { format as formatDate, isValid } from "date-fns";
import { timezoneUTC } from "config/constants";
import { utcToZonedTime } from "date-fns-tz";

export const resolveDate = (
  value: string | undefined | null | Date,
  format = "dd/MM/yyyy - HH:mm:ss"
) => {
  if (value) {
    const date = new Date(value);
    const zonedDate = utcToZonedTime(date, timezoneUTC);

    return isValid(date) ? formatDate(zonedDate, format) : `${date}`;
  }
  return "";
};
