import { states } from "config/states";

export const defineDefaultCounty = () => {
  return states.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.value]: [],
    };
  }, {});
};
