import { IPropertyDetails } from "./getPropertyById";
import { apiClient } from "lib/apiClient";

export const searchByPropertyName = async (
  name: string
): Promise<IPropertyDetails[]> => {
  const response = await apiClient.get(`/properties/preview?name=${name}`);

  return response.data;
};
