import { ISortProperty } from "features/Property/interfaces";
import { SavedFiltersType } from "pages/Properties/components/PropertiesFilter/PropertiesFilter";
import { ILocation } from "pages/Properties/interfaces";

const tokenKey = "token";
const authServiceId = "authServiceId";
const selectedFilters = "selectedFilters";
const mapZoomLevel = "mapZoomLevel";
const mapLocation = "mapLocation";
const sortPropertyParam = "sortPropertyParam";
const sortCompParam = "sortCompParam";
const mapBoundedMode = "mapBoundedMode";
const dealView = "dealView";
const currentDeal = "currentDeal";

export const localStorageManager = {
  getToken() {
    return localStorage.getItem(tokenKey);
  },

  getAuthServiceId() {
    return localStorage.getItem(authServiceId);
  },

  getSelectedFilters() {
    return localStorage.getItem(selectedFilters);
  },

  setToken(token: string) {
    localStorage.setItem(tokenKey, token);
  },

  setUserAuthServiceId(id: string) {
    localStorage.setItem(authServiceId, id);
  },

  setAppliedFilters(filters: SavedFiltersType) {
    localStorage.setItem(selectedFilters, JSON.stringify(filters));
  },

  clearSelectedFilters() {
    return localStorage.removeItem(selectedFilters);
  },

  clearStorage() {
    localStorage.clear();
  },

  setMapZoomLevel(zoom: number) {
    localStorage.setItem(mapZoomLevel, `${zoom}`);
  },

  getMapZoomLevel() {
    return localStorage.getItem(mapZoomLevel);
  },

  setMapCoordinates(coordinates: ILocation) {
    localStorage.setItem(mapLocation, JSON.stringify(coordinates));
  },

  getMapCoordinates() {
    return localStorage.getItem(mapLocation);
  },

  deleteZoom() {
    localStorage.removeItem(mapZoomLevel);
  },

  deleteMapLocation() {
    localStorage.removeItem(mapLocation);
  },

  getSortPropertyParam() {
    return localStorage.getItem(sortPropertyParam);
  },

  setSortPropertyParam(sortPropertyBy: ISortProperty) {
    localStorage.setItem(sortPropertyParam, JSON.stringify(sortPropertyBy));
  },

  clearSortPropertyParam() {
    localStorage.removeItem(sortPropertyParam);
  },

  getSortCompParam() {
    return localStorage.getItem(sortCompParam);
  },

  setSortCompParam(sortCompBy: ISortProperty) {
    localStorage.setItem(sortCompParam, JSON.stringify(sortCompBy));
  },

  clearSortCompParam() {
    localStorage.removeItem(sortCompParam);
  },

  setMapMode(isBoundedMode: boolean) {
    localStorage.setItem(mapBoundedMode, JSON.stringify(isBoundedMode));
  },

  getMapMode() {
    return localStorage.getItem(mapBoundedMode);
  },

  setDealView(isDealView: boolean) {
    localStorage.setItem(dealView, JSON.stringify(isDealView));
  },

  getDealView() {
    return localStorage.getItem(dealView);
  },

  setCurrentDeal(dealId: string, subjectId: string) {
    localStorage.setItem(currentDeal, JSON.stringify({ dealId, subjectId }));
  },

  getCurrentDeal() {
    return localStorage.getItem(currentDeal);
  },

  deleteCurrentDeal() {
    localStorage.removeItem(currentDeal);
  },
};
