import { apiClient } from "lib/apiClient";

type Response = {
  id: string;
  filename: string;
  creationDate: string;
  lastModifiedDate: string;
};
export const addCompFiles = async (
  compType: string,
  id: string,
  files: FormData
): Promise<Response> => {
  const response = await apiClient.post(
    `/comps/${compType}/${id}/attachments`,
    files
  );

  return response.data;
};
