import { CancelTokenSource } from "axios";
import { apiClient } from "lib/apiClient";

export interface IAttachments {
  id: string;
  compType: string;
  compId: string;
  notes: string;
  filename: string;
  creationDate: string;
  lastModifiedDate: string;
  fileSize: number;
  order: number;
  size: number;
}

export const getAllAttachments = async (
  compId: string,
  compType: string,
  cancelTokenSource: CancelTokenSource
): Promise<IAttachments[]> => {
  const response = await apiClient.get(
    `/comps/${compType}/${compId}/attachments`,
    { cancelToken: cancelTokenSource.token }
  );

  return response.data;
};
