import { CancelTokenSource } from "axios";
import { PricingAnalysisAllData } from "features/Comp/interfaces";
import { apiClient } from "lib/apiClient";

export const getPricingAnalysisAllData = async (
  propertyId: string,
  cancelTokenSource: CancelTokenSource,
  isAdjusted: boolean
): Promise<PricingAnalysisAllData> => {
  const response = await apiClient.get(
    `/property/${propertyId}/analysis/rent/all?isAdjusted=${isAdjusted}`,
    { cancelToken: cancelTokenSource.token }
  );

  return response.data;
};
