export const SET_DEMO_OPEN = "SET_DEMO_OPEN";
export const SET_SUCCESS_VISITORS_OPEN = "SET_SUCCESS_VISITORS_OPEN";
export const SET_RESET_EMAIL = "SET_RESET_EMAIL";

export const setDemoOpen = (value: boolean) => ({
  type: SET_DEMO_OPEN,
  payload: value,
});

export const setResetEmail = (value: string) => ({
  type: SET_RESET_EMAIL,
  payload: value,
});
