import { IDealSubjects } from "features/Deals/interfaces";
import { apiClient } from "lib/apiClient";

export interface IEditDealSubjects {
  propertyId: string;
  id: string;
  emails: string[];
}

export const editSubjectById = async (
  dealId: string,
  id: string,
  request: IEditDealSubjects
): Promise<IDealSubjects> => {
  const response = await apiClient.put(
    `/deals/${dealId}/subjects/${id}`,
    request
  );

  return response.data;
};
