import { IDealSubjects } from "features/Deals/interfaces";
import { apiClient } from "lib/apiClient";
import { ISelectedDealProperties } from "pages/MyDeals/components/DealPropertyModal/DealPropertyModal";

export const addSubjects = async (
  id: string,
  request: ISelectedDealProperties[]
): Promise<IDealSubjects[]> => {
  const response = await apiClient.post(`/deals/${id}/subjects`, request);

  return response.data;
};
