import { apiClient } from "lib/apiClient";

export const companyGroupActivation = async (
  id: string,
  activate: boolean
): Promise<void> => {
  const methodToUse = activate ? "post" : "delete";
  const response = await apiClient[methodToUse](`/companies/${id}/activation`);

  return response.data;
};
