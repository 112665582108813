import { SET_DEMO_OPEN, SET_RESET_EMAIL } from "./VisitorsActions";
import { IVisitorsState, Actions } from "./interfaces";

export const initialState = {
  isDemoOpen: false,
  isSuccessOpen: false,
  resetEmail: "",
};

const VisitorsReducers = (
  state: IVisitorsState = initialState,
  action: Actions
) => {
  switch (action.type) {
    case SET_DEMO_OPEN:
      return {
        ...state,
        isDemoOpen: action.payload,
      };
    case SET_RESET_EMAIL:
      return {
        ...state,
        resetEmail: action.payload,
      };
    default:
      return state;
  }
};

export default VisitorsReducers;
