import { IDealCompetitors } from "features/Deals/interfaces";
import { apiClient } from "lib/apiClient";

export const addCompetitors = async (
  dealId: string,
  id: string,
  request: string[]
): Promise<IDealCompetitors[]> => {
  const response = await apiClient.post(
    `/deals/${dealId}/subjects/${id}/competitors`,
    request
  );

  return response.data;
};
