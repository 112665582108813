import { CancelTokenSource } from "axios";
import { apiClient } from "lib/apiClient";

export interface IPhotoDetails {
  url: string;
  creationDate: string;
  extension: string;
  filename: string;
  id: string;
  lastModifiedDate: string;
  fileSize: number;
  order: number;
  size: number;
}

export const getPropertyPhoto = async (
  id: string,
  cancelTokenSource: CancelTokenSource
): Promise<IPhotoDetails[]> => {
  const response = await apiClient.get(`/properties/${id}/photos`, {
    cancelToken: cancelTokenSource.token,
  });

  return response.data;
};
