import FileSaver from "file-saver";

export const exportExcel = (exportName: string, response: Blob) => {
  FileSaver.saveAs(
    new Blob([response], {
      type: "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    }),
    `${exportName}.xlsx`
  );
};
