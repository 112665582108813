import {
  SET_COMP_TYPE,
  SET_COMP_PROPERTIES,
  SET_OPEN_COMP_CREATE,
  SET_SALE_COMP_OPTIONS,
  SET_REQUESTED_COMP_PROPERTIES,
  SET_COMP_ATTACHMENTS,
  SET_SUCCESS_COMP_OPEN,
  SET_COMP_LOADING,
  SET_TABLE_COMPS_DATA,
  SET_REVIEWED_COMP_DATA,
  SET_SUCCESS_MODAL_MESSAGE,
  SET_COMP_INFO,
  SET_COMP_SUB_TYPE,
  SET_COMP_PRICE,
  SET_SORT_COMP,
  SET_EDIT_COMP_FORM_STATUS,
  SET_COMP_FORM_VALUES,
  SET_COPY_COMP_FORM_STATUS,
  SET_COMP_CANCEL_TOKEN,
  SET_COMP_PRICING_ANALYSIS,
  SET_CREATE_COMP_FORM_STATUS,
  SET_COMP_PRICING_ANALYSIS_CONFIG,
  SET_ADJUSTMENT_MODE,
  SET_EXPENSE_ANALYSIS_COMP_COUNT,
  SET_EXPENSE_ANALYSIS_CONFIG,
  SET_EXPENSE_ANALYSIS_DATA,
} from "./CompActions";
import { initialState } from "./initialState";
import { Actions, ICompState } from "./interfaces";

const CompReducer = (state: ICompState = initialState, action: Actions) => {
  switch (action.type) {
    case SET_COMP_TYPE:
      return {
        ...state,
        compType: action.payload,
      };
    case SET_COMP_SUB_TYPE:
      return {
        ...state,
        compSubType: action.payload,
      };
    case SET_COMP_PROPERTIES:
      return {
        ...state,
        compProperties: action.payload,
      };
    case SET_OPEN_COMP_CREATE:
      return {
        ...state,
        isCompOpen: action.payload,
      };
    case SET_CREATE_COMP_FORM_STATUS:
      return {
        ...state,
        createCompFormStatus: action.payload,
      };

    case SET_EDIT_COMP_FORM_STATUS:
      return {
        ...state,
        editFormStatus: action.payload,
      };
    case SET_COPY_COMP_FORM_STATUS:
      return {
        ...state,
        copyFormStatus: action.payload,
      };
    case SET_COMP_FORM_VALUES:
      return {
        ...state,
        unsavedForm: action.payload,
      };
    case SET_SALE_COMP_OPTIONS:
      return {
        ...state,
        propertiesOptions: action.payload,
      };
    case SET_COMP_INFO:
      return {
        ...state,
        compInfo: { ...state.compInfo, ...action.payload },
      };
    case SET_REQUESTED_COMP_PROPERTIES:
      return {
        ...state,
        requestedCompProperties: {
          ...state.requestedCompProperties,
          ...action.payload,
        },
      };
    case SET_COMP_ATTACHMENTS:
      return {
        ...state,
        compAttachments: { ...state.compAttachments, ...action.payload },
      };
    case SET_SUCCESS_COMP_OPEN:
      return {
        ...state,
        isSuccessOpen: action.payload,
      };
    case SET_COMP_LOADING:
      return {
        ...state,
        isLoading: { ...state.isLoading, ...action.payload },
      };
    case SET_TABLE_COMPS_DATA:
      return {
        ...state,
        tableCompsData: { ...state.tableCompsData, ...action.payload },
      };
    case SET_REVIEWED_COMP_DATA:
      return {
        ...state,
        reviewCompsData: { ...state.reviewCompsData, ...action.payload },
      };
    case SET_SUCCESS_MODAL_MESSAGE:
      return {
        ...state,
        successModalMessage: action.payload,
      };
    case SET_COMP_PRICE:
      return {
        ...state,
        compPrice: { ...state.compPrice, ...action.payload },
      };
    case SET_SORT_COMP:
      return {
        ...state,
        sortCompBy: action.payload,
      };
    case SET_COMP_CANCEL_TOKEN:
      return {
        ...state,
        cancelToken: { ...state.cancelToken, ...action.payload },
      };
    case SET_COMP_PRICING_ANALYSIS:
      return {
        ...state,
        pricingAnalysis: action.payload,
      };
    case SET_COMP_PRICING_ANALYSIS_CONFIG:
      return {
        ...state,
        pricingAnalysisConfig: action.payload,
      };
    case SET_EXPENSE_ANALYSIS_COMP_COUNT:
      return {
        ...state,
        expenseAnalysisCompCount: action.payload,
      };
    case SET_EXPENSE_ANALYSIS_CONFIG:
      return {
        ...state,
        expenseAnalysisConfig: action.payload,
      };
    case SET_EXPENSE_ANALYSIS_DATA:
      return {
        ...state,
        expenseAnalysisData: action.payload,
      };
    case SET_ADJUSTMENT_MODE:
      return {
        ...state,
        isAdjustmentMode: action.payload,
      };
    default:
      return state;
  }
};

export default CompReducer;
