import { apiClient } from "lib/apiClient";

type Response = {
  id: string;
  filename: string;
  creationDate: string;
  lastModifiedDate: string;
};
export const addPropertyPhoto = async (
  propertyId: string,
  photo: FormData,
  order: number
): Promise<Response> => {
  const response = await apiClient.post(
    `/properties/${propertyId}/photo?order=${order}`,
    photo
  );

  return response.data;
};
