import { apiClient } from "lib/apiClient";

export const deleteComp = async <T>(
  id: string,
  compType: string,
  propertyId: string
): Promise<T> => {
  const response = await apiClient.delete(
    `/reviews/comps/${compType}/${id}/${propertyId}`
  );

  return response.data;
};
