import { ISortProperty } from "features/Property/interfaces";
import { IRequestParams } from "pages/Properties/components/PropertiesFilter/PropertiesFilter";
import { isNumber } from "utils/isNumber";
import { resolveDate } from "utils/resolveDate";

export const configureFilterParamsForRequest = (
  requestParams: IRequestParams,
  coordinatesOnly: boolean,
  sortPropertyParam?: ISortProperty,
  page?: number
) => ({
  ...requestParams.selectedServices,
  statesCounties: requestParams.states,
  page: isNumber(page) ? page : null,
  size: isNumber(page) ? 10 : null,
  coordinatesOnly,
  country: requestParams.checkedCountry.join(",") || null,
  name: requestParams.searchedPropertyName || null,
  hasSaleComp: requestParams.selectedCompTypes.isSaleCompsSelected || null,
  closingDateFrom: requestParams.selectedCompTypes.closingDateFrom || null,
  closingDateTo: requestParams.selectedCompTypes.closingDateTo || null,
  pricePerUnitFrom: requestParams.selectedCompTypes.pricePerUnitFrom || null,
  pricePerUnitTo: requestParams.selectedCompTypes.pricePerUnitTo || null,
  hasRentComp: requestParams.selectedCompTypes.isRentCompsSelected || null,
  verificationDateFrom: requestParams.selectedCompTypes.verificationDateFrom
    ? resolveDate(
        requestParams.selectedCompTypes.verificationDateFrom,
        "yyyy-MM-dd"
      )
    : null,
  verificationDateTo: requestParams.selectedCompTypes.verificationDateTo
    ? resolveDate(
        requestParams.selectedCompTypes.verificationDateTo,
        "yyyy-MM-dd"
      )
    : null,
  hasExpenseComp:
    requestParams.selectedCompTypes.isExpenseCompsSelected || null,
  expenseYearFrom: requestParams.selectedCompTypes.plYearFrom || null,
  expenseYearTo: requestParams.selectedCompTypes.plYearTo || null,
  revenuePerResidentDaysFrom:
    requestParams.selectedCompTypes.revenueFrom || null,
  revenuePerResidentDaysTo: requestParams.selectedCompTypes.revenueTo || null,
  expenseRatioFrom: requestParams.selectedCompTypes.expenseFrom || null,
  expenseRatioTo: requestParams.selectedCompTypes.expenseTo || null,
  licensedBedsAlFrom:
    requestParams?.selectedServicesParams?.licensedBedsAlFrom || null,
  licensedBedsAlTo:
    requestParams?.selectedServicesParams?.licensedBedsAlTo || null,
  licensedBedsMcFrom:
    requestParams?.selectedServicesParams?.licensedBedsMcFrom || null,
  licensedBedsMcTo:
    requestParams?.selectedServicesParams?.licensedBedsMcTo || null,
  licensedBedsSnFrom:
    requestParams?.selectedServicesParams?.licensedBedsSnFrom || null,
  licensedBedsSnTo:
    requestParams?.selectedServicesParams?.licensedBedsSnTo || null,
  hasPhoto: requestParams.propertyPhoto,
  hasSeniorComp: requestParams?.availableComps.hasSeniorComp || null,
  hasMemberComp: requestParams?.availableComps.hasMemberComp || null,
  hasCompUnderReview: requestParams?.availableComps.hasCompUnderReview || null,
  sortBy: sortPropertyParam?.value || null,
  longitudeMin: requestParams?.longitudeMin || null,
  longitudeMax: requestParams?.longitudeMax || null,
  latitudeMin: requestParams?.latitudeMin || null,
  latitudeMax: requestParams?.latitudeMax || null,
  dealView: requestParams?.dealView || null,
  marketTypes: requestParams?.advancedSearch?.marketType?.length
    ? requestParams.advancedSearch?.marketType
    : null,
  operator: requestParams?.advancedSearch?.operatorName || null,
  isOperatorUnknown: requestParams?.advancedSearch?.operatorCheckbox || null,
  profitTypes: requestParams?.advancedSearch?.profitType?.length
    ? requestParams.advancedSearch?.profitType
    : null,
  propertyStatuses: requestParams?.advancedSearch?.propertyStatus?.length
    ? requestParams.advancedSearch?.propertyStatus
    : null,
  yearOpenedFrom: requestParams?.advancedSearch?.yearOpenedFrom || null,
  yearOpenedTo: requestParams?.advancedSearch?.yearOpenedTo || null,
  isYearOpenedUnknown:
    requestParams?.advancedSearch?.yearOpenedCheckbox || null,
  primaryPaymentTypes: requestParams?.advancedSearch?.paymentType?.length
    ? requestParams.advancedSearch?.paymentType
    : null,
  federalProviderNumber:
    requestParams?.advancedSearch?.federalProviderNumber || null,
  isFederalProviderNumberUnknown:
    requestParams?.advancedSearch?.federalProviderNumberCheckbox || null,
  saleTypes: requestParams?.selectedCompTypes.saleType?.length
    ? requestParams.selectedCompTypes.saleType
    : null,
  assetsTypes: requestParams?.selectedCompTypes.assets?.length
    ? requestParams?.selectedCompTypes.assets
    : null,
  propertyRights: requestParams?.selectedCompTypes.propertyRights?.length
    ? requestParams?.selectedCompTypes.propertyRights
    : null,
  financing: requestParams?.selectedCompTypes.financing?.length
    ? requestParams?.selectedCompTypes.financing
    : null,
  saleConditions: requestParams?.selectedCompTypes.saleConditions?.length
    ? requestParams?.selectedCompTypes.saleConditions
    : null,
  propertyConditions: requestParams?.selectedCompTypes.propertyCondition?.length
    ? requestParams?.selectedCompTypes.propertyCondition
    : null,
});
