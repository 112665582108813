import { apiClient } from "lib/apiClient";

export type IPropertyDetails = {
  propertyId: string;
  name: string;
  country: string;
  state: string;
  region: string;
  city: string;
  street: string;
  zipCode: string;
  activeAdultOffered: boolean;
  assistedLiving: boolean;
  independentLivingOffered: boolean;
  memoryCareOffered: boolean;
  skilledNursing: boolean;
  compsCount: {
    EXPENSE: number;
    SALE: number;
    RENT: number;
  };
};

export const getPropertyById = async (
  id: string
): Promise<IPropertyDetails> => {
  const response = await apiClient.get(`/properties/${id}/preview`);

  return response.data;
};
