export enum LOADING_STATE {
  idle = "idle",
  pending = "pending",
  fulfilled = "fulfilled",
  rejected = "rejected",
}

export type TServiceError = {
  code: number;
  message: string;
};
