import React, { SyntheticEvent, useEffect, useState } from "react";
import { Box, Tab, Typography, useTheme } from "@mui/material";
import { templatesTranslations } from "./translations";
import { EntityWrapper } from "components/EntityWrapper";
import { TabPanel, Tabs } from "components/Tabs";
import AddIcon from "@mui/icons-material/Add";
import { AddTemplateModal } from "./components/AddTemplateModal";
import { useTypedDispatch } from "store";
import {
  createTemplate,
  getMemberTemplates,
  getSeniorTemplates,
  getTemplatesCount,
  updateTemplateSubscriptions,
} from "features/Templates/controllers";
import { useSelector } from "react-redux";
import { getTemplatesCountData } from "features/Templates/selectors";
import * as signInSelectors from "store/selectors/signIn";
import { isAdmin } from "config/constants";
import { getIsAddButtonAvailable } from "./helpers/getIsAddButtonAvailable";
import { SuccessModal } from "components/SuccessModal";
import successChecked from "assets/images/successChecked.png";
import { IMultipleFileData } from "../Comps/components/CreateSaleComp/interfaces";
import { toastMessage } from "../../components/ToastMessage";
import { resetTemplatesData } from "features/Templates/slice";
import { getTabsConfiguration } from "./helpers/getTabsConfiguration";
import { TSubscriptionPlan } from "../../features/Templates/types";
import { StyledAddButton } from "./components/Components.styled";

const Templates = () => {
  const [successModalText, setSuccessModalText] = useState<null | string>(null);
  const dispatch = useTypedDispatch();
  const userInfo = useSelector(signInSelectors.userInfo);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [errorText, setErrorText] = useState("");

  const isAdminUser = isAdmin(userInfo.roles);
  const tabsConfiguration = getTabsConfiguration(isAdminUser);
  const [currentTab, setCurrentTab] = useState(tabsConfiguration[0].key);
  const theme = useTheme();
  const { seniorTemplatesCount, memberTemplatesCount } = useSelector(
    getTemplatesCountData
  );

  const handleCloseSuccessModal = () => {
    setSuccessModalText(null);
  };

  const handleOpenSuccessModal = (text: string) => {
    setSuccessModalText(text);
  };

  const isAddButtonAvailable = getIsAddButtonAvailable(isAdminUser, currentTab);

  useEffect(() => {
    dispatch(getTemplatesCount());

    return () => {
      dispatch(resetTemplatesData());
    };
  }, []);

  const templateCount = seniorTemplatesCount + memberTemplatesCount;

  const templateCountTranslation =
    templateCount > 1
      ? templatesTranslations.templatesFound_other
      : templatesTranslations.templatesFound_one;

  const toggleAddModal = () => {
    setErrorText("");
    setIsAddModalOpen((prevState) => !prevState);
  };

  const handleTabChange = (_event: SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  };

  const handleAddFiles = (files: IMultipleFileData[]) => {
    return dispatch(createTemplate({ file: files[0].data })).then(
      (resultAction) => {
        if (createTemplate.fulfilled.match(resultAction)) {
          setIsAddModalOpen(false);
          handleOpenSuccessModal(templatesTranslations.successToCreate);

          dispatch(getTemplatesCount());
          if (currentTab === "senior") {
            dispatch(getSeniorTemplates({}));
          } else {
            dispatch(getMemberTemplates({}));
          }
        } else {
          if (resultAction.payload?.message) {
            setErrorText(resultAction.payload.message);
          } else {
            toastMessage({
              type: "error",
              position: "top-right",
              message: templatesTranslations.failedToCreate,
            });
          }
        }

        return resultAction;
      }
    );
  };

  const handleUpdatePlans = (
    templateId: string,
    subscriptions: TSubscriptionPlan[]
  ) => {
    dispatch(updateTemplateSubscriptions({ templateId, subscriptions })).then(
      (resultAction) => {
        if (updateTemplateSubscriptions.fulfilled.match(resultAction)) {
          handleOpenSuccessModal(templatesTranslations.successToUpdate);
          dispatch(getTemplatesCount());
          dispatch(getSeniorTemplates({}));
        } else {
          toastMessage({
            message: templatesTranslations.failedToUpdate,
            position: "top-right",
            type: "error",
          });
        }
      }
    );
  };
  return (
    <EntityWrapper>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <Typography variant="h4" mb={1}>
            {templatesTranslations.templates}
          </Typography>
          <Typography variant="h5" mb={3}>
            {`${templateCount} ${templateCountTranslation}`}
          </Typography>
        </Box>
        {isAddButtonAvailable && (
          <StyledAddButton
            onClick={toggleAddModal}
            color="secondary"
            size="small"
            variant="contained"
            startIcon={<AddIcon />}
            disableElevation
          >
            {templatesTranslations.addTemplate}
          </StyledAddButton>
        )}
      </Box>
      <Box borderBottom={1} borderColor={theme.palette.divider}>
        <Tabs value={currentTab} onChange={handleTabChange}>
          {tabsConfiguration.map(({ label, key }) => (
            <Tab
              key={key}
              value={key}
              label={`${label} (${
                key === tabsConfiguration[0].key
                  ? seniorTemplatesCount
                  : memberTemplatesCount
              })`}
            />
          ))}
        </Tabs>
      </Box>
      {tabsConfiguration.map(({ component, key, label }) => {
        const TabToRender = component;
        return (
          <TabPanel key={label} value={currentTab} index={key}>
            <TabToRender
              isAdminUser={isAdminUser}
              handleUpdatePlans={handleUpdatePlans}
            />
          </TabPanel>
        );
      })}
      {isAddModalOpen && (
        <AddTemplateModal
          handleClose={toggleAddModal}
          handleAddFiles={handleAddFiles}
          uploadError={errorText}
        />
      )}
      {successModalText && (
        <SuccessModal
          title={successModalText}
          icon={successChecked}
          open
          handleClose={handleCloseSuccessModal}
        />
      )}
    </EntityWrapper>
  );
};

export default Templates;
