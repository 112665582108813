import { ICreateExpenseComp } from "pages/Comps/components/ExpenseCompModal/interfaces";
import { RentCompInfoType } from "features/Comp/interfaces";
import { apiClient } from "lib/apiClient";

export interface IEditExpenseComp extends ICreateExpenseComp {
  id: string;
}

export const editExpenseComp = async (
  request: IEditExpenseComp
): Promise<RentCompInfoType> => {
  const response = await apiClient.put(`/comps/expense`, request);

  return response.data;
};
