import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ModalComponent } from "components/ModalComponent";
import { setDemoOpen } from "features/Visitors/VisitorsActions";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as selector from "store/selectors/visitors";

export const DemoModal = () => {
  const dispatch = useDispatch();
  const isDemoOpen = useSelector(selector.isDemoOpen);

  const handleClose = () => {
    dispatch(setDemoOpen(false));
  };

  useEffect(() => {
    if (isDemoOpen && window.hbspt) {
      window.hbspt.forms.create({
        portalId: process.env.REACT_APP_HUBSPOT_CLIENT_ID,
        formId: process.env.REACT_APP_DEMO_FORM_ID,
        region: "na1",
        target: "#hubspotForm",
      });
    }
  }, [isDemoOpen]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    script.async = true;
    script.charset = "utf-8";
    script.type = "text/javascript";
    script.crossOrigin = "anonymous";
    script.integrity =
      "sha384-k4HcwfV/HVP0YNE4V1H9MxRyn3F+kKuEzKhPMElX15Lra3QnlyuOBFl4gXBP6eQd";
    document.body.appendChild(script);
  }, []);

  return (
    <ModalComponent
      open={isDemoOpen}
      handleClose={handleClose}
      closable
      boxStyles={{
        width: "480px",
        maxHeight: "99%",
      }}
    >
      <Typography
        id="modal-modal-title"
        variant="h4"
        sx={{
          textAlign: "center",
          mb: "20px",
        }}
      >
        Get started with SeniorComps
      </Typography>
      <Box component="div" id="hubspotForm" sx={{ pb: "10px" }} />
    </ModalComponent>
  );
};
