import { apiClient } from "lib/apiClient";
import { IRequestParams } from "pages/Properties/components/PropertiesFilter/PropertiesFilter";
import { ISortProperty } from "features/Property/interfaces";
import { CancelTokenSource } from "axios";
import { configureFilterParamsForRequest } from "./utils/configureFilterParamsForRequest";

export const exportProperties = async (
  requestParams: IRequestParams,
  cancelTokenSource: CancelTokenSource,
  sortPropertyParam?: ISortProperty
) => {
  const response = await apiClient.post(
    `/property/export/xlsx`,
    configureFilterParamsForRequest(requestParams, false, sortPropertyParam),
    { cancelToken: cancelTokenSource.token }
  );

  return response.data;
};
