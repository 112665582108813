import { Box } from "@mui/material";
import { SideBar } from "components/SideBar";
import { routes } from "config/routes";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { checkRoute } from "utils/checkRoute";
import { setPageType } from "features/CompanyGroups/CompanyGroupsActions";
import { localStorageManager } from "lib/localStorageManager";
import { useTypedDispatch } from "store";

export const Layout = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const dispatch = useTypedDispatch();

  useEffect(() => {
    const isGeneralRoute = checkRoute(location.pathname, routes);
    if (isGeneralRoute) localStorageManager.clearStorage();
    const token = localStorageManager.getToken();
    dispatch(setPageType(!!token));
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <SideBar />
      <Box
        sx={{
          width: "100%",
          overflow: "auto",
          maxWidth:
            location.pathname !== routes.private.properties ? "1700px" : "auto",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
