import { IconButton, styled, TableCell } from "@mui/material";
import { ReactComponent as GreyExpandIcon } from "assets/svg/greyExpandIcon.svg";

export const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== "isOpen",
})<{
  isOpen: boolean;
}>(({ isOpen }) => ({
  "&.MuiIconButton-root:hover": {
    backgroundColor: "transparent",
  },
  "& .MuiTouchRipple-root": {
    display: "none",
  },
  top: "2px",
  padding: "6px 10px 12px 0px",
  position: "relative",
  "::before": {
    content: '""',
    position: "absolute",
    left: "4px",
    top: "22px",
    bottom: isOpen ? "-35px" : "6px",
    borderLeft: "1px solid #BFBFBF",
  },
}));

export const StyledExpandIcon = styled(GreyExpandIcon, {
  shouldForwardProp: (prop) => prop !== "isOpen",
})<{
  isOpen: boolean;
}>(({ isOpen }) => ({
  transform: isOpen ? "none" : "rotate(-90deg)",
  transition: "all 0.3s ease",
}));

export const StyledCollapsibleTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "isOpen",
})<{
  isOpen: boolean;
}>(({ isOpen }) => ({
  padding: "0 !important",
  paddingLeft: "52px !important",
  position: "relative",
  "::before": {
    content: '""',
    position: "absolute",
    left: "24px",
    top: "-11px",
    bottom: isOpen ? "12px" : "24px",
    borderLeft: "1px solid #BFBFBF",
  },
}));
