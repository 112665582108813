export const states = [
  { value: "AK", name: "Alaska" },
  { value: "AL", name: "Alabama" },
  { value: "AR", name: "Arkansas" },
  { value: "AZ", name: "Arizona" },
  { value: "CA", name: "California" },
  { value: "CO", name: "Colorado" },
  { value: "CT", name: "Connecticut" },
  { value: "DC", name: "DistrictofColumbia" },
  { value: "DE", name: "Delaware" },
  { value: "FL", name: "Florida" },
  { value: "GA", name: "Georgia" },
  { value: "HI", name: "Hawaii" },
  { value: "IA", name: "Iowa" },
  { value: "ID", name: "Idaho" },
  { value: "IL", name: "Illinois" },
  { value: "IN", name: "Indiana" },
  { value: "KS", name: "Kansas" },
  { value: "KY", name: "Kentucky" },
  { value: "LA", name: "Louisiana" },
  { value: "MA", name: "Massachusetts" },
  { value: "MD", name: "Maryland" },
  { value: "ME", name: "Maine" },
  { value: "MI", name: "Michigan" },
  { value: "MN", name: "Minnesota" },
  { value: "MO", name: "Missouri" },
  { value: "MS", name: "Mississippi" },
  { value: "MT", name: "Montana" },
  { value: "NC", name: "NorthCarolina" },
  { value: "ND", name: "NorthDakota" },
  { value: "NE", name: "Nebraska" },
  { value: "NH", name: "NewHampshire" },
  { value: "NJ", name: "NewJersey" },
  { value: "NM", name: "NewMexico" },
  { value: "NV", name: "Nevada" },
  { value: "NY", name: "NewYork" },
  { value: "OH", name: "Ohio" },
  { value: "OK", name: "Oklahoma" },
  { value: "OR", name: "Oregon" },
  { value: "PA", name: "Pennsylvania" },
  { value: "PR", name: "Puerto Rico" },
  { value: "RI", name: "RhodeIsland" },
  { value: "SC", name: "SouthCarolina" },
  { value: "SD", name: "SouthDakota" },
  { value: "TN", name: "Tennessee" },
  { value: "TX", name: "Texas" },
  { value: "UT", name: "Utah" },
  { value: "VA", name: "Virginia" },
  { value: "VT", name: "Vermont" },
  { value: "WA", name: "Washington" },
  { value: "WI", name: "Wisconsin" },
  { value: "WV", name: "WestVirginia" },
  { value: "WY", name: "Wyoming" },
];

export const regions: {
  [key: string]: string;
} = {
  AK: "Pacific West",
  AL: "Southeast",
  AR: "Southeast",
  AZ: "Southwest",
  CA: "Pacific West",
  CO: "Mountain West",
  CT: "Northeast",
  DC: "Mid-Atlantic",
  DE: "Northeast",
  FL: "Southeast",
  GA: "Southeast",
  HI: "Pacific West",
  IA: "Midwest",
  ID: "Mountain West",
  IL: "Midwest",
  IN: "Midwest",
  KS: "Midwest",
  KY: "Midwest",
  LA: "Southeast",
  MA: "Northeast",
  MD: "Mid-Atlantic",
  ME: "Northeast",
  MI: "Midwest",
  MN: "Midwest",
  MO: "Midwest",
  MS: "Southeast",
  MT: "Mountain West",
  NC: "Southeast",
  ND: "Midwest",
  NE: "Midwest",
  NH: "Northeast",
  NJ: "Northeast",
  NM: "Southwest",
  NV: "Mountain West",
  NY: "Northeast",
  OH: "Midwest",
  OK: "Southwest",
  OR: "Pacific West",
  PA: "Mid-Atlantic",
  PR: "Southeast",
  RI: "Northeast",
  SC: "Southeast",
  SD: "Midwest",
  TN: "Southeast",
  TX: "Southwest",
  UT: "Mountain West",
  VA: "Mid-Atlantic",
  VT: "Northeast",
  WA: "Pacific West",
  WI: "Midwest",
  WV: "Mid-Atlantic",
  WY: "Mountain West",
};

export const MARKET_TYPES = [
  {
    state: "AL",
    county: "Bibb",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "AL",
    county: "Blount",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "AL",
    county: "Chilton",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "AL",
    county: "Jefferson",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "AL",
    county: "Shelby",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "AL",
    county: "St. Clair",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "AL",
    county: "Walker",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "AR",
    county: "Crittenden",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "AR",
    county: "Faulkner",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "AR",
    county: "Grant",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "AR",
    county: "Lonoke",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "AR",
    county: "Perry",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "AR",
    county: "Pulaski",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "AR",
    county: "Saline",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "AZ",
    county: "Maricopa",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "AZ",
    county: "Pima",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "AZ",
    county: "Pinal",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "CA",
    county: "Alameda",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "CA",
    county: "Contra Costa",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "CA",
    county: "El Dorado",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "CA",
    county: "Fresno",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "CA",
    county: "Kern",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "CA",
    county: "Los Angeles",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "CA",
    county: "Marin",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "CA",
    county: "Orange",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "CA",
    county: "Placer",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "CA",
    county: "Riverside",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "CA",
    county: "Sacramento",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "CA",
    county: "San Bernardino",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "CA",
    county: "San Diego",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "CA",
    county: "San Francisco",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "CA",
    county: "San Joaquin",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "CA",
    county: "San Mateo",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "CA",
    county: "Santa Clara",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "CA",
    county: "Stanislaus",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "CA",
    county: "Ventura",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "CA",
    county: "Yolo",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "CO",
    county: "El Paso",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "CO",
    county: "Elbert",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "CO",
    county: "Jefferson",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "CO",
    county: "Teller",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "CT",
    county: "Fairfield",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "CT",
    county: "Hartford",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "CT",
    county: "Middlesex",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "CT",
    county: "New Haven",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "CT",
    county: "Tolland",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "DC",
    county: "City of Washington",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "DE",
    county: "New Castle",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "FL",
    county: "Baker",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "FL",
    county: "Brevard",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "FL",
    county: "Broward",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "FL",
    county: "Clay",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "FL",
    county: "Duval",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "FL",
    county: "Hernando",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "FL",
    county: "Hillsborough",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "FL",
    county: "Lake",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "FL",
    county: "Lee",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "FL",
    county: "Manatee",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "FL",
    county: "Miami-Dade",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "FL",
    county: "Nassau",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "FL",
    county: "Orange",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "FL",
    county: "Osceola",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "FL",
    county: "Palm Beach",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "FL",
    county: "Pasco",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "FL",
    county: "Pinellas",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "FL",
    county: "Polk",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "FL",
    county: "Sarasota",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "FL",
    county: "Seminole",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "FL",
    county: "St. Johns",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "FL",
    county: "Volusia",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "GA",
    county: "Barrow",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Bartow",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Burke",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "GA",
    county: "Butts",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Carroll",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Catoosa",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "GA",
    county: "Cherokee",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Clayton",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Cobb",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Columbia",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "GA",
    county: "Coweta",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Dade",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "GA",
    county: "Dawson",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Dekalb",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Douglas",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Fayette",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Forsyth",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Fulton",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Gwinnett",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Haralson",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Heard",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Henry",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Jasper",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Lamar",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Mcduffie",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "GA",
    county: "Meriwether",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Newton",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Paulding",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Pickens",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Richmond",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "GA",
    county: "Rockdale",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Spalding",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "GA",
    county: "Walker",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "GA",
    county: "Walton",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IA",
    county: "Dallas",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "IA",
    county: "Guthrie",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "IA",
    county: "Harrison",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "IA",
    county: "Madison",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "IA",
    county: "Mills",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "IA",
    county: "Polk",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "IA",
    county: "Pottawattamie",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "IA",
    county: "Warren",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "ID",
    county: "Ada",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "ID",
    county: "Canyon",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "ID",
    county: "Gem",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "ID",
    county: "Owyhee",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "IL",
    county: "Bond",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IL",
    county: "Calhoun",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IL",
    county: "Clinton",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IL",
    county: "Cook",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IL",
    county: "Dekalb",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IL",
    county: "Dupage",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IL",
    county: "Grundy",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IL",
    county: "Jersey",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IL",
    county: "Kane",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IL",
    county: "Kendall",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IL",
    county: "Lake",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IL",
    county: "Macoupin",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IL",
    county: "Madison",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IL",
    county: "McHenry",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IL",
    county: "Monroe",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IL",
    county: "St. Clair",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IL",
    county: "Will",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IN",
    county: "Boone",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "IN",
    county: "Brown",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "IN",
    county: "Clark",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "IN",
    county: "Dearborn",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IN",
    county: "Floyd",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "IN",
    county: "Franklin",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IN",
    county: "Hamilton",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "IN",
    county: "Hancock",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "IN",
    county: "Harrison",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "IN",
    county: "Hendricks",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "IN",
    county: "Jasper",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IN",
    county: "Johnson",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "IN",
    county: "Lake",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IN",
    county: "Marion",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "IN",
    county: "Morgan",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "IN",
    county: "Newton",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IN",
    county: "Ohio",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IN",
    county: "Porter",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "IN",
    county: "Putnam",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "IN",
    county: "Shelby",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "IN",
    county: "Washington",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "KS",
    county: "Franklin",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "KS",
    county: "Harvey",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "KS",
    county: "Johnson",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "KS",
    county: "Leavenworth",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "KS",
    county: "Linn",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "KS",
    county: "Miami",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "KS",
    county: "Sedgwick",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "KS",
    county: "Sumner",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "KS",
    county: "Wyandotte",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "KY",
    county: "Boone",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "KY",
    county: "Bracken",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "KY",
    county: "Bullitt",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "KY",
    county: "Campbell",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "KY",
    county: "Gallatin",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "KY",
    county: "Grant",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "KY",
    county: "Henry",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "KY",
    county: "Jefferson",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "KY",
    county: "Kenton",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "KY",
    county: "Meade",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "KY",
    county: "Nelson",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "KY",
    county: "Oldham",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "KY",
    county: "Pendleton",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "KY",
    county: "Shelby",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "KY",
    county: "Spencer",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "KY",
    county: "Trimble",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "LA",
    county: "Ascension Parish",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "LA",
    county: "East Baton Rouge",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "LA",
    county: "East Baton Rouge Parish",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "LA",
    county: "East Feliciana Parish",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "LA",
    county: "Iberville",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "LA",
    county: "Iberville Parish",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "LA",
    county: "Jefferson",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "LA",
    county: "Jefferson Parish",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "LA",
    county: "Livingston Parish",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "LA",
    county: "Orleans",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "LA",
    county: "Orleans Parish",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "LA",
    county: "Plaquemines Parish",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "LA",
    county: "Pointe Coupee Parish",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "LA",
    county: "St. Bernard Parish",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "LA",
    county: "St. Charles Parish",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "LA",
    county: "St. Helena",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "LA",
    county: "St. John The Baptist Parish",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "LA",
    county: "St. Tammany",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "LA",
    county: "St. Tammany Parish",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "LA",
    county: "West Baton Rouge Parish",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "LA",
    county: "West Feliciana Parish",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "MA",
    county: "Bristol",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "MA",
    county: "Essex",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MA",
    county: "Franklin",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "MA",
    county: "Hampden",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "MA",
    county: "Hampshire",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "MA",
    county: "Middlesex",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MA",
    county: "Norfolk",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MA",
    county: "Plymouth",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MA",
    county: "Suffolk",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MA",
    county: "Worcester",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "MD",
    county: "Cecil",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "ME",
    county: "Cumberland",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "ME",
    county: "Sagadahoc",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "ME",
    county: "York",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "MI",
    county: "Barry",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "MI",
    county: "Ionia",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "MI",
    county: "Kent",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "MI",
    county: "Lapeer",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MI",
    county: "Livingston",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MI",
    county: "Macomb",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MI",
    county: "Newaygo",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "MI",
    county: "Oakland",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MI",
    county: "St. Clair",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MI",
    county: "Wayne",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MN",
    county: "Anoka",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MN",
    county: "Carver",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MN",
    county: "Chisago",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MN",
    county: "Dakota",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MN",
    county: "Hennepin",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MN",
    county: "Isanti",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MN",
    county: "Ramsey",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MN",
    county: "Scott",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MN",
    county: "Sherburne",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MN",
    county: "Washington",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MN",
    county: "Wright",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MO",
    county: "Bates",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MO",
    county: "Caldwell",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MO",
    county: "Cass",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MO",
    county: "Clay",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MO",
    county: "Clinton",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MO",
    county: "Crawford",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MO",
    county: "Franklin",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MO",
    county: "Jackson",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MO",
    county: "Jefferson",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MO",
    county: "Lafayette",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MO",
    county: "Lincoln",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MO",
    county: "Platte",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MO",
    county: "Ray",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MO",
    county: "Saint Charles",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MO",
    county: "St Louis City",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MO",
    county: "St. Louis",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MO",
    county: "Warren",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MO",
    county: "Washington",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "MS",
    county: "Copiah",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "MS",
    county: "Desoto",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "MS",
    county: "Hinds",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "MS",
    county: "Madison",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "MS",
    county: "Marshall",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "MS",
    county: "Rankin",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "MS",
    county: "Simpson",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "MS",
    county: "Tate",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "MS",
    county: "Tunica",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NC",
    county: "Anson",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NC",
    county: "Cabarrus",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NC",
    county: "Currituck",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NC",
    county: "Franklin",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NC",
    county: "Gaston",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NC",
    county: "Guilford",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NC",
    county: "Johnston",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NC",
    county: "Mecklenburg",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NC",
    county: "Randolph",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NC",
    county: "Rockingham",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NC",
    county: "Union",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NC",
    county: "Wake",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NE",
    county: "Cass",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NE",
    county: "Douglas",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NE",
    county: "Sarpy",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NE",
    county: "Saunders",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NE",
    county: "Washington",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NH",
    county: "Rockingham",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NH",
    county: "Strafford",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NJ",
    county: "Bergen",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NJ",
    county: "Burlington",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NJ",
    county: "Camden",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NJ",
    county: "Essex",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NJ",
    county: "Gloucester",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NJ",
    county: "Hudson",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NJ",
    county: "Hunterdon",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NJ",
    county: "Middlesex",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NJ",
    county: "Monmouth",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NJ",
    county: "Morris",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NJ",
    county: "Ocean",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NJ",
    county: "Passaic",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NJ",
    county: "Salem",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NJ",
    county: "Somerset",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NJ",
    county: "Sussex",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NJ",
    county: "Union",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NJ",
    county: "Warren",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NM",
    county: "Bernalillo",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NM",
    county: "Sandoval",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NM",
    county: "Valencia",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NV",
    county: "Clark",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NY",
    county: "Albany",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NY",
    county: "Bronx",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NY",
    county: "Dutchess",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NY",
    county: "Erie",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NY",
    county: "Kings",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NY",
    county: "Livingston",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NY",
    county: "Madison",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NY",
    county: "Monroe",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NY",
    county: "Nassau",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NY",
    county: "New York",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NY",
    county: "Niagara",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NY",
    county: "Onondaga",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NY",
    county: "Ontario",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NY",
    county: "Orange",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NY",
    county: "Orleans",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NY",
    county: "Oswego",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NY",
    county: "Putnam",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NY",
    county: "Queens",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NY",
    county: "Rensselaer",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NY",
    county: "Richmond",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NY",
    county: "Rockland",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NY",
    county: "Saratoga",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NY",
    county: "Schenectady",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NY",
    county: "Schoharie",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NY",
    county: "Suffolk",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "NY",
    county: "Wayne",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "NY",
    county: "Westchester",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "OH",
    county: "Brown",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "OH",
    county: "Butler",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "OH",
    county: "Clermont",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "OH",
    county: "Cuyahoga",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "OH",
    county: "Delaware",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OH",
    county: "Franklin",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OH",
    county: "Fulton",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OH",
    county: "Geauga",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "OH",
    county: "Greene",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OH",
    county: "Hamilton",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "OH",
    county: "Lake",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "OH",
    county: "Licking",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OH",
    county: "Lorain",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "OH",
    county: "Lucas",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OH",
    county: "Madison",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OH",
    county: "Mahoning",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OH",
    county: "Medina",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "OH",
    county: "Miami",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OH",
    county: "Montgomery",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OH",
    county: "Morrow",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OH",
    county: "Ottawa",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OH",
    county: "Pickaway",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OH",
    county: "Portage",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OH",
    county: "Preble",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OH",
    county: "Summit",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OH",
    county: "Trumbull",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OH",
    county: "Union",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OH",
    county: "Warren",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "OH",
    county: "Wood",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OK",
    county: "Canadian",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OK",
    county: "Cleveland",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OK",
    county: "Creek",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OK",
    county: "Grady",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OK",
    county: "Lincoln",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OK",
    county: "Logan",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OK",
    county: "Mcclain",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OK",
    county: "Oklahoma",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OK",
    county: "Okmulgee",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OK",
    county: "Osage",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OK",
    county: "Pawnee",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OK",
    county: "Rogers",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OK",
    county: "Tulsa",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OK",
    county: "Wagoner",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "OR",
    county: "Clackamas",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "OR",
    county: "Clackamas           ",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "OR",
    county: "Columbia",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "OR",
    county: "Multnomah",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "OR",
    county: "Washington",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "OR",
    county: "Yamhill",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "PA",
    county: "Allegheny",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "PA",
    county: "Armstrong",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "PA",
    county: "Beaver",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "PA",
    county: "Bucks",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "PA",
    county: "Butler",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "PA",
    county: "Carbon",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "PA",
    county: "Chester",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "PA",
    county: "Cumberland",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "PA",
    county: "Dauphin",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "PA",
    county: "Delaware",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "PA",
    county: "Fayette",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "PA",
    county: "Lackawanna",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "PA",
    county: "Lancaster",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "PA",
    county: "Lehigh",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "PA",
    county: "Luzerne",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "PA",
    county: "Mercer",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "PA",
    county: "Montgomery",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "PA",
    county: "Northampton",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "PA",
    county: "Perry",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "PA",
    county: "Philadelphia",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "PA",
    county: "Warren",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "PA",
    county: "Washington",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "PA",
    county: "Westmoreland",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "PA",
    county: "Wyoming",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "RI",
    county: "Bristol",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "RI",
    county: "Kent",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "RI",
    county: "Newport",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "RI",
    county: "Providence",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "RI",
    county: "Washington",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "SC",
    county: "Aiken",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "SC",
    county: "Berkeley",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "SC",
    county: "Calhoun",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "SC",
    county: "Charleston",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "SC",
    county: "Dorchester",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "SC",
    county: "Edgefield",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "SC",
    county: "Fairfield",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "SC",
    county: "Greenville",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "SC",
    county: "Kershaw",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "SC",
    county: "Laurens",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "SC",
    county: "Lexington",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "SC",
    county: "Pickens",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "SC",
    county: "Richland",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "SC",
    county: "Saluda",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "SC",
    county: "York",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Anderson",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Blount",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Cannon",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Cheatham",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Davidson",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Dickson",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Fayette",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Hamilton",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Hickman",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Knox",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Loudon",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Macon",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Marion",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Robertson",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Rutherford",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Sequatchie",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Shelby",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Smith",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Sumner",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Tipton",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Trousdale",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Union",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Williamson",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TN",
    county: "Wilson",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TX",
    county: "Atascosa",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Austin",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Bandera",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Bastrop",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TX",
    county: "Bexar",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Brazoria",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Caldwell",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TX",
    county: "Chambers",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Collin",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Comal",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Dallas",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Delta",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Denton",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "El Paso",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TX",
    county: "Ellis",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Fort Bend",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Galveston",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Guadalupe",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Harris",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Hays",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TX",
    county: "Hidalgo",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TX",
    county: "Hunt",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Johnson",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Kaufman",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Kendall",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Liberty",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Medina",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Montgomery",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Parker",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Rockwall",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "San Jacinto",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Tarrant",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Travis",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TX",
    county: "Waller",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Williamson",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "TX",
    county: "Wilson",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "TX",
    county: "Wise",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "UT",
    county: "Davis",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "UT",
    county: "Morgan",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "UT",
    county: "Salt Lake",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "UT",
    county: "Summit",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "UT",
    county: "Tooele",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "UT",
    county: "Weber",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Alexandria",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "VA",
    county: "Amelia",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Caroline",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Chesapeake",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Chesterfield",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Clarke",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "VA",
    county: "Colonial Heights",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Cumberland",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Dinwiddie",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Fairfax",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "VA",
    county: "Fairfax City",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "VA",
    county: "Falls Church",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "VA",
    county: "Fauquier",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "VA",
    county: "Fredericksburg",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "VA",
    county: "Gloucester",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Goochland",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Hampton",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Hanover",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Henrico",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Hopewell",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Isle Of Wight",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "James City",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "King William",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Loudoun",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "VA",
    county: "Louisa",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Manassas",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "VA",
    county: "Mathews",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Newport News",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Norfolk",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Petersburg",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Petersburg City",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Portsmouth",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Powhatan",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Prince William",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "VA",
    county: "Richmond",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Richmond city",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Spotsylvania",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "VA",
    county: "Stafford",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "VA",
    county: "Suffolk",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Sussex",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Virginia Beach",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "Warren",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "VA",
    county: "Williamsburg",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "VA",
    county: "York",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "WA",
    county: "Clark",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "WA",
    county: "King",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "WA",
    county: "Pierce",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "WA",
    county: "Skamania",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "WA",
    county: "Snohomish",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "WI",
    county: "Columbia",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "WI",
    county: "Dane",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "WI",
    county: "Iowa",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "WI",
    county: "Kenosha",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "WI",
    county: "Milwaukee",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "WI",
    county: "Ozaukee",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "WI",
    county: "Pierce",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "WI",
    county: "St. Croix",
    marketType: "PRIMARY_TOP_30_METRO",
  },
  {
    state: "WI",
    county: "Washington",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "WI",
    county: "Waukesha",
    marketType: "SECONDARY_31_100_METRO",
  },
  {
    state: "WV",
    county: "Jefferson",
    marketType: "PRIMARY_TOP_30_METRO",
  },
];
