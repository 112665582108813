import React from "react";
import { ModalComponent } from "components/ModalComponent";
import { Box, Typography, useTheme } from "@mui/material";
import { templatesTranslations } from "../../translations";
import { ModalButtonStyled } from "./ShareTemplateModal.styled";
import { TSubscriptionPlan } from "features/Templates/types";
import { shareFormSchema } from "./shareFormSchema";
import { useFormik } from "formik";
import { SUBSCRIPTION_TYPES } from "config/constants";
import { FilterCheckBox } from "../../../Properties/components/PropertiesFilter/components/FilterCheckBox";

interface IAddTemplateModalProps {
  handleClose: () => void;
  subscriptions: TSubscriptionPlan[] | null;
  handleUpdateSubscriptions: (subscriptions: TSubscriptionPlan[]) => void;
  templateName: string;
}

export const ShareTemplateModal = ({
  handleClose,
  subscriptions,
  templateName,
  handleUpdateSubscriptions,
}: IAddTemplateModalProps) => {
  const { spacing } = useTheme();

  const { values, handleSubmit, errors, touched, setFieldValue } = useFormik({
    validationSchema: shareFormSchema,
    initialValues: {
      subscriptions: subscriptions ?? [],
    },
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: async ({ subscriptions }) => {
      handleUpdateSubscriptions(subscriptions);
    },
  });

  const handleSubmitModal = () => {
    handleSubmit();
  };

  const handleChange = (newValue: string) => {
    const isValueExist = values.subscriptions.includes(
      newValue as TSubscriptionPlan
    );
    const valueToSet = isValueExist
      ? values.subscriptions.filter((item: string) => item !== newValue)
      : [...values.subscriptions, newValue];
    setFieldValue("subscriptions", valueToSet);
  };

  return (
    <ModalComponent open boxStyles={{ borderRadius: "6px" }}>
      <Box display="flex" flexDirection="column" width="530px" height="349px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          bgcolor="secondary.main"
          p={spacing(2, 5)}
        >
          <Typography variant="h5" fontWeight={600} color="white">
            {templatesTranslations.shareTemplate}
          </Typography>
        </Box>
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          p={spacing(4, 2, 4, 5)}
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="body1" mb={3}>
              {templateName.split(".").slice(0, -1).join(".")}
            </Typography>
            <Typography variant="h5" fontWeight={600} mb={1.5}>
              {templatesTranslations.choosePlans}
            </Typography>
            <Box display="flex" gap={10}>
              {(
                [
                  SUBSCRIPTION_TYPES.BASIC,
                  SUBSCRIPTION_TYPES.PRO,
                  SUBSCRIPTION_TYPES.TEAM,
                ] as TSubscriptionPlan[]
              ).map((key) => {
                return (
                  <FilterCheckBox
                    key={key}
                    value={key}
                    title={
                      key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()
                    }
                    fontWeight={400}
                    handleChange={handleChange}
                    error={
                      Boolean(errors.subscriptions) &&
                      Boolean(touched.subscriptions)
                    }
                    checked={values.subscriptions.includes(key)}
                    fontSize="14px"
                  />
                );
              })}
            </Box>
          </Box>

          <Box display="flex" gap={2} justifyContent="flex-end">
            <ModalButtonStyled classes="light" onClick={handleClose}>
              {templatesTranslations.cancelButtonLabel}
            </ModalButtonStyled>
            <ModalButtonStyled
              disableElevation
              onClick={handleSubmitModal}
              variant="contained"
              color="secondary"
            >
              {templatesTranslations.share}
            </ModalButtonStyled>
          </Box>
        </Box>
      </Box>
    </ModalComponent>
  );
};
