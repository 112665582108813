import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { DemoModal } from "components/DemoModal";
import { setDemoOpen } from "features/Visitors/VisitorsActions";
import { useDispatch } from "react-redux";
import logoWithTextDark from "assets/images/logoWithTextDark.png";

import styles from "./Header.module.scss";
import { BurgerMenu } from "../BurgerMenu";
import { NavItems } from "../NavItems";

export const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isComponentLoaded) {
      handleMenuClose();
    }
  }, [isComponentLoaded]);

  const handleComponentLoad = () => {
    setIsComponentLoaded(true);
  };

  const handleOpenDemo = () => {
    dispatch(setDemoOpen(true));
  };

  const handleMenuOpen = () => {
    setIsComponentLoaded(false);
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  return (
    <Box
      component="header"
      sx={{
        position: "sticky",
        display: "flex",
        top: 0,
        width: "100%",
        height: "84px",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#fff",
        boxShadow: "0 5px 10px rgba(154, 161, 177, .1)",
        zIndex: 2,
      }}
    >
      <Box
        component="div"
        sx={{
          display: "flex",
          width: "100%",
          maxWidth: 1440,
          height: "100%",
          alignItems: "center",

          "@media screen and (max-width: 1380px)": {
            paddingX: "20px",
          },

          "@media screen and (max-width: 920px)": {
            justifyContent: "space-between",
          },
        }}
      >
        <Link
          to={process.env.REACT_APP_HOME_LINK ?? ""}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={logoWithTextDark}
            alt="SeniorComps Inc logo with brand name"
            className={styles.icon}
          />
        </Link>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <NavItems className={styles.link} />
          <Button
            classes="dark"
            onClick={handleOpenDemo}
            sx={{
              display: "none",
              width: "115px",
              height: " 45px",
              borderRadius: "6px",
              fontWeight: "600",

              "@media screen and (max-width: 1000px)": {
                display: "none",
              },
            }}
          >
            Get Started
          </Button>
          <BurgerMenu
            open={isMenuOpen}
            className={styles.menuIcon}
            handleMenuOpen={handleMenuOpen}
            handleComponentLoad={handleComponentLoad}
          />
        </Box>
        <DemoModal />
      </Box>
    </Box>
  );
};
