import { defineDefaultCounty } from "utils/defineDefaultCounty";
import {
  SET_USER_OPEN,
  SET_COMPANY_GROUPS,
  SET_COMPANY_GROUP_INFO,
  SET_COMPANY_GROUP_USERS,
  SET_USER_DATA,
  SET_PAGE_TYPE,
  SET_USER_TRANSACTIONS,
  SET_COMPANY_GROUPS_LOADING,
  SET_UPLOADED_COMPS,
  SET_COUNTY,
} from "./CompanyGroupsActions";
import { ICompanyGroupState, Actions } from "./interfaces";

export const initialState = {
  isAdminPageType: false,
  isLoading: {
    companyGroup: false,
    uploadComp: false,
  },
  isSuccessOpen: {
    user: false,
    companyGroup: false,
    uploadComp: false,
  },
  transactionHistory: {
    totalBalance: 0,
    walletTransactions: [],
  },
  companyGroups: {
    active: 0,
    disabled: 0,
    total: 0,
    objects: [],
  },
  companyGroupUsers: {
    active: 0,
    disabled: 0,
    total: 0,
    objects: [],
  },
  companyGroupInfo: {
    city: "",
    contactEmail: "",
    status: "",
    contactFirstName: "",
    contactLastName: "",
    contactPhone: "",
    contactPhonePrefix: "",
    creationDate: "",
    id: "",
    lastModifiedDate: "",
    lastLoginDate: "",
    name: "",
    state: "",
    street: "",
    zipCode: "",
    active: false,
    userListIsFull: false,
    wallet: {
      balance: null,
      id: "",
    },
    subscription: {
      endDate: "",
      id: "",
      startDate: "",
      subscriptionType: "",
    },
    country: null,
    statesCounties: {},
  },
  userDetails: {
    agreedToLegalDocs: false,
    id: "",
    companyId: "",
    authServiceId: "",
    firstName: "",
    lastName: "",
    email: "",
    phonePrefix: "",
    phone: "",
    creationDate: "",
    lastModifiedDate: "",
    roles: [],
  },
  uploadedComps: null,
  county: defineDefaultCounty(),
};

const CompanyGroupsReducer = (
  state: ICompanyGroupState = initialState,
  action: Actions
) => {
  switch (action.type) {
    case SET_USER_OPEN:
      return {
        ...state,
        isSuccessOpen: { ...state.isSuccessOpen, ...action.payload },
      };
    case SET_COMPANY_GROUPS:
      return {
        ...state,
        companyGroups: action.payload,
      };
    case SET_COMPANY_GROUP_INFO:
      return {
        ...state,
        companyGroupInfo: { ...state.companyGroupInfo, ...action.payload },
      };
    case SET_COMPANY_GROUP_USERS:
      return {
        ...state,
        companyGroupUsers: action.payload,
      };
    case SET_USER_DATA:
      return {
        ...state,
        userDetails: action.payload,
      };
    case SET_PAGE_TYPE:
      return {
        ...state,
        isAdminPageType: action.payload,
      };
    case SET_USER_TRANSACTIONS:
      return {
        ...state,
        transactionHistory: action.payload,
      };
    case SET_COMPANY_GROUPS_LOADING:
      return {
        ...state,
        isLoading: { ...state.isLoading, ...action.payload },
      };
    case SET_UPLOADED_COMPS:
      return {
        ...state,
        uploadedComps: action.payload,
      };
    case SET_COUNTY:
      return {
        ...state,
        county: action.payload,
      };
    default:
      return state;
  }
};

export default CompanyGroupsReducer;
