import { TypedDispatch } from "store/index";
import {
  getTransactionHistory,
  ITransactions,
} from "api/getTransactionHistory";
import { userActivation } from "api/userActivation";
import { getUserById, IUserDetails } from "api/getUserById";
import { companyGroupActivation } from "api/companyGroupActivation";
import { addUser } from "api/addUser";
import { getCompanyGroupById } from "api/getCompanyGroupById";
import {
  ICompanyGroups,
  ICompanyGroup,
  getCompanyGroups,
} from "api/getCompanyGroups";
import { addCompanyGroup } from "api/addCompanyGroup";
import { editCompanyGroup } from "api/editCompanyGroup";
import { getCompanyGroupUsers, IUsers } from "api/getCompanyGroupUsers";
import { Auth, Amplify } from "aws-amplify";
import { signInConfig } from "./../SignIn/signInConfig";
import {
  ICreateCompanyGroup,
  IEditCompanyGroup,
  ICreateUser,
  IEditUser,
  Actions,
  UploadedCompsType,
  CompanyGroupsLoadingType,
} from "./interfaces";
import { Dispatch } from "redux";
import { editUser } from "api/editUser";
import { deleteUser } from "api/deleteUser";
import { FormikErrors, FormikValues } from "formik";
import { deductMultipleCompCoins } from "api/deductMultipleCompCoins";
import { deductCompCoins } from "api/deductCompCoins";
import { uploadXlsxFile } from "api/uploadXlsxFile";
import { getCounties } from "api/getCounties";
import { RootState } from "store/rootReducer";
import { setFilterStates } from "features/Property/PropertyActions";
import { defineDefaultLocationFilters } from "pages/Properties/components/PropertiesFilter/constants";

Amplify.configure(signInConfig);

export const SET_USER_OPEN = "SET_USER_OPEN";
export const SET_COMPANY_GROUPS = "SET_COMPANY_GROUPS";
export const SET_COMPANY_GROUP_INFO = "SET_COMPANY_GROUP_INFO";
export const SET_COMPANY_GROUP_USERS = "SET_COMPANY_GROUP_USERS";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_PAGE_TYPE = "SET_PAGE_TYPE";
export const SET_USER_TRANSACTIONS = "SET_USER_TRANSACTIONS";
export const SET_COMPANY_GROUPS_LOADING = "SET_COMPANY_GROUPS_LOADING";
export const SET_UPLOADED_COMPS = "SET_UPLOADED_COMPS";
export const SET_COUNTY = "SET_COUNTY";

const existedEmail = "An account with the given email already exists.";
const updateEmailMessage = "already exists";

export type UserSuccessType = {
  [key in "user" | "companyGroup" | "uploadComp"]?: boolean;
};

export const setPageType = (value: boolean): Actions => ({
  type: SET_PAGE_TYPE,
  payload: value,
});

export const setUserSuccessOpen = (value: UserSuccessType): Actions => ({
  type: SET_USER_OPEN,
  payload: value,
});

export const setCompanyGroups = (value: ICompanyGroups): Actions => ({
  type: SET_COMPANY_GROUPS,
  payload: value,
});

export const setCompanyGroupInfo = (value: ICompanyGroup): Actions => ({
  type: SET_COMPANY_GROUP_INFO,
  payload: value,
});

export const setCompanyGroupUsers = (value: IUsers): Actions => ({
  type: SET_COMPANY_GROUP_USERS,
  payload: value,
});

export const setUserData = (value: IUserDetails): Actions => ({
  type: SET_USER_DATA,
  payload: value,
});

export const setUserTransactions = (value: ITransactions): Actions => ({
  type: SET_USER_TRANSACTIONS,
  payload: value,
});

export const setCounty = (value: Record<string, string[]>): Actions => ({
  type: SET_COUNTY,
  payload: value,
});

export const setCompanyGroupsLoading = (
  value: CompanyGroupsLoadingType
): Actions => ({
  type: SET_COMPANY_GROUPS_LOADING,
  payload: value,
});

export const setUploadedComps = (value: UploadedCompsType | null): Actions => ({
  type: SET_UPLOADED_COMPS,
  payload: value,
});

export const defineCompanyGroups =
  (active: boolean) => async (dispatch: Dispatch) => {
    dispatch(setCompanyGroupsLoading({ companyGroup: true }));
    try {
      const status = active ? "ACTIVE" : "DISABLED";
      const response = await getCompanyGroups(status);
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
      dispatch(setCompanyGroups(response));
    } catch (error) {
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
    }
  };

export const createCompanyGroup =
  (
    companyGroup: ICreateCompanyGroup,
    active: boolean,
    resetForm: () => void,
    handleClose: () => void,
    handleOpenSuccess: () => void
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setCompanyGroupsLoading({ companyGroup: true }));
    try {
      const status = active ? "ACTIVE" : "DISABLED";
      const response = await addCompanyGroup(companyGroup).then(() => {
        return getCompanyGroups(status);
      });
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
      dispatch(setCompanyGroups(response));
      resetForm();
      handleClose();
      handleOpenSuccess();
    } catch (error) {
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
    }
  };

export const fetchCompanyGroupInfo =
  (id: string) => async (dispatch: Dispatch) => {
    dispatch(setCompanyGroupsLoading({ companyGroup: true }));
    try {
      const response = await getCompanyGroupById(id);
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
      dispatch(setCompanyGroupInfo(response));
      const allowedFilterStates = defineDefaultLocationFilters(
        response.statesCounties
      );
      dispatch(setFilterStates(allowedFilterStates));
    } catch (error) {
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
    }
  };

export const updateCompanyGroup =
  (
    companyGroup: IEditCompanyGroup,
    resetForm: () => void,
    handleClose: () => void,
    handleOpenSuccess: () => void
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setCompanyGroupsLoading({ companyGroup: true }));
    try {
      const response = await editCompanyGroup(companyGroup);
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
      dispatch(setCompanyGroupInfo(response));
      resetForm();
      handleClose();
      handleOpenSuccess();
    } catch (error) {
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
    }
  };

export const fetchCompanyGroupsUsers =
  (id: string, isActive: boolean) => async (dispatch: Dispatch) => {
    dispatch(setCompanyGroupsLoading({ companyGroup: true }));
    try {
      const status = isActive ? "ACTIVE" : "DISABLED";
      const response = await getCompanyGroupUsers(id, status);
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
      dispatch(setCompanyGroupUsers(response));
    } catch (error) {
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
    }
  };

export const createUser =
  (
    userData: ICreateUser,
    isActive: boolean,
    handleOpenSuccess: () => void,
    handleClose: () => void,
    resetForm: () => void,
    setErrors: (errors: FormikErrors<FormikValues>) => void,
    errors: FormikErrors<FormikValues>
  ) =>
  async (dispatch: TypedDispatch) => {
    const status = isActive ? "ACTIVE" : "DISABLED";
    dispatch(setCompanyGroupsLoading({ companyGroup: true }));
    try {
      await Auth.signUp({
        username: userData.email,
        password: userData.password,
        attributes: {
          email: userData.email,
        },
      }).then((data) => {
        const response = addUser({
          ...userData,
          status,
          authServiceId: data.userSub,
        });
        return response;
      });
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
      dispatch(fetchCompanyGroupsUsers(userData.companyId, isActive));
      dispatch(fetchCompanyGroupInfo(userData.companyId));
      handleClose();
      handleOpenSuccess();
      resetForm();
    } catch (error) {
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
      error.message === existedEmail
        ? setErrors({
            ...errors,
            email: "User with this email already exists in the system",
          })
        : console.log(error);
    }
  };

export const toggleCompanyGroup =
  (id: string, activate: boolean, handleClose: () => void) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setCompanyGroupsLoading({ companyGroup: true }));
    try {
      await companyGroupActivation(id, activate).then(() => {
        dispatch(defineCompanyGroups(!activate));
      });
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
      handleClose();
    } catch (error) {
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
    }
  };

export const toggleUserActivation =
  (id: string, activate: boolean, companyId: string, handleClose: () => void) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setCompanyGroupsLoading({ companyGroup: true }));
    try {
      await userActivation(id, !activate).then(() => {
        dispatch(fetchCompanyGroupsUsers(companyId, activate));
      });
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
      handleClose();
    } catch (error) {
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
    }
  };

export const fetchUserById =
  (id: string) => async (dispatch: TypedDispatch) => {
    dispatch(setCompanyGroupsLoading({ companyGroup: true }));
    try {
      const result = await getUserById(id);
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
      dispatch(setUserData(result));
    } catch (error) {
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
    }
  };

export const updateUser =
  (
    userData: IEditUser,
    handleOpenSuccess: () => void,
    handleClose: () => void,
    resetForm: () => void,
    setErrors: (errors: FormikErrors<FormikValues>) => void,
    errors: FormikErrors<FormikValues>
  ) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setCompanyGroupsLoading({ companyGroup: true }));
    try {
      await editUser(userData).then(() => {
        dispatch(fetchUserById(userData.id));
      });
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
      handleClose();
      handleOpenSuccess();
      resetForm();
    } catch (error) {
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
      error.response.data.includes(updateEmailMessage)
        ? setErrors({
            ...errors,
            email: "User with this email already exists in the system",
          })
        : console.log(error);
    }
  };

export const deleteSelectedUser =
  (
    id: string,
    companyGroupId: string,
    active: boolean,
    handleClose: () => void
  ) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setCompanyGroupsLoading({ companyGroup: true }));
    try {
      await deleteUser(id);
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
      dispatch(fetchCompanyGroupsUsers(companyGroupId, active));
      dispatch(fetchCompanyGroupInfo(companyGroupId));
      handleClose();
    } catch (error) {
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
    }
  };

export const fetchUserTransactions =
  (id: string) => async (dispatch: TypedDispatch) => {
    dispatch(setCompanyGroupsLoading({ companyGroup: true }));
    try {
      const response = await getTransactionHistory(id);
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
      dispatch(setUserTransactions(response));
    } catch (error) {
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
    }
  };

export const updateCompanyGroupCoins =
  (comp: string, compType: string, companyId: string) =>
  async (dispatch: TypedDispatch) => {
    dispatch(setCompanyGroupsLoading({ companyGroup: true }));
    try {
      await deductCompCoins(comp, compType);
      dispatch(fetchCompanyGroupInfo(companyId));
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
    } catch (error) {
      dispatch(setCompanyGroupsLoading({ companyGroup: false }));
    }
  };

export const updateMultipleCompanyGroupCoins =
  (comps: string[], compType: string, companyId: string) =>
  async (dispatch: TypedDispatch) => {
    try {
      await deductMultipleCompCoins(comps, compType);
      dispatch(fetchCompanyGroupInfo(companyId));
    } catch (error) {
      console.log(error);
    }
  };

export const uploadCompFile =
  (
    userId: string,
    file: FormData,
    handleClose: () => void,
    fileType?: string
  ) =>
  async (dispatch: TypedDispatch) => {
    try {
      dispatch(setCompanyGroupsLoading({ uploadComp: true }));
      const response = await uploadXlsxFile(userId, file);
      dispatch(
        setUploadedComps({
          count: response.savedComps,
          errors: response.validationErrors,
          compType: fileType ?? "",
        })
      );
      dispatch(setCompanyGroupsLoading({ uploadComp: false }));
      if (response.state === "SUCCESS") {
        dispatch(setUserSuccessOpen({ uploadComp: true }));
        handleClose();
      }
    } catch (error) {
      dispatch(setCompanyGroupsLoading({ uploadComp: false }));
    }
  };

export const fetchCounty =
  (state: string) =>
  async (dispatch: TypedDispatch, getState: () => RootState) => {
    try {
      const { CompanyGroupsReducer } = getState();
      const { county } = CompanyGroupsReducer;
      const response = await getCounties(state, "");
      const updatedCounty = {
        ...county,
        [state]: response,
      };
      dispatch(setCounty(updatedCounty));
    } catch (error) {
      console.log(error);
    }
  };
