import { CancelTokenSource } from "axios";
import { apiClient } from "lib/apiClient";

type ExpenseAnalysisChartValues = {
  value: number;
  percentileRank: number;
  quartile1: number;
  quartile2: number;
  quartile3: number;
};

export type ExpenseAnalysisChartData = {
  revenue: {
    egi: ExpenseAnalysisChartValues;
  };
  activities: {
    nonPayroll: ExpenseAnalysisChartValues;
    payroll: ExpenseAnalysisChartValues;
    total: ExpenseAnalysisChartValues;
  };
  adminMarketing: {
    nonPayroll: ExpenseAnalysisChartValues;
    payroll: ExpenseAnalysisChartValues;
    total: ExpenseAnalysisChartValues;
  };
  dietary: {
    nonPayroll: ExpenseAnalysisChartValues;
    payroll: ExpenseAnalysisChartValues;
    total: ExpenseAnalysisChartValues;
  };
  housekeepingLaundry: {
    nonPayroll: ExpenseAnalysisChartValues;
    payroll: ExpenseAnalysisChartValues;
    total: ExpenseAnalysisChartValues;
  };
  maintenance: {
    nonPayroll: ExpenseAnalysisChartValues;
    payroll: ExpenseAnalysisChartValues;
    total: ExpenseAnalysisChartValues;
  };
  residentCare: {
    nonPayroll: ExpenseAnalysisChartValues;
    payroll: ExpenseAnalysisChartValues;
    total: ExpenseAnalysisChartValues;
  };
  payrollRelated: {
    nonPayroll: ExpenseAnalysisChartValues;
    payroll: ExpenseAnalysisChartValues;
    total: ExpenseAnalysisChartValues;
  };
  other: {
    insuranceTotal: ExpenseAnalysisChartValues;
    utilities: ExpenseAnalysisChartValues;
    propertyTax: ExpenseAnalysisChartValues;
    managementFee: ExpenseAnalysisChartValues;
    bedProviderTax: ExpenseAnalysisChartValues;
    nonPayroll: ExpenseAnalysisChartValues;
    payroll: ExpenseAnalysisChartValues;
  };
  netIncome: {
    noi: ExpenseAnalysisChartValues;
  };
};

export type ExpenseAnalysisData = {
  expenseAnalysisData: ExpenseAnalysisChartData | null;
  reason: string | null;
  count: number | null;
};

export const getExpenseAnalysisChart = async (
  propertyId: string,
  compId: string,
  cancelTokenSource: CancelTokenSource
) => {
  const response = await apiClient.get(
    `/property/${propertyId}/analysis/expense?compId=${compId}`,
    { cancelToken: cancelTokenSource.token }
  );

  return response.data;
};
