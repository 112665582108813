import { ISaleCompInfo } from "features/Comp/interfaces";
import { apiClient } from "lib/apiClient";
import { ICreateSaleComp } from "pages/Comps/components/CreateSaleComp/interfaces";

export interface IEditSaleComp extends ICreateSaleComp {
  id: string;
}

export const editSaleComp = async (
  request: IEditSaleComp
): Promise<ISaleCompInfo> => {
  const response = await apiClient.put(`/comps/sale`, request);

  return response.data;
};
