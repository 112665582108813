import { RootState } from "store/rootReducer";

const getTemplatesData = (state: RootState) => state.templates;

const getTemplatesOptions = (state: RootState) =>
  getTemplatesData(state).templatesOptions;

export const getTemplatesOptionsLoadingState = (state: RootState) =>
  getTemplatesOptions(state).loadingState;

export const getTemplatesOptionsData = (state: RootState) =>
  getTemplatesOptions(state).data;

export const getTemplatesCountData = (state: RootState) =>
  getTemplatesData(state).count;

const getSeniorCompsTemplateData = (state: RootState) =>
  getTemplatesData(state).senior;

const getGroupedTemplateData = (state: RootState) =>
  getTemplatesData(state).groupedTemplates;

const getMemberTemplateData = (state: RootState) =>
  getTemplatesData(state).member;

export const getMemberCompsTemplates = (state: RootState) =>
  getMemberTemplateData(state).data;

export const getMemberTemplatesIsLoading = (state: RootState) =>
  getMemberTemplateData(state).loadingState;

export const getSeniorCompsTemplates = (state: RootState) =>
  getSeniorCompsTemplateData(state).data;

export const getSeniorCompsSortDirection = (state: RootState) =>
  getSeniorCompsTemplateData(state).sortDirection;

export const getSeniorCompsTemplatesIsLoading = (state: RootState) =>
  getSeniorCompsTemplateData(state).loadingState;

export const getGroupedTemplates = (state: RootState) =>
  getGroupedTemplateData(state).data;

export const getGroupedTemplatesIsLoading = (state: RootState) =>
  getGroupedTemplateData(state).loadingState;
