import { apiClient } from "lib/apiClient";
import { ISaleCompTableData } from "./getCompsTableData";
import { IRequestParams } from "pages/Properties/components/PropertiesFilter/PropertiesFilter";
import { CancelTokenSource } from "axios";
import { configureCompTableRequestParams } from "./utils/configureCompTableRequestParams";

export const getCompsTableCount = async (
  compType: string,
  requestParams: IRequestParams | null,
  cancelTokenSource: CancelTokenSource
): Promise<ISaleCompTableData["count"]> => {
  const response = await apiClient.post(
    `/comps/${compType}/export/preview/count`,
    configureCompTableRequestParams(requestParams),
    { cancelToken: cancelTokenSource?.token }
  );
  return response.data;
};
