import { exportComp } from "api/exportComp";
import { toastMessage } from "components/ToastMessage";
import { exportExcel } from "./exportExcel";
import { resolveDate } from "./resolveDate";

export const handleCompExport = async (
  comptype: string,
  compIds: string[],
  propertyName?: string,
  compNumber?: string,
  propertyComps?: boolean
) => {
  try {
    if (compIds.length) {
      const date = resolveDate(new Date(), "MM-dd-yyyy-HH-mm-ss");
      const fileName =
        propertyName && compNumber
          ? `SeniorComps-${propertyName}-${comptype} Comp-${compNumber} ${date}`
          : `SeniorComps-${comptype} ${date}`;
      const { blob, fileName: serverFileName } = await exportComp(
        compIds,
        comptype.toLowerCase(),
        propertyComps
      );
      exportExcel(serverFileName ?? fileName, blob);
    }
  } catch {
    toastMessage({
      message: "Failed to export comp.",
      position: "top-right",
      type: "error",
    });
  }
};
