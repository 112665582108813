import { templatesTranslations } from "../translations";
import { MyTemplates, SeniorCompsTemplates } from "../components";

export const getTabsConfiguration = (isAdmin: boolean) => [
  {
    key: "senior",
    label: templatesTranslations.seniorCompsTemplates,
    component: SeniorCompsTemplates,
  },
  {
    key: "member",
    label: isAdmin
      ? templatesTranslations.memberTemplates
      : templatesTranslations.myTemplates,
    component: MyTemplates,
  },
];
