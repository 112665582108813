import { Button, IconButton, styled, Typography } from "@mui/material";

export const ModalButtonStyled = styled(Button)({
  width: "130px",
});

export const CloseButtonStyled = styled(IconButton)({
  paddingRight: 0,
});

export const AdditionalInfoNode = styled(Typography)(
  ({ theme: { palette } }) => ({
    fontSize: "12px",
    fontWeight: 400,
    color: palette.primary.main,
    opacity: 0.7,
  })
);

export const List = styled("ul")({
  listStyle: "disc",
  marginTop: "10px",
  paddingLeft: "18px",
});
