import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import logoWithTextLight from "assets/images/logoWithTextLight.png";
import { Link } from "react-router-dom";

import styles from "./Footer.module.scss";
import { getYear } from "date-fns";
import { landingWidth } from "config/constants";
import privacyPolicy from "assets/pdf/Privacy_Policy.pdf";
import termsOfUse from "assets/pdf/Terms_of_Use.pdf";

export const Footer = () => {
  const theme = useTheme();
  const currentYear = getYear(new Date());

  return (
    <Box
      component="footer"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: theme.palette.primary.dark,
        padding: `50px calc((100% - ${landingWidth}) /2)`,

        "@media screen and (max-width: 1000px)": {
          p: "50px 30px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "12px",
          p: "0 45px",

          "@media screen and (max-width: 1000px)": {
            p: 0,
          },

          "@media screen and (max-width: 768px)": {
            justifyContent: "center",
          },
        }}
      >
        <Link
          to={process.env.REACT_APP_HOME_LINK ?? ""}
          className={styles.flexLink}
        >
          <img
            src={logoWithTextLight}
            alt="SeniorComps Inc logo with brand name"
          />
        </Link>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",

            "@media screen and (max-width: 768px)": {
              flexDirection: "column",
              gap: "10px",
            },
          }}
        >
          <a href="mailto:info@seniorcomps.com" className={styles.mailLink}>
            info@seniorcomps.com
          </a>
          <Link
            to={termsOfUse}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            Terms & Conditions
          </Link>
          <Link
            to={privacyPolicy}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.link}
          >
            Privacy Policy
          </Link>
        </Box>
      </Box>
      <Typography
        sx={{
          fontSize: "16px",
          fontWeight: "400",
          color: "white",
          display: "inline",
          p: "0 45px",

          "@media screen and (max-width: 1100px)": {
            fontSize: "14px",
          },

          "@media screen and (max-width: 1000px)": {
            whiteSpace: "pre-line",
            p: 0,
          },

          "@media screen and (max-width: 768px)": {
            textAlign: "center",
            fontSize: "12px",
          },
        }}
      >
        {`© Copyright ${currentYear} SeniorComps.
        All rights reserved.`}
      </Typography>
    </Box>
  );
};
