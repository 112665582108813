import { Box, CircularProgress, SxProps, Theme } from "@mui/material";
import React from "react";

export type Props = {
  size?: number;
  styles?: SxProps<Theme>;
  boxStyles?: SxProps<Theme>;
  thickness?: number;
};

export const CustomLoader = ({ styles, boxStyles, size, thickness }: Props) => {
  return (
    <Box sx={{ ...boxStyles }} data-testid="custom-loader">
      <CircularProgress
        thickness={thickness}
        size={size}
        sx={{
          strokeLinecap: "round",
          ...styles,
        }}
      />
    </Box>
  );
};
