const codePrefixLength = 2;

export const splitPhone = (phone: string) => {
  const phonePrefix = phone.substring(0, codePrefixLength);
  const phoneNumber = phone.substring(codePrefixLength, phone.length);
  return {
    phone: phoneNumber,
    phonePrefix,
  };
};
