/* eslint-disable camelcase */
export const templatesTranslations = {
  templates: "Templates",
  templatesFound_one: "Template found",
  templatesFound_other: "Templates found",
  seniorCompsTemplates: "SeniorComps Templates",
  myTemplates: "My Templates",
  memberTemplates: "Member Templates",
  addTemplate: "ADD TEMPLATE",
  addTemplateLowerCase: "Add Template",
  choosePlans: "Choose Plans",

  //Table columns
  companyGroupName: "Company Group Name",
  templateName: "Template Name",
  date: "Date",
  subscriptionType: "Subscription Type",
  maxFileSize: "Upload max. 50MB",
  cancelButtonLabel: "CANCEL",
  uploadButtonLabel: "SAVE",
  failedToCreate: "Your template has not been created. Please try again later",
  successToCreate: "Your Template has been added successfully!",
  successToDelete: "Your Template has been deleted successfully!",
  successToUpdate: "Template plans has been updated successfully!",
  failedToUpdate: "Template plans has not been updated. Please try again later",
  share: "Share",
  shareTemplate: "Share template",
  remove: "Remove",
  somethingWentWrong: "Something went wrong. Please, try again later.",
};
