import { IDealsInfo } from "features/Deals/interfaces";
import { apiClient } from "lib/apiClient";

export const searchByDealName = async (
  paramName: string,
  value: string,
  status = "ACTIVE"
): Promise<IDealsInfo> => {
  const response = await apiClient.get(
    `/deals?${paramName}=${value}&status=${status}`
  );

  return response.data;
};
