import {
  SET_CURRENT_DEAL,
  SET_DEALS,
  SET_DEALS_LOADING,
  SET_DEAL_COMPS_COUNT,
  SET_DEAL_TYPE,
  SET_EMAIL_MODAL_OPEN,
  SET_MODAL_OPEN,
  SET_SUCCESS_DEALS_OPEN,
} from "./DealsActions";
import { Actions, DealTypes, IDealsState } from "./interfaces";

export const initialState: IDealsState = {
  dealsInfo: {
    totalDealsCount: 0,
    totalActiveCount: 0,
    deals: [],
  },
  isModalOpen: {
    deal: false,
    dealProperty: false,
  },
  isLoading: {
    deals: false,
    dealCompsCount: false,
  },
  isSuccessOpen: false,
  isEmailModalOpen: false,
  currentDeal: null,
  dealType: "" as DealTypes,
  dealCompsCount: null,
};

const DealsReducer = (state: IDealsState = initialState, action: Actions) => {
  switch (action.type) {
    case SET_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: { ...state.isModalOpen, ...action.payload },
      };
    case SET_DEALS:
      return { ...state, dealsInfo: action.payload };
    case SET_DEALS_LOADING:
      return { ...state, isLoading: { ...state.isLoading, ...action.payload } };
    case SET_SUCCESS_DEALS_OPEN:
      return { ...state, isSuccessOpen: action.payload };
    case SET_EMAIL_MODAL_OPEN:
      return { ...state, isEmailModalOpen: action.payload };
    case SET_CURRENT_DEAL:
      return { ...state, currentDeal: action.payload };
    case SET_DEAL_TYPE:
      return { ...state, dealType: action.payload };
    case SET_DEAL_COMPS_COUNT:
      return { ...state, dealCompsCount: action.payload };
    default:
      return state;
  }
};

export default DealsReducer;
