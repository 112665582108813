import { apiClient } from "lib/apiClient";
import { removeFileExtension } from "../utils/removeFileExtension";

export interface IExportMyDealsCompsRequestParams {
  priority: string;
  properties: string[];
  recent: boolean;
}

export const exportMyDealsComps = async (
  compType: string,
  requestParams: IExportMyDealsCompsRequestParams
): Promise<{ blob: Blob; fileName?: string }> => {
  const response = await apiClient.post(
    `/export/xlsx/${compType}/properties`,
    requestParams,
    {
      responseType: "blob",
    }
  );
  const contentDisposition = response.headers["content-disposition"];
  const filename = contentDisposition.split("filename=")?.[1];
  return { blob: response.data, fileName: removeFileExtension(filename) };
};
