import { useTypedDispatch } from "store";
import { useAbortPreviousRequest } from "utils/hooks/useAbortPreviousRequest";
import { useSelector } from "react-redux";
import {
  getMemberCompsTemplates,
  getMemberTemplatesIsLoading,
} from "features/Templates/selectors";
import React, { useEffect, useState } from "react";
import {
  deleteTemplates,
  getMemberTemplates,
  getTemplatesCount,
} from "features/Templates/controllers";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { ReactComponent as TrashIcon } from "assets/svg/smallSecondaryButtonIcons/trashIcon.svg";
import { templatesTranslations } from "../../translations";
import styles from "./MyTemplates.module.scss";
import { getSeniorCompTableHeaders } from "../../helpers/getSeniorCompTableHeaders";
import { LOADING_STATE } from "types/general";
import { CustomLoader } from "components/CustomLoader";
import { TemplateTableRow } from "../TemplateTableRow";
import { LOADER_BOX_STYLE } from "../constants";
import { toastMessage } from "components/ToastMessage";
import { SuccessModal } from "components/SuccessModal";
import successChecked from "assets/images/successChecked.png";

export const MemberTemplates = () => {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [checkedTemplates, setCheckedTemplates] = useState<Array<string>>([]);
  const dispatch = useTypedDispatch();
  const { abortPreviousRequest } = useAbortPreviousRequest();
  const data = useSelector(getMemberCompsTemplates);
  const loadingState = useSelector(getMemberTemplatesIsLoading);

  useEffect(() => {
    const { signal } = abortPreviousRequest();
    dispatch(getMemberTemplates({ abortSignal: signal }));
  }, []);

  const handleCloseSuccessModal = () => {
    setIsSuccessModalOpen(false);
  };

  const handleCheckTemplate = (templateId: string) => {
    const isTemplateAlreadyChecked = checkedTemplates.includes(templateId);
    setCheckedTemplates((prevState) =>
      isTemplateAlreadyChecked
        ? prevState.filter(
            (checkedTemplateId) => checkedTemplateId !== templateId
          )
        : [...prevState, templateId]
    );
  };

  const handleDeleteTemplates = () => {
    dispatch(deleteTemplates(checkedTemplates)).then((resultAction) => {
      if (deleteTemplates.rejected.match(resultAction)) {
        toastMessage({
          message: templatesTranslations.somethingWentWrong,
          position: "top-right",
          type: "error",
        });
      } else {
        setIsSuccessModalOpen(true);
      }

      dispatch(getTemplatesCount());
      dispatch(getMemberTemplates({}));
      setCheckedTemplates([]);
    });
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mb={3}>
        <Button
          disabled={!checkedTemplates.length}
          onClick={handleDeleteTemplates}
          classes="outlined"
          startIcon={<TrashIcon />}
          size="small"
        >
          {templatesTranslations.remove}
        </Button>
      </Box>
      <Table className={styles.tableWrapper}>
        <TableHead>
          <TableRow>
            {getSeniorCompTableHeaders(false).map((item) => (
              <TableCell key={item} colSpan={1}>
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody sx={{ position: "relative" }}>
          {loadingState === LOADING_STATE.pending ? (
            <CustomLoader
              styles={{
                color: "#E3E3E3",
                opacity: 0.6,
              }}
              boxStyles={LOADER_BOX_STYLE}
            />
          ) : (
            data?.map((item) => {
              return (
                <TemplateTableRow
                  key={item.id}
                  isAdminUser={false}
                  data={item}
                  checkedTemplates={checkedTemplates}
                  handleCheckTemplate={handleCheckTemplate}
                  isAbleToRemove
                />
              );
            })
          )}
        </TableBody>
      </Table>
      {isSuccessModalOpen && (
        <SuccessModal
          title={templatesTranslations.successToDelete}
          icon={successChecked}
          open
          handleClose={handleCloseSuccessModal}
        />
      )}
    </>
  );
};
