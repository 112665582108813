import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient } from "lib/apiClient";
import { TServiceError } from "types/general";
import { TGroupedTemplates, TSubscriptionPlan, TTemplate } from "./types";
import { TEMPLATES_SLICE_NAME } from "./constants";
import { SORT_DIRECTION } from "../../config/constants";
import { getSeniorCompsSortDirection } from "./selectors";
import { RootState } from "../../store/rootReducer";

export const exportPropertiesToTemplate = createAsyncThunk<
  { fileLink: string },
  {
    compType: string;
    templateId: string;
    priority: string;
    properties: string[];
    recent: boolean;
  },
  { rejectValue: TServiceError }
>(
  `${TEMPLATES_SLICE_NAME}/exportPropertiesToTemplate`,
  async ({ compType, ...restData }, { rejectWithValue }) => {
    try {
      const templateExport = await apiClient.post(
        `/export/xlsx/${compType}/template/properties`,
        restData
      );

      return templateExport.data;
    } catch (err) {
      return rejectWithValue({
        code: err.code,
        message: err.response?.data ?? err.message,
      });
    }
  }
);
export const exportAllComps = createAsyncThunk<
  void,
  {
    compType: string;
    templateId?: string;
    priority: string;
    recent: boolean;
  },
  { rejectValue: TServiceError }
>(
  `${TEMPLATES_SLICE_NAME}/exportAllComps`,
  async ({ compType, ...restData }, { rejectWithValue }) => {
    try {
      const templateExport = await apiClient.post(
        `/export/xlsx/${compType}/all`,
        restData
      );

      return templateExport.data;
    } catch (err) {
      return rejectWithValue({
        code: err.code,
        message: err.response?.data ?? err.message,
      });
    }
  }
);

export const exportCompsToTemplate = createAsyncThunk<
  { fileLink: string },
  {
    templateId: string;
    compIds: string[];
    compType: string;
  },
  { rejectValue: TServiceError }
>(
  `${TEMPLATES_SLICE_NAME}/exportCompsToTemplate`,
  async ({ compType, ...restData }, { rejectWithValue }) => {
    try {
      const templateExport = await apiClient.post(
        `/export/xlsx/${compType}/template`,
        restData
      );

      return templateExport.data;
    } catch (err) {
      return rejectWithValue({
        code: err.code,
        message: err.response?.data ?? err.message,
      });
    }
  }
);

export const getTemplatesOptions = createAsyncThunk<
  TTemplate[],
  void,
  { rejectValue: TServiceError }
>(
  `${TEMPLATES_SLICE_NAME}/getTemplatesOptions`,
  async (_, { rejectWithValue }) => {
    try {
      const templatesResponse = await apiClient.get("/templates/titles");

      return templatesResponse.data;
    } catch (err) {
      return rejectWithValue({
        code: err.code,
        message: err.message,
      });
    }
  }
);

export const getTemplatesCount = createAsyncThunk<
  {
    seniorTemplatesCount: number;
    memberTemplatesCount: number;
  },
  void,
  { rejectValue: TServiceError }
>(
  `${TEMPLATES_SLICE_NAME}/getTemplatesCount`,
  async (_, { rejectWithValue }) => {
    try {
      const templatesCountResponse = await apiClient.get("/templates/count");

      return templatesCountResponse.data;
    } catch (err) {
      return rejectWithValue({
        code: err.code,
        message: err.message,
      });
    }
  }
);

export const getSeniorTemplates = createAsyncThunk<
  TTemplate[],
  {
    abortSignal?: AbortSignal;
    sortDirection?: (typeof SORT_DIRECTION)[string];
  },
  { rejectValue: TServiceError; state: RootState }
>(
  `${TEMPLATES_SLICE_NAME}/getSeniorTemplates`,
  async ({ abortSignal, sortDirection }, { rejectWithValue, getState }) => {
    try {
      const appliedDirection = getSeniorCompsSortDirection(getState());
      const seniorCompsResponse = await apiClient.get("/templates/senior", {
        signal: abortSignal,
        params: {
          sortBy: `${
            (sortDirection ?? appliedDirection) === SORT_DIRECTION.asc
              ? ""
              : "-"
          }title`,
        },
      });

      return seniorCompsResponse.data;
    } catch (err) {
      return rejectWithValue({
        code: err.code,
        message: err.message,
      });
    }
  }
);

export const createTemplate = createAsyncThunk<
  void,
  {
    file: FormData;
  },
  { rejectValue: TServiceError }
>(
  `${TEMPLATES_SLICE_NAME}/createTemplate`,
  async ({ file }, { rejectWithValue }) => {
    try {
      const result = await apiClient.post("/templates", file);

      return result.data;
    } catch (err) {
      return rejectWithValue({
        code: err.code,
        message: err.response?.data ?? err.message,
      });
    }
  }
);

export const deleteTemplates = createAsyncThunk<
  unknown,
  Array<string>,
  { rejectValue: TServiceError }
>(
  `${TEMPLATES_SLICE_NAME}/deleteTemplates`,
  async (templateIds, { rejectWithValue }) => {
    try {
      const result = await Promise.allSettled(
        templateIds.map(async (templateId) => {
          return await apiClient.delete(`/templates/${templateId}`);
        })
      );
      return result;
    } catch (err) {
      return rejectWithValue({
        code: err.code,
        message: err.response?.data ?? err.message,
      });
    }
  }
);

export const getGroupedMemberTemplates = createAsyncThunk<
  TGroupedTemplates,
  {
    abortSignal?: AbortSignal;
  },
  { rejectValue: TServiceError }
>(
  `${TEMPLATES_SLICE_NAME}/getGroupedMemberTemplates`,
  async ({ abortSignal }, { rejectWithValue }) => {
    try {
      const groupedResponse = await apiClient.get("/templates/member/grouped", {
        signal: abortSignal,
      });

      return groupedResponse.data;
    } catch (err) {
      return rejectWithValue({
        code: err.code,
        message: err.message,
      });
    }
  }
);

export const getMemberTemplates = createAsyncThunk<
  TTemplate[],
  {
    abortSignal?: AbortSignal;
  },
  { rejectValue: TServiceError }
>(
  `${TEMPLATES_SLICE_NAME}/getMemberTemplates`,
  async ({ abortSignal }, { rejectWithValue }) => {
    try {
      const groupedResponse = await apiClient.get("/templates/member", {
        signal: abortSignal,
      });

      return groupedResponse.data;
    } catch (err) {
      return rejectWithValue({
        code: err.code,
        message: err.message,
      });
    }
  }
);

export const updateTemplateSubscriptions = createAsyncThunk<
  void,
  {
    subscriptions: TSubscriptionPlan[];
    templateId: string;
  },
  { rejectValue: TServiceError }
>(
  `${TEMPLATES_SLICE_NAME}/updateTemplateSubscriptions`,
  async ({ subscriptions, templateId }, { rejectWithValue }) => {
    try {
      const result = await apiClient.put(
        `/templates/${templateId}/subscriptions`,
        subscriptions
      );

      return result.data;
    } catch (err) {
      return rejectWithValue({
        code: err.code,
        message: err.response?.data ?? err.message,
      });
    }
  }
);
