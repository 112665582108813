export const jpeg = "image/jpeg";
export const png = "image/png";
export const pdf = "application/pdf";
export const xlsx =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const xls = "application/vnd.ms-excel";
export const xlsm = "application/vnd.ms-excel.sheet.macroenabled.12";
export const xlsb = "application/vnd.ms-excel.sheet.binary.macroenabled.12";
export const csv = "text/csv";

export const fileExtensions = [jpeg, png, pdf];
export const xlsFileExtensions = [xlsx, csv];
export const bytesLimit = 52428800;
export const errorFileSize = "Upload failed: File is too large";
export const sortedKey = "order";
