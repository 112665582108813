import React, { ReactNode } from "react";
import { StyledContainer } from "./EntityWrapper.styled";

export const EntityWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <StyledContainer display="flex" flexDirection="column">
      {children}
    </StyledContainer>
  );
};
