import { apiClient } from "lib/apiClient";

export const getCounties = async (
  state: string,
  searchedValue: string
): Promise<string[]> => {
  const countyQuery = searchedValue ? `&countyName=${searchedValue}` : "";
  const response = await apiClient.get(
    `/geography/usa/counties?stateCode=${state}${countyQuery}`
  );

  return response.data;
};
