import { COMP_TYPES } from "config/constants";
import { CompFormStatusEnum, ICompState } from "./interfaces";

export const initialState: ICompState = {
  compType: "",
  compSubType: "senior",
  compProperties: [],
  requestedCompProperties: {
    sale: [],
    rent: [],
    expense: [],
  },
  isCompOpen: false,
  createCompFormStatus: {
    type: COMP_TYPES.rent,
    status: CompFormStatusEnum.NONE,
  },
  editFormStatus: {
    type: COMP_TYPES.rent,
    status: CompFormStatusEnum.NONE,
    id: null,
  },
  copyFormStatus: {
    type: COMP_TYPES.rent,
    status: CompFormStatusEnum.NONE,
    id: null,
  },
  unsavedForm: null,
  propertiesOptions: [],
  compAttachments: {
    sale: [],
    rent: [],
    expense: [],
  },
  successModalMessage: "",
  compInfo: {
    sale: {
      companyName: "",
      firstName: "",
      lastName: "",
      id: "",
      name: "",
      ownerRelatedStatus: "",
      closingDate: "",
      price: 0,
      properties: [],
      defaultProperty: "",
      participantData: {
        sellerProfitType: null,
        buyerLegalName: null,
        buyerTrueName: null,
        participantPhone: null,
        participantCompanyName: null,
        sellerLegalName: null,
        buyerProfitType: null,
        participantName: null,
        participantEmail: null,
        sellerTrueName: null,
        verificationSources: [],
      },
      transactionData: {
        inPlaceReservesPerUnitBed: 0,
        assetsType: "",
        propertyRights: "",
        buyerReservesPerUnitBed: 0,
        inPlaceExpenses: 0,
        buyerManagementFees: 0,
        inPlaceRevenues: 0,
        inPlaceManagementFees: 0,
        sellerExpenses: 0,
        sellerReservesPerUnitBed: 0,
        inPlaceOccupancy: null,
        buyerExpenses: 0,
        buyerRevenues: 0,
        buyerOccupancy: null,
        transactionComment: null,
        saleConditions: "",
        propertyCondition: "",
        financing: "",
        sellerManagementFees: 0,
        sellerOccupancy: null,
        sellerRevenues: 0,
      },
      serviceData: {
        activeAdultUnits: null,
        assistedLivingUnits: null,
        memoryCareBeds: null,
        memoryCareUnits: null,
        independentLivingUnits: null,
        skilledNursingUnits: null,
        assistedLivingBeds: null,
        independentLivingBeds: null,
        skilledNursingBeds: null,
      },
      saleType: "",
      permissions: [],
      createdByAdmin: false,
      activeAdultOffered: false,
      assistedLiving: false,
      independentLivingOffered: false,
      memoryCareOffered: false,
      skilledNursing: false,
      internalNotes: null,
    },
    rent: {
      comment: "",
      internalNotes: null,
      companyName: "",
      contactCompanyName: null,
      contactPersonName: null,
      createdByAdmin: false,
      email: null,
      firstName: "",
      id: "",
      lastName: "",
      name: "",
      ownerRelatedStatus: "",
      permissions: [],
      phone: null,
      propertyId: "",
      roomRates: {
        wardAssistedLivingMin: "",
        wardAssistedLivingMax: "",
        wardMemoryCareMin: "",
        wardMemoryCareMax: "",
        wardSkilledNursingMin: "",
        wardSkilledNursingMax: "",
        semiPrivateAssistedLivingMin: "",
        semiPrivateAssistedLivingMax: "",
        semiPrivateMemoryCareMin: "",
        semiPrivateMemoryCareMax: "",
        semiPrivateSkilledNursingMin: "",
        semiPrivateSkilledNursingMax: "",
        studioActiveAdultMin: "",
        studioActiveAdultMax: "",
        studioIndependentLivingMin: "",
        studioIndependentLivingMax: "",
        studioAssistedLivingMin: "",
        studioAssistedLivingMax: "",
        studioMemoryCareMin: "",
        studioMemoryCareMax: "",
        studioSkilledNursingMin: "",
        studioSkilledNursingMax: "",
        oneBedroomActiveAdultMin: "",
        oneBedroomActiveAdultMax: "",
        oneBedroomIndependentLivingMin: "",
        oneBedroomIndependentLivingMax: "",
        oneBedroomAssistedLivingMin: "",
        oneBedroomAssistedLivingMax: "",
        oneBedroomMemoryCareMin: "",
        oneBedroomMemoryCareMax: "",
        twoBedroomActiveAdultMin: "",
        twoBedroomActiveAdultMax: "",
        twoBedroomIndependentLivingMin: "",
        twoBedroomIndependentLivingMax: "",
        twoBedroomAssistedLivingMin: "",
        twoBedroomAssistedLivingMax: "",
        twoBedroomMemoryCareMin: "",
        twoBedroomMemoryCareMax: "",
        threeBedroomActiveAdultMin: "",
        threeBedroomActiveAdultMax: "",
        threeBedroomIndependentLivingMin: "",
        threeBedroomIndependentLivingMax: "",
        threeBedroomAssistedLivingMin: "",
        threeBedroomAssistedLivingMax: "",
        threeBedroomMemoryCareMin: "",
        threeBedroomMemoryCareMax: "",
        cottageActiveAdultMin: "",
        cottageActiveAdultMax: "",
        cottageIndependentLivingMin: "",
        cottageIndependentLivingMax: "",
        cottageAssistedLivingMin: "",
        cottageAssistedLivingMax: "",
        cottageMemoryCareMin: "",
        cottageMemoryCareMax: "",
        secondPersonActiveAdultMin: "",
        secondPersonActiveAdultMax: "",
        secondPersonIndependentLivingMin: "",
        secondPersonIndependentLivingMax: "",
        secondPersonAssistedLivingMin: "",
        secondPersonAssistedLivingMax: "",
        secondPersonMemoryCareMin: "",
        secondPersonMemoryCareMax: "",
        communityFeeActiveAdultMin: "",
        communityFeeActiveAdultMax: "",
        communityFeeIndependentLivingMin: "",
        communityFeeIndependentLivingMax: "",
        communityFeeAssistedLivingMin: "",
        communityFeeAssistedLivingMax: "",
        communityFeeMemoryCareMin: "",
        communityFeeMemoryCareMax: "",
        additionalCareAssistedLivingMin: "",
        additionalCareAssistedLivingMax: "",
        additionalCareMemoryCareMin: "",
        additionalCareMemoryCareMax: "",
        careFeeStructureAssistedLiving: "",
        careFeeStructureMemoryCare: "",

        incentivesActiveAdultMin: "",
        incentivesActiveAdultMax: "",
        incentivesIndependentLivingMin: "",
        incentivesIndependentLivingMax: "",
        incentivesAssistedLivingMin: "",
        incentivesAssistedLivingMax: "",
        incentivesMemoryCareMin: "",
        incentivesMemoryCareMax: "",
      },
      feePlans: {
        firstPlanContractTermsType: "",
        firstPlanContractTermsRefund: "",
        firstPlanContractTermsAmortPeriod: "",
        firstPlanContractTermsAdminFee: "",
        firstPlanStudioEntranceFeeMin: "",
        firstPlanStudioEntranceFeeMax: "",
        firstPlanOneBedroomEntranceFeeMin: "",
        firstPlanOneBedroomEntranceFeeMax: "",
        firstPlanTwoBedroomEntranceFeeMin: "",
        firstPlanTwoBedroomEntranceFeeMax: "",
        firstPlanThreeBedroomEntranceFeeMin: "",
        firstPlanThreeBedroomEntranceFeeMax: "",
        firstPlanCottageEntranceFeeMin: "",
        firstPlanCottageEntranceFeeMax: "",
        firstPlanSecondPersonEntranceFeeMin: "",
        firstPlanSecondPersonEntranceFeeMax: "",
        firstPlanStudioMonthlyFeeMin: "",
        firstPlanStudioMonthlyFeeMax: "",
        firstPlanOneBedroomMonthlyFeeMin: "",
        firstPlanOneBedroomMonthlyFeeMax: "",
        firstPlanTwoBedroomMonthlyFeeMin: "",
        firstPlanTwoBedroomMonthlyFeeMax: "",
        firstPlanThreeBedroomMonthlyFeeMin: "",
        firstPlanThreeBedroomMonthlyFeeMax: "",
        firstPlanCottageMonthlyFeeMin: "",
        firstPlanCottageMonthlyFeeMax: "",
        firstPlanSecondPersonMonthlyFeeMin: "",
        firstPlanSecondPersonMonthlyFeeMax: "",
        firstPlanNotes: "",
        secondPlanContractTermsType: "",
        secondPlanContractTermsRefund: "",
        secondPlanContractTermsAmortPeriod: "",
        secondPlanContractTermsAdminFee: "",
        secondPlanStudioEntranceFeeMin: "",
        secondPlanStudioEntranceFeeMax: "",
        secondPlanOneBedroomEntranceFeeMin: "",
        secondPlanOneBedroomEntranceFeeMax: "",
        secondPlanTwoBedroomEntranceFeeMin: "",
        secondPlanTwoBedroomEntranceFeeMax: "",
        secondPlanThreeBedroomEntranceFeeMin: "",
        secondPlanThreeBedroomEntranceFeeMax: "",
        secondPlanCottageEntranceFeeMin: "",
        secondPlanCottageEntranceFeeMax: "",
        secondPlanSecondPersonEntranceFeeMin: "",
        secondPlanSecondPersonEntranceFeeMax: "",
        secondPlanStudioMonthlyFeeMin: "",
        secondPlanStudioMonthlyFeeMax: "",
        secondPlanOneBedroomMonthlyFeeMin: "",
        secondPlanOneBedroomMonthlyFeeMax: "",
        secondPlanTwoBedroomMonthlyFeeMin: "",
        secondPlanTwoBedroomMonthlyFeeMax: "",
        secondPlanThreeBedroomMonthlyFeeMin: "",
        secondPlanThreeBedroomMonthlyFeeMax: "",
        secondPlanCottageMonthlyFeeMin: "",
        secondPlanCottageMonthlyFeeMax: "",
        secondPlanSecondPersonMonthlyFeeMin: "",
        secondPlanSecondPersonMonthlyFeeMax: "",
        secondPlanNotes: "",
        thirdPlanContractTermsType: "",
        thirdPlanContractTermsRefund: "",
        thirdPlanContractTermsAmortPeriod: "",
        thirdPlanContractTermsAdminFee: "",
        thirdPlanStudioEntranceFeeMin: "",
        thirdPlanStudioEntranceFeeMax: "",
        thirdPlanOneBedroomEntranceFeeMin: "",
        thirdPlanOneBedroomEntranceFeeMax: "",
        thirdPlanTwoBedroomEntranceFeeMin: "",
        thirdPlanTwoBedroomEntranceFeeMax: "",
        thirdPlanThreeBedroomEntranceFeeMin: "",
        thirdPlanThreeBedroomEntranceFeeMax: "",
        thirdPlanCottageEntranceFeeMin: "",
        thirdPlanCottageEntranceFeeMax: "",
        thirdPlanSecondPersonEntranceFeeMin: "",
        thirdPlanSecondPersonEntranceFeeMax: "",
        thirdPlanStudioMonthlyFeeMin: "",
        thirdPlanStudioMonthlyFeeMax: "",
        thirdPlanOneBedroomMonthlyFeeMin: "",
        thirdPlanOneBedroomMonthlyFeeMax: "",
        thirdPlanTwoBedroomMonthlyFeeMin: "",
        thirdPlanTwoBedroomMonthlyFeeMax: "",
        thirdPlanThreeBedroomMonthlyFeeMin: "",
        thirdPlanThreeBedroomMonthlyFeeMax: "",
        thirdPlanCottageMonthlyFeeMin: "",
        thirdPlanCottageMonthlyFeeMax: "",
        thirdPlanSecondPersonMonthlyFeeMin: "",
        thirdPlanSecondPersonMonthlyFeeMax: "",
        thirdPlanNotes: "",
      },
      roomSizes: {
        sizesWardAssistedLivingMin: "",
        sizesWardAssistedLivingMax: "",
        sizesWardMemoryCareMin: "",
        sizesWardMemoryCareMax: "",
        sizesWardSkilledNursingMin: "",
        sizesWardSkilledNursingMax: "",
        sizesSemiPrivateAssistedLivingMin: "",
        sizesSemiPrivateAssistedLivingMax: "",
        sizesSemiPrivateMemoryCareMin: "",
        sizesSemiPrivateMemoryCareMax: "",
        sizesSemiPrivateSkilledNursingMin: "",
        sizesSemiPrivateSkilledNursingMax: "",
        sizesStudioActiveAdultMin: "",
        sizesStudioActiveAdultMax: "",
        sizesStudioIndependentLivingMin: "",
        sizesStudioIndependentLivingMax: "",
        sizesStudioAssistedLivingMin: "",
        sizesStudioAssistedLivingMax: "",
        sizesStudioMemoryCareMin: "",
        sizesStudioMemoryCareMax: "",
        sizesStudioSkilledNursingMin: "",
        sizesStudioSkilledNursingMax: "",
        sizesOneBedroomActiveAdultMin: "",
        sizesOneBedroomActiveAdultMax: "",
        sizesOneBedroomIndependentLivingMin: "",
        sizesOneBedroomIndependentLivingMax: "",
        sizesOneBedroomAssistedLivingMin: "",
        sizesOneBedroomAssistedLivingMax: "",
        sizesOneBedroomMemoryCareMin: "",
        sizesOneBedroomMemoryCareMax: "",
        sizesTwoBedroomActiveAdultMin: "",
        sizesTwoBedroomActiveAdultMax: "",
        sizesTwoBedroomIndependentLivingMin: "",
        sizesTwoBedroomIndependentLivingMax: "",
        sizesTwoBedroomAssistedLivingMin: "",
        sizesTwoBedroomAssistedLivingMax: "",
        sizesTwoBedroomMemoryCareMin: "",
        sizesTwoBedroomMemoryCareMax: "",
        sizesThreeBedroomActiveAdultMin: "",
        sizesThreeBedroomActiveAdultMax: "",
        sizesThreeBedroomIndependentLivingMin: "",
        sizesThreeBedroomIndependentLivingMax: "",
        sizesThreeBedroomAssistedLivingMin: "",
        sizesThreeBedroomAssistedLivingMax: "",
        sizesThreeBedroomMemoryCareMin: "",
        sizesThreeBedroomMemoryCareMax: "",
        sizesCottageActiveAdultMin: "",
        sizesCottageActiveAdultMax: "",
        sizesCottageIndependentLivingMin: "",
        sizesCottageIndependentLivingMax: "",
        sizesCottageAssistedLivingMin: "",
        sizesCottageAssistedLivingMax: "",
        sizesCottageMemoryCareMin: "",
        sizesCottageMemoryCareMax: "",
      },
      servicesAndOccupancy: {
        activeAdultOfferedUnits: "",
        activeAdultOccupancy: "",
        independentLivingUnits: "",
        independentLivingOccupancy: "",
        assistedLivingUnits: "",
        assistedLivingBeds: "",
        assistedLivingOccupancy: "",
        assistedLivingPrivate: "",
        assistedLivingMedicaid: "",
        memoryCareUnits: "",
        memoryCareBeds: "",
        memoryCareOccupancy: "",
        memoryCarePrivate: "",
        memoryCareMedicaid: "",
        skilledNursingUnits: "",
        skilledNursingBeds: "",
        skilledNursingOccupancy: "",
        skilledNursingPrivate: "",
        skilledNursingMedicaid: "",
        skilledNursingMedicare: "",
        skilledNursingInsurance: "",
        skilledNursingOther: "",
      },
      verificationDate: "",
      verificationSource: "",
      rentStatus: "",
    },
    expense: {
      companyName: null,
      firstName: null,
      lastName: null,
      id: "",
      name: "",
      financialType: 0,
      financialYear: 0,
      performance: "",
      oer: 0,
      mixData: {
        residentDaysMedicaidPayorMix: null,
        capacityILUnitMix: null,
        residentDaysSNOccupancyMix: null,
        capacityALUnitMix: null,
        residentDaysMCOccupancyMix: null,
        residentDaysAAOccupancyMix: null,
        residentDaysPrivatePayorMix: null,
        residentDaysMedicarePayorMix: null,
        residentDaysInsurancePayorMix: null,
        capacityMCUnitMix: null,
        capacitySNUnitMix: null,
        residentDaysOtherPayorMix: null,
        residentDaysILOccupancyMix: null,
        capacityAAUnitMix: null,
        residentDaysALOccupancyMix: null,
      },
      revenuesData: {
        totalAARevenues: null,
        totalCommunityFeesRevenues: null,
        totalSecondOccupancyRevenues: null,
        totalMCRevenues: null,
        totalCareFeesRevenues: null,
        totalCollectionLossRevenues: null,
        totalSNRevenues: null,
        totalILRevenues: null,
        totalALRevenues: null,
        totalOtherRevenues: null,
      },
      expensesData: {
        payrollMarketingExpenses: null,
        totalPropertyTaxesExpenses: null,
        totalPayrollTaxesExpenses: null,
        nonPayrollDietaryExpenses: null,
        payrollOthersExpenses: null,
        totalUtilitiesExpenses: null,
        totalActivitiesExpenses: null,
        payrollAdministrativeExpenses: null,
        totalMarketingExpenses: null,
        totalEmployeeBenefitsExpenses: null,
        totalLiabilityInsuranceExpenses: null,
        totalOtherInsuranceExpenses: null,
        totalDietaryExpenses: null,
        payrollActivitiesExpenses: null,
        totalWorkersCompensationExpenses: null,
        nonPayrollResidentCareExpenses: null,
        totalHousekeepingExpenses: null,
        totalManagementFeesExpenses: null,
        managementFeesExpenses: null,
        payrollResidentCareExpenses: null,
        totalPropertyInsuranceExpenses: null,
        nonPayrollAdministrativeExpenses: null,
        nonPayrollMaintenanceExpenses: null,
        payrollHousekeepingExpenses: null,
        payrollDietaryExpenses: null,
        reservesExpenses: null,
        totalOthersExpenses: null,
        totalBedProviderTaxesExpenses: null,
        nonPayrollHousekeepingExpenses: null,
        nonPayrollActivitiesExpenses: null,
        totalReservesExpenses: null,
        totalResidentCareExpenses: null,
        nonPayrollMarketingExpenses: null,
        totalAdministrativeExpenses: null,
        totalMaintenanceExpenses: null,
        payrollMaintenanceExpenses: null,
        nonPayrollOthersExpenses: null,
      },
      comment: null,
      internalNotes: null,
      ownerRelatedStatus: "",
      propertyId: "",
      permissions: [],
      createdByAdmin: false,
    },
  },
  isSuccessOpen: false,
  isLoading: {
    sale: false,
    rent: false,
    expense: false,
    compsExport: false,
    compPrice: false,
    pricingAnalysis: false,
    pricingAnalysisConfig: false,
    expenseAnalysisConfig: false,
    expenseAnalysisCompCount: false,
    expenseAnalysisData: false,
    propertiesOptions: false,
    filtredMyDealsCompsExport: false,
    rentCompAttachments: false,
    saleCompAttachments: false,
    expenseCompAttachments: false,
  },
  tableCompsData: {
    sale: null,
    rent: null,
    expense: null,
  },
  reviewCompsData: {
    sale: null,
    rent: null,
    expense: null,
    membership: "",
  },
  compPrice: {
    SALE: 30,
    RENT: 40,
    EXPENSE: 50,
  },
  sortCompBy: {
    value: "",
    direction: "",
  },
  cancelToken: {
    sale: null,
    rent: null,
    expense: null,
    compPrice: null,
  },
  pricingAnalysis: null,
  pricingAnalysisConfig: [],
  expenseAnalysisCompCount: null,
  expenseAnalysisConfig: null,
  expenseAnalysisData: null,
  isAdjustmentMode: true,
};
