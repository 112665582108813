import { deletedAccountError, disabledAccountError } from "config/constants";

const attemptsExceeded = "Password attempts exceeded";
const incorrectPassword = "Incorrect username or password.";
const nonExistentUser = "User does not exist.";

type SignInErrorsType = {
  [key: string]: {
    type: string;
    message: string;
  };
};

export const signInErrors: SignInErrorsType = {
  [disabledAccountError]: {
    type: "",
    message: "",
  },
  [deletedAccountError]: {
    type: "deleted",
    message: "Incorrect e–mail or password, please retry",
  },
  [attemptsExceeded]: {
    type: "forbidden",
    message: "Too many failed log in attempts. Please try again in 15 minutes",
  },
  [incorrectPassword]: {
    type: "incorrectPassword",
    message: "Incorrect e–mail or password, please retry",
  },
  [nonExistentUser]: {
    type: "nonExistentUser",
    message: "Incorrect e–mail or password, please retry",
  },
};
