import { createSlice } from "@reduxjs/toolkit";
import { LOADING_STATE } from "types/general";

import { TEMPLATES_SLICE_NAME, initialState } from "./constants";
import {
  getGroupedMemberTemplates,
  getMemberTemplates,
  getSeniorTemplates,
  getTemplatesCount,
  getTemplatesOptions,
} from "./controllers";

const templatesSlice = createSlice({
  name: TEMPLATES_SLICE_NAME,
  initialState,
  reducers: {
    resetTemplatesData: () => initialState,
    resetTemplatesOptions: (state) => {
      state.templatesOptions = initialState.templatesOptions;
    },
    updateSortDirection: (state, { payload }) => {
      state.senior.sortDirection = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSeniorTemplates.pending, ({ senior }) => {
        senior.loadingState = LOADING_STATE.pending;
      })
      .addCase(getSeniorTemplates.rejected, ({ senior }) => {
        senior.loadingState = LOADING_STATE.rejected;
      })
      .addCase(getSeniorTemplates.fulfilled, ({ senior }, { payload }) => {
        senior.data = payload;
        senior.loadingState = LOADING_STATE.fulfilled;
      })
      .addCase(getTemplatesOptions.pending, ({ templatesOptions }) => {
        templatesOptions.loadingState = LOADING_STATE.pending;
      })
      .addCase(getTemplatesOptions.rejected, ({ templatesOptions }) => {
        templatesOptions.loadingState = LOADING_STATE.rejected;
      })
      .addCase(
        getTemplatesOptions.fulfilled,
        ({ templatesOptions }, { payload }) => {
          templatesOptions.data = payload;
          templatesOptions.loadingState = LOADING_STATE.fulfilled;
        }
      )
      .addCase(getMemberTemplates.pending, ({ member }) => {
        member.loadingState = LOADING_STATE.pending;
      })
      .addCase(getMemberTemplates.rejected, ({ member }) => {
        member.loadingState = LOADING_STATE.rejected;
      })
      .addCase(getMemberTemplates.fulfilled, ({ member }, { payload }) => {
        member.data = payload;
        member.loadingState = LOADING_STATE.fulfilled;
      })
      .addCase(getGroupedMemberTemplates.pending, ({ groupedTemplates }) => {
        groupedTemplates.loadingState = LOADING_STATE.pending;
      })
      .addCase(getGroupedMemberTemplates.rejected, ({ groupedTemplates }) => {
        groupedTemplates.loadingState = LOADING_STATE.rejected;
      })
      .addCase(
        getGroupedMemberTemplates.fulfilled,
        ({ groupedTemplates }, { payload }) => {
          groupedTemplates.data = payload;
          groupedTemplates.loadingState = LOADING_STATE.fulfilled;
        }
      )
      .addCase(
        getTemplatesCount.fulfilled,
        (
          state,
          { payload: { seniorTemplatesCount, memberTemplatesCount } }
        ) => {
          state.count = {
            seniorTemplatesCount: seniorTemplatesCount ?? 0,
            memberTemplatesCount: memberTemplatesCount ?? 0,
          };
        }
      );
  },
});

export const {
  resetTemplatesData,
  resetTemplatesOptions,
  updateSortDirection,
} = templatesSlice.actions;

export default templatesSlice;
