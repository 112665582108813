import { apiClient } from "lib/apiClient";
import { IPropertyRequestData } from "pages/Properties/interfaces";

export type IPropertyResponse = {
  id: string;
  name: string;
  phonePrefix: string;
  phone: string;
  website: string;
  progressStatus: string;
  operator: string;
  profitType: string;
  latitude: number;
  longitude: number;
  country: string;
  state: string;
  county: string;
  region: string;
  city: string;
  street: string;
  zipCode: string;
  marketType: string;
  storeys: number;
  buildingSizeSfTotal: number;
  yearOpened: number;
  expansionOrRenovationHistory: string;
  primaryPaymentType: string;
  activeAdultOffered: boolean;
  independentLivingOffered: boolean;
  memoryCareOffered: boolean;
  skilledNursing: boolean;
  licensedBedsAl: number;
  licensedBedsMc: number;
  licensedBedsSn: number;
  notes: string;
  creationDate: string;
  lastModifiedDate: string;
};
export const addProperty = async (
  request: IPropertyRequestData
): Promise<IPropertyResponse> => {
  const response = await apiClient.post(`/properties`, request);

  return response.data;
};
