import { apiClient } from "lib/apiClient";
import { IPropertyInfo } from "pages/Properties/interfaces";
import { IRequestParams } from "pages/Properties/components/PropertiesFilter/PropertiesFilter";
import { ISortProperty } from "features/Property/interfaces";
import { CancelTokenSource } from "axios";
import { configureFilterParamsForRequest } from "./utils/configureFilterParamsForRequest";

export type IPropertyResults = {
  active: number;
  disabled: number;
  objects: IPropertyInfo[];
  totalForPages: number;
};

export const getFilteredMapProperties = async (
  requestParams: IRequestParams,
  coordinatesOnly: boolean,
  cancelTokenSource: CancelTokenSource,
  page?: number,
  sortPropertyParam?: ISortProperty
): Promise<IPropertyResults> => {
  const response = await apiClient.post(
    `/properties/filter`,
    configureFilterParamsForRequest(
      requestParams,
      coordinatesOnly,
      sortPropertyParam,
      page
    ),
    { cancelToken: cancelTokenSource.token }
  );

  return response.data;
};
