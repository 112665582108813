import { apiClient } from "lib/apiClient";

export interface ITransaction {
  coins: number;
  compName: string;
  creationDate: string;
  compId: string;
  compType: string;
  initiatorId: string;
  transactionId: string;
  type: string;
  walletId: string;
}

export type ITransactions = {
  totalBalance: number;
  walletTransactions: ITransaction[];
};

export const getTransactionHistory = async (
  id: string
): Promise<ITransactions> => {
  const response = await apiClient.get(`/wallets/transactions/${id}`);

  return response.data;
};
