import { RentCompInfoType } from "./../features/Comp/interfaces";
import { apiClient } from "lib/apiClient";
import { ICreateRentComp } from "pages/Comps/components/RentCompModal/interfaces";

export interface IEditRentComp extends ICreateRentComp {
  id: string;
}

export const editRentComp = async (
  request: IEditRentComp | RentCompInfoType
): Promise<RentCompInfoType> => {
  const response = await apiClient.put(`/comps/rent`, request);

  return response.data;
};
